export const IS_LOADING = '@@abgMappingAction/IS_LOADING';
export const GET_ABG_MAPPINGS_LIST = '@@abgMappingAction/GET_ABG_MAPPINGS_LIST';
export const ON_FAILURE = '@@abgMappingAction/ON_FAILURE_GET_LIST';
export const CLEAR_SNACKBAR_MESSAGE = '@@abgMappingAction/CLEAR_SNACKBAR_MESSAGE';
export const ADD_ABG_MAPPING = '@@abgMappingAction/ADD_ABG_MAPPING';
export const UPDATE_ABG_MAPPING = '@@abgMappingAction/UPDATE_ABG_MAPPING';
export const DELETE_ABG_MAPPING = '@@abgMappingAction/DELETE_ABG_MAPPING';
export const GET_ABG_LIST = '@@abgMappingAction/GET_ABG_LIST';


