import axios from 'axios';
import {downloadFileFromStream} from '../actions/constants';

export const REQUEST_UPLOADED_FILES_FETCH = 'REQUEST_UPLOADED_FILES_FETCH';
export const UPLOADED_FILES_FETCH_SUCCESS = 'UPLOADED_FILES_FETCH_SUCCESS';
export const UPLOADED_FILES_FETCH_FAILURE = 'UPLOADED_FILES_FETCH_FAILURE';

export const REQUEST_UPLOADED_FILES_DATA = 'REQUEST_UPLOADED_FILES_DATA';
export const UPLOADED_FILES_DATA_SUCCESS = 'UPLOADED_FILES_DATA_SUCCESS';
export const UPLOADED_FILES_DATA_FAILURE = 'UPLOADED_FILES_DATA_FAILURE';

export const RESET_EXCEL_UPLOADED_FILES = 'RESET_EXCEL_UPLOADED_FILES';

export const REQUEST_DOWNLOAD_GENERIC_UPLOADED_FILE = 'REQUEST_DOWNLOAD_GENERIC_UPLOADED_FILE';
export const DOWNLOAD_GENERIC_UPLOADED_FILE_SUCCESS = 'DOWNLOAD_GENERIC_UPLOADED_FILE_SUCCESS';
export const DOWNLOAD_GENERIC_UPLOADED_FILE_FAILURE = 'DOWNLOAD_GENERIC_UPLOADED_FILE_FAILURE';

export const UPLOADED_FILTERED_FILES_FETCH_SUCCESS = 'UPLOADED_FILTERED_FILES_FETCH_SUCCESS';
export const UPDATE_FILE_PROGRESS_FILED = 'UPDATE_FILE_PROGRESS_FILED';

export function uploadedFilesFetch(data) {
  return (dispatch) => {
    dispatch(requestUploadedFilesFetch());
    let response = axios.post('/api/catalog/files/fetch', data);
    response.then((data) => {
      dispatch(uploadedFilesFetchSuccess(data.data));
    })
      .catch(error => {
        dispatch(uploadedFilesFetchFailure(error));
      });
    return response;
  };
}

export function getUpdatedFileProgress(file_ref_id) {
  return (dispatch) => {
    let response = fileProgressFetch(file_ref_id);
    response.then((data) => {
      if(!!data && !!data.data && data.data.processed_percentage !== undefined) {
        const payload = {
          processed_percentage: data.data.processed_percentage,
          file_ref_id: file_ref_id
        };
        dispatch(updateFileProgress(payload));
      }
    })
      .catch(error => {
        dispatch(uploadedFilesFetchFailure(error));
      });
    return response;
  };
}


export function fileProgressFetch(file_ref_id) {
  return axios.post('/api/catalog/progress/fetch', {file_ref_id: file_ref_id});
}

function updateFileProgress(payload) {
  return {
    type: UPDATE_FILE_PROGRESS_FILED,
    data: payload
  };
}

export function requestUploadedFilesFetch() {
  return {
    type: REQUEST_UPLOADED_FILES_FETCH,
  };
}

export function uploadedFilesFetchSuccess(d) {
  return {
    type: UPLOADED_FILES_FETCH_SUCCESS,
    data: d.files
  };
}

export function uploadedFilesFetchFailure(d) {
  return {
    type: UPLOADED_FILES_FETCH_FAILURE,
    data: d
  };
}

export function fetchUploadedFilesData(data) {

  return (dispatch) => {
    dispatch(requestUploadedFilesData());
    let response = axios.post('/api/catalog/filesData/fetch', body);
    response.then((data) => {
      dispatch(uploadedFilesDataSuccess(data.data));
    })
      .catch(error => {
        dispatch(uploadedFilesDataFailure(error));
      });
    return response;
  };
}

export function requestUploadedFilesData() {
  return {
    type: REQUEST_UPLOADED_FILES_DATA,
  };
}

export function uploadedFilesDataSuccess(d) {
  return {
    type: UPLOADED_FILES_DATA_SUCCESS,
    data: d.files
  };
}

export function uploadedFilesDataFailure(d) {
  return {
    type: UPLOADED_FILES_DATA_FAILURE,
    data: d
  };
}

export function resetExcelUploadedFiles() {
  return {
    type: RESET_EXCEL_UPLOADED_FILES
  };
}

export function downloadGenericUploadedFile(data) {
  let extension = data.file_extension;
  // delete data.file_extension;
  return (dispatch) => {
    dispatch(requestDownloadGenericUploadedFile());
    let response = axios.post('/api/downloadGenericUploadedFile', data, {responseType: 'blob'});
    response.then((data) => {
      dispatch(downloadGenericUploadedFileSuccess(data, extension));
    })
      .catch(error => {
        dispatch(downloadGenericUploadedFileFailure(error));
      });
    return response;
  };
}

export function requestDownloadGenericUploadedFile() {
  return {
    type: REQUEST_DOWNLOAD_GENERIC_UPLOADED_FILE,
  };
}

export function downloadGenericUploadedFileSuccess(d, ext) {
  let data = d.data;
  let mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
  if(ext == 'csv'){
    mimeType = 'text/csv';
  }
  downloadFileFromStream(data, Date.now(), mimeType);
  return {
    type: DOWNLOAD_GENERIC_UPLOADED_FILE_SUCCESS,
    data: d.data
  };
}

export function downloadGenericUploadedFileFailure(d) {
  return {
    type: DOWNLOAD_GENERIC_UPLOADED_FILE_FAILURE,
    data: d
  };
}

export function uploadedFilteredFilesFetch(data) {
  return (dispatch) => {
    dispatch(requestUploadedFilesFetch());
    let response = axios.post('/api/catalog/files/fetch', data);
    response.then((data) => {
      dispatch(uploadedFilteredFilesFetchSuccess(data.data));
    })
      .catch(error => {
        dispatch(uploadedFilesFetchFailure(error));
      });
    return response;
  };
}

export function uploadedFilteredFilesFetchSuccess(d) {
  return {
    type: UPLOADED_FILTERED_FILES_FETCH_SUCCESS,
    data: d.files
  };
}
