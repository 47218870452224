import {
  SET_INTENT_FILTER_LOADER,
  ON_SUCCESS_ALL_INTENT_FILTER,
  ON_SUCCESS_DELETE_BY_ID,
  ON_FAILURE_INTENT_RULE_REQUEST,
  SEARCH_BY_NAME,
  UPDATE_TEXT_INPUT,
  UPDATE_PAGE_NUMBER,
  RESET_IS_DELETED,
  CLEAR_INTENT_LIST,
  SET_SCROLL_POSITION,
  ON_SUCCESS_EDIT_BY_ID,
  ON_SUCCESS_RULE_CREATION
} from '../actions/types';

const INITIAL_STATE = {
  isLoading: false,
  prevIntentList: [],
  tempPrevList: [],
  isUpdated: false,
  count: 0,
  perPage: 20,
  errorMsg: {},
  input: '',
  currentPage: 1,
  scrollPosition: 0,
};

export default function catalogIntentFilter(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_INTENT_FILTER_LOADER:
      return {
        ...state,
        isLoading: action.payload,
        errorMsg: {},
      };
    case ON_SUCCESS_ALL_INTENT_FILTER:
      const numberOfPagesToSlice = state.currentPage === action.pageNumber ? state.currentPage - 1: state.currentPage;
      return {
        ...state,
        prevIntentList: [
          ...state.prevIntentList.slice(0, numberOfPagesToSlice * state.perPage),
          ...action.payload.intent_list,
        ],
        tempPrevList: action.payload.intent_list,
        count: action.payload.count,
        isDeleted: false,
        isLoading: false,
        isUpdated: false,
      };

    case ON_SUCCESS_DELETE_BY_ID:
      return {
        ...state,
        prevIntentList: action.payload.list,
        isLoading: false,
        count: state.count - 1,
      };
    case ON_FAILURE_INTENT_RULE_REQUEST:
      return {
        ...state,
        errorMsg: action.payload,
        isLoading: false,
      };

    case SEARCH_BY_NAME:
      return {
        ...state,
        prevIntentList: action.payload,
      };

    case UPDATE_TEXT_INPUT:
      return {
        ...state,
        input: action.payload,
      };
    case UPDATE_PAGE_NUMBER:
      return {
        ...state,
        currentPage: action.payload,
      };
    case RESET_IS_DELETED:
      return {
        ...state,
        isDeleted: false,
      };
    case CLEAR_INTENT_LIST:
      return {
        ...state,
        prevIntentList: [],
      };
    case SET_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: action.payload,
      };
    case ON_SUCCESS_EDIT_BY_ID:
      return {
        ...state,
        prevIntentList: action.payload,
      };
    case ON_SUCCESS_RULE_CREATION:
      return {
        ...state,
        count: state.count + 1
      };

    default:
      return state;
  }
}
