import React from 'react';
import NavigationBar from './../NavigationBar';

class LoginPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let userPermission = true;
    let loginFailure = false;
    if(location.href.indexOf('permissionDenied') !== -1) {
      userPermission = false;
    } else if(location.href.indexOf('loginError') !== -1) {
      loginFailure = true;
    }
    let googleButtonUrl = '/api/google/oauth';
    if(this.props.location.search !== undefined) {
      googleButtonUrl += this.props.location.search;
    }

    return (
      <div>
        <NavigationBar loggedStatus={false}/>
        <div>
          <div className="row full-page center">
            <div>
              {!userPermission &&
              <div className="alert alert-danger">
                <a href="#" className="close" data-dismiss="alert" aria-label="close">&times;</a>
                <strong>Sorry! </strong>
                You need Admins Permission to Sign In
              </div>
              }
              {loginFailure &&
              <div className="alert alert-danger">
                <a href="#" className="close" data-dismiss="alert" aria-label="close">&times;</a>
                <strong>Sorry! </strong>
                Your Google login failed
              </div>}
            </div>
            <div className="googleLoginButton">
              <a href={googleButtonUrl} className="btn btn-primary btn-google-sign-up flex-row">
                <i className="fa fa-google-plus"/>
                <span>Sign In with Google</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginPage;
