const initialState = {
  isFetching: false,
  options: [],
  spins: [],
  audience_Mappings: {},
};

export function gamification(state = initialState, action = {}) {
  switch(action.type) {
    case 'FETCH_IN_PROGRESS': {
      return {
        ...initialState,
        isFetching: true,
      };
    }
    default:
      return state;
  }
}

export default gamification;
