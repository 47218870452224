import {FETCH_USER_ROLES} from '../actions/types';

const initialState = {
  isFetching: true,
  errors: {},
  user_roles: [],
  user: {},
};

export function fetchUserRoles(state = initialState, action = {}) {
  switch(action.type) {
    case FETCH_USER_ROLES:
      let user_roles = [];
      let user = {};
      if(action.data !== undefined && action.data.actions !== undefined) {
        user_roles = action.data.actions;
      }
      if(action.data !== undefined && action.data.user !== undefined) {
        user = action.data.user;
      }
      return Object.assign({}, state, {
        user_roles: user_roles,
        user: user,
        isFetching: false,
      });
    default:
      return state;
  }
}