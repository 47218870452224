import {
  SET_INTENT_FILTER_LOADER_V2,
  ON_SUCCESS_INITIAL_DATA_V2,
  ON_SUCCESS_ALL_INTENT_FILTER_V2,
  ON_SUCCESS_SUBCATEGORY_DATA_WITH_PARAMS_V2,
  ON_SUCCESS_GET_LIST_BY_ID_V2,
  ON_SUCCESS_DELETE_BY_ID_V2,
  ON_FAILURE_INTENT_RULE_REQUEST_V2,
  ON_ADD_NEW_CONDITION_V2,
  ON_SELECTION_CONDITION_NAME_V2,
  SEARCH_BY_NAME_V2,
  UPDATE_TEXT_INPUT_V2,
  UPDATE_PAGE_NUMBER_V2,
  RESET_IS_DELETED_V2,
  CLEAR_INTENT_LIST_V2,
  SET_SCROLL_POSITION_V2,
  ON_SUCCESS_EDIT_BY_ID_V2,
  ON_SUCCESS_RULE_CREATION_V2
} from '../actions/types';

const INITIAL_STATE = {
  ruleConditions: [],
  ruleActions: [],
  isLoading: false,
  initialData: {},
  prevIntentList: [],
  tempPrevList: [],
  listForUpdate: [],
  isUpdated: false,
  count: 0,
  perPage: 20,
  errorMsg: {},
  input: '',
  currentPage: 1,
  scrollPosition: 0,
  categoriesData: {},
  categoryDataWithMappedIds: {}
};

export default function catalogIntentFilter(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_INTENT_FILTER_LOADER_V2:
      return {
        ...state,
        isLoading: action.payload,
        errorMsg: {},
      };
    case ON_SUCCESS_ALL_INTENT_FILTER_V2:
      const numberOfPagesToSlice = state.currentPage === action.pageNumber ? state.currentPage - 1: state.currentPage;
      return {
        ...state,
        prevIntentList: [
          ...state.prevIntentList.slice(0, numberOfPagesToSlice * state.perPage),
          ...action.payload.intent_list,
        ],
        tempPrevList: action.payload.intent_list,
        count: action.payload.count,
        isDeleted: false,
        isLoading: false,
        isUpdated: false,
      };
    case ON_SUCCESS_INITIAL_DATA_V2:
      return {
        ...state,
        isLoading: false,
        initialData: action.payload,
      };
    
    case ON_SUCCESS_SUBCATEGORY_DATA_WITH_PARAMS_V2:
      let categoriesData = action.payload.data;
      if(action.param === 'category') {
        categoriesData = categoriesData.map((cat) => {
          cat.type = `${cat.type.slice(0, cat.type.length-1)}ies`;
          return cat;
        });
      }

      let mapDataWithIds = {};
      categoriesData.forEach((item) =>{
        if(action.param === 'category') {
          mapDataWithIds[item.id] = item;
        } else {
          mapDataWithIds[item.name] = item;
        }
      });
      
      return {
        ...state,
        categoriesData: {...state.categoriesData, [action.param]: categoriesData},
        categoryDataWithMappedIds: {...state.categoryDataWithMappedIds, [action.param]: mapDataWithIds}
      };
    case ON_SUCCESS_GET_LIST_BY_ID_V2:
      return {
        ...state,
        listForUpdate: action.payload,
      };
    case ON_SUCCESS_DELETE_BY_ID_V2:
      return {
        ...state,
        prevIntentList: action.payload.list,
        isLoading: false,
        count: state.count - 1,
      };
    case ON_FAILURE_INTENT_RULE_REQUEST_V2:
      return {
        ...state,
        errorMsg: action.payload,
        isLoading: false,
      };
    case ON_ADD_NEW_CONDITION_V2:
      return {
        ...state,
        ruleConditions: action.payload,
      };
    case ON_SELECTION_CONDITION_NAME_V2:
      return {
        ...state,
        ruleConditions: action.payload,
      };
    case SEARCH_BY_NAME_V2:
      return {
        ...state,
        prevIntentList: action.payload,
      };

    case UPDATE_TEXT_INPUT_V2:
      return {
        ...state,
        input: action.payload,
      };
    case UPDATE_PAGE_NUMBER_V2:
      return {
        ...state,
        currentPage: action.payload,
      };
    case RESET_IS_DELETED_V2:
      return {
        ...state,
        isDeleted: false,
      };
    case CLEAR_INTENT_LIST_V2:
      return {
        ...state,
        prevIntentList: [],
      };
    case SET_SCROLL_POSITION_V2:
      return {
        ...state,
        scrollPosition: action.payload,
      };
    case ON_SUCCESS_EDIT_BY_ID_V2:
      return {
        ...state,
        prevIntentList: action.payload,
      };
    case ON_SUCCESS_RULE_CREATION_V2:
      return {
        ...state,
        count: state.count + 1
      };

    default:
      return state;
  }
}
