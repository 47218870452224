import axios from 'axios';

export function checkLoggedInStatus() {
  return (dispatch) => {
    const requestPayload = {
      method: 'POST',
      url: '/api/verify/signin',
      withCredentials: true,
    };
    return axios(requestPayload);
  };
}

export function logout() {
  return (dispatch) => {
    return axios.post('/api/logout');
  };
}
