import React from 'react';
import {connect} from 'react-redux';
import {checkLoggedInStatus} from './../actions/authActions';
import {getUserPageAccess} from './../actions/constants';
import {fetchUserRoles} from './../actions/redux/adminApis';
import DashBoardNavigation from './../components/dashboard/dashboardnavigation/DashBoardNavigation';
import Loader from '../components/dashboard/Loader/Loader';
import Globalize from '../actions/languages/Globalize';
import withRouter from '../withRouter';
import PropTypes from 'prop-types';

export default function(ComposedComponent, roles, index_page) {
  class Authenticate extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        page_access: 0,
        user_roles: [],
        user: {},
        isFetching: true,
        errors: {},
        sidebar_hidden: false,
      };
    }

    UNSAFE_componentWillMount() {
      const {history} = this.props;
      checkLoggedInStatus()().then(
        (res) => {
          if((window.location.href).indexOf('/login') !== -1) {
            history.push('/');
          } else {
            this.setState({page_access: 1}, function() {
              this.getPropsAndSetState(this.props, true);
            });
          }
        },
        (err) => {
          if((window.location.href).indexOf('/login') === -1) {
            let redirect_url = '/login?redirect=' + window.location.href;
            history.push(redirect_url);
          } else {
            this.setState({page_access: 1}, function() {
              this.getPropsAndSetState(this.props, false);
            });
          }
        }
      );
    }

    toggleSideBar() {
      this.setState({sidebarOpen: !this.state.sidebarOpen});
    }

    getPropsAndSetState(nextProps, isLogin) {
      if(isLogin) {
        if(nextProps.isFetching) {
          this.setState({isFetching: nextProps.isFetching}, function() {
            this.props.fetchUserRoles();
          });
        } else {
          let stateVariable = {
            user_roles: nextProps.user_roles,
            user: nextProps.user,
            isFetching: nextProps.isFetching
          };
          if(index_page !== undefined) {
            stateVariable.page_access = 3;
          } else {
            if(!getUserPageAccess(nextProps.user_roles, roles)) {
              stateVariable.page_access = 2;
            }
          }
          this.setState(stateVariable);
        }
      } else {
        this.setState({page_access: 3, isFetching: false, user_roles: []});
      }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.getPropsAndSetState(nextProps, true);
    }

    render() {
      return (
        <div>
          {this.state.isFetching ?
            <div className="page-loader flex-container">
              <div className="item1">
                <Loader/>
              </div>
            </div> :
            <div>
              {(this.state.page_access === 1 || this.state.page_access === 3) &&
              <div>
                <ComposedComponent {...this.props} user_roles={this.state.user_roles} user={this.state.user}/>
              </div>
              }
              {this.state.page_access === 2 &&
              <div id="wrapper" className={' ' + (this.state.sidebarOpen ? '' : ' sidebarClosed')}>
                <DashBoardNavigation
                  user_roles={this.props.user_roles}
                  user={this.props.user}
                  toggleSideBar={this.toggleSideBar.bind(this)}/>
                <div id="page-wrapper" className="dashboard-page">
                  <div className="padding-2em text-center">
                    <div className="error-message inline-block">
                      {Globalize.getString('access_denied_contact_your_team_lead_to_get_access')}
                    </div>
                  </div>
                </div>
              </div>
              }
            </div>
          }
        </div>
      );
    }
  }

  Authenticate.propTypes = {
    fetchUserRoles: PropTypes.func.isRequired
  };

  function mapStateToProps(state) {
    return {
      user_roles: state.fetchUserRoles.user_roles,
      user: state.fetchUserRoles.user,
      isFetching: state.fetchUserRoles.isFetching,
      errors: state.fetchUserRoles.errors,
    };
  }
  
  return withRouter(connect(mapStateToProps, {fetchUserRoles})(Authenticate));
}
