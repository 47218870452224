import React from 'react';
import { unstable_HistoryRouter as Router } from 'react-router-dom';
import { browserHistory } from '@browserHistory';
import 'jquery';
import './components/script/tableFix';

import 'bootstrap/dist/css/bootstrap.css';
import './components/styles/homePageCss.css';
import './components/styles/sb-admin.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-widgets/dist/css/react-widgets.css';
import './components/styles/bootstrap-multiselect.css';
import './components/styles/search.css';
import './components/styles/loader.css';
import './components/styles/react_datepicker.css';
import './components/styles/dashboardCss.css';
import './components/styles/select_button.css';
import './components/styles/scrollTable.css';
import './components/styles/style.css';
import './components/styles/tagsInput.css';
import './components/styles/search-list.css';
import './components/styles/excelUpload.css';
import './components/styles/agentDashboard.css';
import './components/styles/sms-portal.css';
import './components/styles/supplierFinance.css';
import './components/dashboard/communityUpload/style/index.css';
import './components/dashboard/collection/style.css';
import './components/dashboard/SearchOps/searchRuleEngine/addSearchRule.css';
import './components/dashboard/SearchOps/searchRuleEngine/list.css';
import './components/styles/allNotificationCampaigns.css';
import './components/styles/audienceSystem.css';
import './components/styles/dropDown.css';
import './components/styles/modal.css';
import './components/dashboard/deals/deals.css';
import './components/dashboard/gamification/index.css';
import './components/dashboard/tipsAndGuide/style.css';
import './components/dashboard/HVFAdmin/style/style.css';
import './components/dashboard/referralProgram/index.css';
import './components/dashboard/referralCampaign/index.css';
import './components/dashboard/communityModeration/style/index.css';
import './components/dashboard/communityModeration/popup/style.css';
import './components/dashboard/filesUpload/priceScheduling/deals.css';
import './components/dashboard/reseller/walletTemplate/wallet.css';
import './components/dashboard/filesUpload/imagesBulkUpload/styles.css';
import './components/dashboard/CLPAdmin/clpAdmin.css';
import './components/dashboard/SearchOps/SubViews/subViews.css';
import './components/dashboard/SearchOps/stopWords/stopWords.css';
import './components/dashboard/CategoryNavigation/CategoryTreeEditor/treeEditor.css';
import './components/dashboard/SortnFilter/sortnfilter.css';
import './components/dashboard/TopNavAdmin/TopNavAdmin.css';
import './components/dashboard/dynamicWebview/common/UploadModal/uploadModal.css';
import './components/dashboard/liveCommerce/liveCommerce.css';
import './components/dashboard/legalAndPolicies/PolicyEditor.css';
import './components/dashboard/catalog/SubView/catalogFilter.css';
import './components/dashboard/SearchOps/qclCorrection/qclCorrection.css';
import './components/dashboard/SearchOps/queryExpansion/queryExpansion.css';
import Cookies from 'universal-cookie';

import routes from './routes';
import { uuidv4 } from './components/dashboard/CLPAdmin/utils';

class App extends React.Component {

  componentDidMount() {
    const cookies = new Cookies();
    if(!cookies.get('session-id')) {
      cookies.set('session-id', uuidv4(), { path: '/' });
    }
  }

  UNSAFE_componentWillMount() {
    window.onload = (function (e, b) {
      if (!b.__SV) {
        var a, f, i, g;
        window.mixpanel = b;
        b._i = [];
        b.init = function (a, e, d) {
          function f(b, h) {
            var a = h.split('.');
            2 == a.length && ((b = b[a[0]]), (h = a[1]));
            b[h] = function () {
              b.push([h].concat(Array.prototype.slice.call(arguments, 0)));
            };
          }

          var c = b;
          'undefined' !== typeof d ? (c = b[d] = []) : (d = 'mixpanel');
          c.people = c.people || [];
          c.toString = function (b) {
            var a = 'mixpanel';
            'mixpanel' !== d && (a += '.' + d);
            b || (a += ' (stub)');
            return a;
          };
          c.people.toString = function () {
            return c.toString(1) + '.people (stub)';
          };
          i =
            'disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config reset people.set people.set_once people.increment people.append people.union people.track_charge people.clear_charges people.delete_user'.split(
              ' '
            );
          for (g = 0; g < i.length; g++) f(c, i[g]);
          b._i.push([a, e, d]);
        };
        b.__SV = 1.2;
        a = e.createElement('script');
        a.type = 'text/javascript';
        a.async = !0;
        a.src =
          'undefined' !== typeof MIXPANEL_CUSTOM_LIB_URL
            ? MIXPANEL_CUSTOM_LIB_URL
            : 'file:' === e.location.protocol &&
              '//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js'.match(/^\/\//)
              ? 'https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js'
              : '//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js';
        f = e.getElementsByTagName('script')[0];
        f.parentNode.insertBefore(a, f);
      }
    })(document, window.mixpanel || []);
    mixpanel.init(process.env.MIXPANEL_TOKEN, {
      loaded: function () {
        var distinct_id = mixpanel.get_distinct_id();
      },
    });
  }

  render() {
    return (
      <>
        <Router history={browserHistory}>{routes}</Router>
      </>
    );
  }
}

export default App;
