import {
  REQUEST_UPLOADED_FILES_FETCH,
  UPLOADED_FILES_FETCH_SUCCESS,
  UPLOADED_FILES_FETCH_FAILURE,
  REQUEST_UPLOADED_FILES_DATA,
  UPLOADED_FILES_DATA_SUCCESS,
  UPLOADED_FILES_DATA_FAILURE,
  RESET_EXCEL_UPLOADED_FILES,
  REQUEST_DOWNLOAD_GENERIC_UPLOADED_FILE,
  DOWNLOAD_GENERIC_UPLOADED_FILE_SUCCESS,
  DOWNLOAD_GENERIC_UPLOADED_FILE_FAILURE,
  UPLOADED_FILTERED_FILES_FETCH_SUCCESS,
  UPDATE_FILE_PROGRESS_FILED
} from '../actions/excelUploadedFiles';
import Globalize from '../actions/languages/Globalize';

const initialState = {
  isLoading: false,
  uploadedFiles: [],
  isError: false,
  errorMessage: '',
  loadMoreUploadedFiles: false,
  uploadedFilesLimit: 15,
  uploadedFileUrl: ''

};

export default (state = initialState, action = {}) => {
  switch(action.type) {

    case RESET_EXCEL_UPLOADED_FILES: {
      let resetState = {
        isLoading: false,
        uploadedFiles: [],
        isError: false,
        errorMessage: '',
        loadMoreUploadedFiles: false,
        uploadedFilesLimit: 15,
      };
      return Object.assign({}, state, resetState);
    }

    case REQUEST_UPLOADED_FILES_FETCH: {
      return Object.assign({}, state, {
        isLoading: true
      });
    }

    case UPDATE_FILE_PROGRESS_FILED: {
      const newData = state.uploadedFiles.map(itm => itm.file_ref_id === action.data.file_ref_id ? Object.assign(itm, {processed_percentage: action.data.processed_percentage}): itm);
      return Object.assign({}, state, {
        uploadedFiles: newData
      });
    }

    case UPLOADED_FILES_FETCH_SUCCESS: {
      let uploadF = state.uploadedFiles.concat(action.data);
      return Object.assign({}, state, {
        isLoading: false,
        uploadedFiles: uploadF,
        loadMoreUploadedFiles: action.data.length === state.uploadedFilesLimit,
        isError: false
      });
    }

    case UPLOADED_FILTERED_FILES_FETCH_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        uploadedFiles: action.data,
        loadMoreUploadedFiles: action.data.length === state.uploadedFilesLimit,
        isError: false
      });
    }

    case UPLOADED_FILES_FETCH_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
        isError: true,
        errorMessage: Globalize.getString('unexpected_error_retry')
      });
    }

    case REQUEST_UPLOADED_FILES_DATA: {
      return Object.assign({}, state, {
        isLoading: true
      });
    }

    case UPLOADED_FILES_DATA_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        uploadedFilesData: action.data,
        isError: false
      });
    }

    case UPLOADED_FILES_DATA_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
        uploadedFilesData: null,
        isError: true,
        errorMessage: Globalize.getString('unexpected_error_retry')
      });
    }

    case REQUEST_DOWNLOAD_GENERIC_UPLOADED_FILE: {
      return Object.assign({}, state, {
        isLoading: true
      });
    }

    case DOWNLOAD_GENERIC_UPLOADED_FILE_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        uploadedFileUrl: action.data
      });
    }

    case DOWNLOAD_GENERIC_UPLOADED_FILE_FAILURE: {
      return Object.assign({}, state, {
        isLoading: true,
        isError: true,
        errorMessage: Globalize.getString('unexpected_error_retry')
      });
    }

    default:
      return state;
  }
};
