import {combineReducers} from 'redux';
import {FETCH_DASHBOARD_METRICS_SUCCESS, FETCH_DASHBOARD_METRICS_ERROR} from '../actions/types';
import moment from 'moment-timezone';

const initialState = {
  isFetching: true,
  error: false,
  errors: {},
  currentMonthData: {},
  lastSunday: moment().day(0).format('YYYY-MM-DD'),
  previousSunday: moment().day(-7).format('YYYY-MM-DD'),
  previous2Sunday: moment().day(-14).format('YYYY-MM-DD'),
  previous3Sunday: moment().day(-21).format('YYYY-MM-DD'),
  today: moment().format('YYYY-MM-DD'),
  previousDay: moment().add(-1, 'days').format('YYYY-MM-DD'),
  last15Days: [],
  metricsData: [],
  weeklyData: [],
  currentWeekMetrics: [],
  previousWeekMetrics: [],
  todayMetrics: [],
  previousDayMetrics: []
};

function isDateCurrentMonth(date) {
  let startOfMonth = moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');

  return moment(startOfMonth).isBefore(date, 'day') || moment(startOfMonth).isSame(date, 'day');
}

function getCurrentMonthMetrics(state, res) {
  let netTotalOrders = 0;
  let netTotalGMV = 0;
  let verifiedTotalOrders = 0;
  let verifiedTotalGMV = 0;
  let today_verified_orders = 0;
  let today_verified_gmv = 0;
  let todayDate = new Date(state.today);
  for(let key in res.data.data) {
    let keyDate = new Date(key);
    if(+keyDate === +todayDate) {
      if(res.data.data[key].verified_only !== undefined) {
        if(res.data.data[key].verified_only.num_orders !== undefined) {
          today_verified_orders = res.data.data[key].verified_only.num_orders;
        }
        if(res.data.data[key].verified_only.gmv !== undefined) {
          today_verified_gmv = res.data.data[key].verified_only.gmv;
        }
      }
    }

    if(isDateCurrentMonth(key)) {
      if(res.data.data[key].net !== undefined && res.data.data[key].net.num_orders !== undefined) {
        netTotalOrders += parseInt(res.data.data[key].net.num_orders);
      }
      if(res.data.data[key].net !== undefined && res.data.data[key].net.gmv !== undefined) {
        netTotalGMV += parseInt(res.data.data[key].net.gmv);
      }
      if(res.data.data[key].verified_only !== undefined && res.data.data[key].verified_only.num_orders !== undefined) {
        verifiedTotalOrders += parseInt(res.data.data[key].verified_only.num_orders);
      }
      if(res.data.data[key].verified_only !== undefined && res.data.data[key].verified_only.gmv !== undefined) {
        verifiedTotalGMV += parseInt(res.data.data[key].verified_only.gmv);
      }
    }
  }

  let currentMonthData = {
    today_verified_orders: today_verified_orders,
    today_verified_gmv: today_verified_gmv,
    total_orders: netTotalOrders,
    total_gmv: netTotalGMV,
    verified_orders: verifiedTotalOrders,
    verified_gmv: verifiedTotalGMV
  };

  return currentMonthData;
}

function setStateAndSetWeeklyData(state, res) {
  let todayDate = new Date(state.today);
  let previousDayDate = new Date(state.previousDay);
  let lastSundayDate = new Date(state.lastSunday);
  let previousSunday = new Date(state.previousSunday);
  let previous2Sunday = new Date(state.previous2Sunday);
  let previous3Sunday = new Date(state.previous3Sunday);

  if(+lastSundayDate === +todayDate) {
    lastSundayDate = previousSunday;
    previousSunday = previous2Sunday;
    previous2Sunday = previous3Sunday;
    previous3Sunday = new Date(moment().day(-28).format('YYYY-MM-DD'));
  }

  let stateVariable = {};
  let p = res.data.data;
  let previousWeekMetrics = [];
  let currentWeekMetrics = [];
  let weeklyData = {};
  let currentWeekSum = [0, 0, 0, 0]; //["Net GMV, Net Orders, Verified GMV, verified_order"];
  let previousWeekSum = [0, 0, 0, 0]; //["Net GMV, Net Orders, Verified GMV, verified_order"];
  let week2Sum = [0, 0, 0, 0]; //["Net GMV, Net Orders, Verified GMV, verified_order"];
  let week3Sum = [0, 0, 0, 0]; //["Net GMV, Net Orders, Verified GMV, verified_order"];
  let date_range1 = moment(lastSundayDate).add(1, 'days').format('DD MMM') + ' - ' + moment(todayDate).format('DD MMM');
  let date_range2 = moment(previousSunday).add(1, 'days').format('DD MMM') + ' - ' + moment(lastSundayDate).format('DD MMM');
  let date_range3 = moment(previous2Sunday).add(1, 'days').format('DD MMM') + ' - ' + moment(previousSunday).format('DD MMM');
  let date_range4 = moment(previous3Sunday).add(1, 'days').format('DD MMM') + ' - ' + moment(previous2Sunday).format('DD MMM');
  weeklyData[date_range1] = {net_gmv: 0, net_orders: 0, verified_gmv: 0, verified_orders: 0};
  weeklyData[date_range2] = {net_gmv: 0, net_orders: 0, verified_gmv: 0, verified_orders: 0};
  weeklyData[date_range3] = {net_gmv: 0, net_orders: 0, verified_gmv: 0, verified_orders: 0};
  weeklyData[date_range4] = {net_gmv: 0, net_orders: 0, verified_gmv: 0, verified_orders: 0};

  let last15Days = [];
  let last15DaysDate = new Date(moment().add(-15, 'days').format('YYYY-MM-DD'));
  for(let key in p) {
    let keyDate = new Date(key);
    if(+keyDate > +last15DaysDate) {
      let las15Days_Object = {
        'date': key,
        'data': p[key]
      };
      last15Days.push(las15Days_Object);
    }
  }
  stateVariable.last15Days = last15Days;
  for(let key in p) {
    let keyDate = new Date(key);
    if(p.hasOwnProperty(key)) {
      if(+keyDate === +todayDate) {
        stateVariable.todayMetrics = p[key];
      }
      if(+keyDate === +previousDayDate) {
        stateVariable.previousDayMetrics = p[key];
      }
      if(+keyDate > +lastSundayDate) {
        let cr_Object = {
          'date': key,
          'data': p[key]
        };
        currentWeekMetrics.push(cr_Object);
      } else if(+previousSunday < +keyDate) {
        let pr_Object = {
          'date': key,
          'data': p[key]
        };
        previousWeekMetrics.push(pr_Object);
      }

      if(+keyDate > +lastSundayDate && +keyDate <= todayDate) {
        if(p[key].net !== undefined) {
          weeklyData[date_range1].net_gmv = parseInt(p[key].net.gmv) + weeklyData[date_range1].net_gmv;
          weeklyData[date_range1].net_orders = parseInt(p[key].net.num_orders) + weeklyData[date_range1].net_orders;
        }
        if(p[key].verified_only !== undefined) {
          weeklyData[date_range1].verified_gmv = parseInt(p[key].verified_only.gmv) + weeklyData[date_range1].verified_gmv;
          weeklyData[date_range1].verified_orders = parseInt(p[key].verified_only.num_orders) + weeklyData[date_range1].verified_orders;
        }
      }
      if(+keyDate <= lastSundayDate && +keyDate > +previousSunday) {
        if(p[key].net !== undefined) {
          weeklyData[date_range2].net_gmv = parseInt(p[key].net.gmv) + weeklyData[date_range2].net_gmv;
          weeklyData[date_range2].net_orders = parseInt(p[key].net.num_orders) + weeklyData[date_range2].net_orders;
        }
        if(p[key].verified_only !== undefined) {
          weeklyData[date_range2].verified_gmv = parseInt(p[key].verified_only.gmv) + weeklyData[date_range2].verified_gmv;
          weeklyData[date_range2].verified_orders = parseInt(p[key].verified_only.num_orders) + weeklyData[date_range2].verified_orders;
        }
      }
      if(+keyDate <= previousSunday && +keyDate > +previous2Sunday) {
        if(p[key].net !== undefined) {
          weeklyData[date_range3].net_gmv = parseInt(p[key].net.gmv) + weeklyData[date_range3].net_gmv;
          weeklyData[date_range3].net_orders = parseInt(p[key].net.num_orders) + weeklyData[date_range3].net_orders;
        }
        if(p[key].verified_only !== undefined) {
          weeklyData[date_range3].verified_gmv = parseInt(p[key].verified_only.gmv) + weeklyData[date_range3].verified_gmv;
          weeklyData[date_range3].verified_orders = parseInt(p[key].verified_only.num_orders) + weeklyData[date_range3].verified_orders;
        }
      }
      if(+keyDate <= previous2Sunday && +keyDate > +previous3Sunday) {
        if(p[key].net !== undefined) {
          weeklyData[date_range4].net_gmv = parseInt(p[key].net.gmv) + weeklyData[date_range4].net_gmv;
          weeklyData[date_range4].net_orders = parseInt(p[key].net.num_orders) + weeklyData[date_range4].net_orders;
        }
        if(p[key].verified_only !== undefined) {
          weeklyData[date_range4].verified_gmv = parseInt(p[key].verified_only.gmv) + weeklyData[date_range4].verified_gmv;
          weeklyData[date_range4].verified_orders = parseInt(p[key].verified_only.num_orders) + weeklyData[date_range4].verified_orders;
        }
      }
    }
  }
  stateVariable.previousWeekMetrics = previousWeekMetrics;
  stateVariable.currentWeekMetrics = currentWeekMetrics;
  stateVariable.weeklyData = weeklyData;

  return stateVariable;
}

/**
 * Dashboard Reducers
 * @param  {Object} state - The current state of the application
 * @param  {Object} action - The action object
 * @return {Object} The updated state
 */
function dashboard(state = initialState, action = {}) {
  switch(action.type) {
    case FETCH_DASHBOARD_METRICS_SUCCESS:
      let currentMonthData = getCurrentMonthMetrics(state, action.data);
      let stateData = setStateAndSetWeeklyData(state, action.data);
      return Object.assign({}, state, {
        metricsData: action.data,
        isFetching: false,
        error: false,
        errors: {},
        currentMonthData,
        ...stateData
      });

    case FETCH_DASHBOARD_METRICS_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        error: true,
        errors: action.errors
      });

    default:
      return state;
  }
}

export default dashboard;
