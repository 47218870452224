import {TOGGLE_SIDEBAR} from '../actions/types';

const initialState = {
  sidebarOpen: true
};

/**
 * Shared Reducers
 * @param  {Object} state - The current state of the application
 * @param  {Object} action - The action object
 * @return {Object} The updated state
 */
export default (state = initialState, action = {}) => {
  switch(action.type) {
    case TOGGLE_SIDEBAR:
      return Object.assign({}, state, {
        sidebarOpen: !state.sidebarOpen
      });

    default:
      return state;
  }
};

