import {
  ON_SUCCESS_BLACKLIST_CREATE,
  IS_LOADING,
  ON_SUCCESS_GET_BLACKLIST,
  ON_SUCCESS_GET_GVT,
  ON_SUCCESS_GET_ALL_RSVT,
  ON_SUCCESS_DELETE_RSVT,
  ON_SUCCESS_DELETE_BLACKLIST,
  ON_ERROR,
  CLAER_BLACKLIST_DATA,
  CLEAR_RSVT_DATA
} from '../components/dashboard/SearchOps/constants/constants';

const INITIAL_STATE = {
  isLoading: false,
  blacklist: [],
  gvt: [],
  rsvt: [],
  totalRsvtCount: 0,
  totalBlacklistCount: 0,
  errors: {
    type:'',
    msg: '',
  }
};

export default function queryTagging(state = INITIAL_STATE, action) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ON_SUCCESS_BLACKLIST_CREATE:
      return {
        ...state,
        isLoading: false,
        errors: {
          type:'',
          msg: '',
        }
      };
    case ON_SUCCESS_GET_GVT:
      return {
        ...state,
        gvt: [action.payload],
        isLoading: false,
        errors: {
          type:'',
          msg: '',
        }
      };
    case ON_SUCCESS_GET_ALL_RSVT:
      return {
        ...state,
        rsvt: [...action.payload],
        isLoading: false,
        totalRsvtCount: action.count,
        errors: {
          type:'',
          msg: '',
        }
      };
    case ON_SUCCESS_DELETE_RSVT:
      return {
        ...state,
        rsvt: [...action.payload],
        isLoading: false,
        errors: {
          type:'',
          msg: '',
        }
      };
    case ON_SUCCESS_GET_BLACKLIST:
      return {
        ...state,
        blacklist: [...action.payload],
        totalBlacklistCount: action.count,
        isLoading: false,
        errors: {
          type:'',
          msg: '',
        }
      };
    case ON_SUCCESS_DELETE_BLACKLIST:
      return {
        ...state,
        blacklist: [...action.payload],
        isLoading: false,
        errors: {
          type:'',
          msg: '',
        }
      };
    case ON_ERROR:
      return {
        ...state,
        errors: action.payload,
        isLoading: false,
      }
    
    case CLAER_BLACKLIST_DATA: 
      return {
        ...state,
        blacklist: [],
        totalBlacklistCount: 0,
        isLoading: false,
        errors: {
          type:'',
          msg: '',
        }
      };
    case CLEAR_RSVT_DATA:
      return {
        ...state,
        rsvt: [],
        isLoading: false,
        totalRsvtCount: 0,
        errors: {
          type:'',
          msg: '',
        }
      };
    default:
      return state;
  }
}
