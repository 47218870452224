// Dashboard
export const FETCH_DASHBOARD_METRICS_SUCCESS =
  'FETCH_DASHBOARD_METRICS_SUCCESS';
export const FETCH_DASHBOARD_METRICS_ERROR = 'FETCH_DASHBOARD_METRICS_ERROR';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

// USER ROLES
export const FETCH_USER_ROLES = 'FETCH_USER_ROLES';
export const FETCH_USER_ROLES_ERROR = 'FETCH_USER_ROLES_ERROR';

// Agent Dashboard
export const FETCH_USER_FROM_SEARCH = 'FETCH_USER_FROM_SEARCH';
export const FETCH_USER_FORM_API = 'FETCH_USER_FORM_API';
export const SAVE_USER_FROM_API = 'SAVE_USER_FROM_API';

/// catlog initent and filters
export const SET_INTENT_FILTER_LOADER = 'SET_INTENT_FILTER_LOADER';
export const ON_SUCCESS_ALL_INTENT_FILTER = 'ON_SUCCESS_ALL_INTENT_FILTER';
export const ON_SUCCESS_DELETE_BY_ID = 'ON_SUCCESS_DELETE_BY_ID';
export const ON_FAILURE_INTENT_RULE_REQUEST = 'ON_FAILURE_INTENT_RULE_REQUEST';
export const UPDATE_TEXT_INPUT = 'UPDATE_TEXT_INPUT';
export const UPDATE_PAGE_NUMBER = 'UPDATE_PAGE_NUMBER';
export const RESET_IS_DELETED = 'RESET_IS_DELETED';
export const CLEAR_INTENT_LIST = 'CLEAR_INTENT_LIST';
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';
export const ON_SUCCESS_RULE_CREATION = 'ON_SUCCESS_RULE_CREATION';
export const SEARCH_BY_NAME = 'SEARCH_BY_NAME';
export const ON_SUCCESS_EDIT_BY_ID = 'ON_SUCCESS_EDIT_BY_ID';

/// catlog initent and filters V2 - old
export const SET_INTENT_FILTER_LOADER_V2 = 'SET_INTENT_FILTER_LOADER_V2';
export const ON_SUCCESS_INITIAL_DATA_V2 = 'ON_SUCCESS_INITIAL_DATA_V2';

export const ON_SUCCESS_ALL_INTENT_FILTER_V2 = 'ON_SUCCESS_ALL_INTENT_FILTER_V2';
export const ON_SUCCESS_SUBCATEGORY_DATA_WITH_PARAMS_V2 = 'ON_SUCCESS_SUBCATEGORY_DATA_WITH_PARAMS_V2';

export const ON_SUCCESS_GET_LIST_BY_ID_V2 = 'ON_SUCCESS_GET_LIST_BY_ID_V2';
export const ON_SUCCESS_DELETE_BY_ID_V2 = 'ON_SUCCESS_DELETE_BY_ID_V2';
export const ON_FAILURE_INTENT_RULE_REQUEST_V2 = 'ON_FAILURE_INTENT_RULE_REQUEST_V2';

export const ON_ADD_NEW_CONDITION_V2 = 'ON_ADD_NEW_CONDITION_V2';
export const ON_SELECTION_CONDITION_NAME_V2 = 'ON_SELECTION_CONDITION_NAME_V2';
export const UPDATE_TEXT_INPUT_V2 = 'UPDATE_TEXT_INPUT_V2';
export const UPDATE_PAGE_NUMBER_V2 = 'UPDATE_PAGE_NUMBER_V2';
export const RESET_IS_DELETED_V2 = 'RESET_IS_DELETED_V2';
export const CLEAR_INTENT_LIST_V2 = 'CLEAR_INTENT_LIST_V2';
export const SET_SCROLL_POSITION_V2 = 'SET_SCROLL_POSITION_V2';
export const ON_SUCCESS_EDIT_BY_ID_V2 = 'ON_SUCCESS_EDIT_BY_ID_V2';
export const ON_SUCCESS_RULE_CREATION_V2 = 'ON_SUCCESS_RULE_CREATION_V2';
export const SEARCH_BY_NAME_V2 = 'SEARCH_BY_NAME_V2';

// query normalization and expansions
export const IS_LOADING = 'IS_LOADING';
export const ON_SUCCESS_GET_NORMALIZATION_RULE =
  'ON_SUCCESS_GET_NORMALIZATION_RULE';
export const ON_FAILURE_GET_NORMALIZATION_RULE =
  'ON_FAILURE_GET_NORMALIZATION_RULE';

export const ON_SUCCESS_ADD_NORMALIZATION_RULE =
  'ON_SUCCESS_ADD_NORMALIZATION_RULE';
export const ON_FAILURE_ADD_NORMALIZATION_RULE =
  'ON_FAILURE_ADD_NORMALIZATION_RULE';
export const ON_SUCCESS_EDIT_NORMALIZATION_RULE =
  'ON_SUCCESS_EDIT_NORMALIZATION_RULE';
export const ON_FAILURE_EDIT_NORMALIZATION_RULE =
  'ON_FAILURE_EDIT_NORMALIZATION_RULE';

export const ON_SUCCESS_DELETE_NORMALIZATION_RULE =
  'ON_SUCCESS_DELETE_NORMALIZATION_RULE';
export const ON_FAILURE_DELETE_NORMALIZATION_RULE =
  'ON_FAILURE_DELETE_NORMALIZATION_RULE';
export const ON_INPUT_NORMALIZED_QUERY = 'ON_INPUT_NORMALIZED_QUERY';
export const ON_INPUT_QUERY = 'ON_INPUT_QUERY';
export const IS_ERROR_FOUND = 'IS_ERROR_FOUND';
export const SUCCESS_RULE_BY_ID = 'SUCCESS_RULE_BY_ID';

export const ON_SUCCESS_GET_LIST = 'ON_SUCCESS_GET_LIST';
export const ON_FAILURE_GET_LIST = 'ON_FAILURE_GET_LIST';
export const CLEAR_SNACKBAR_MESSAGE = 'CLEAR_SNACKBAR_MESSAGE';
export const UPDATE_OFFSET = 'UPDATE_OFFSET';
export const UPDATE_LIMIT = 'UPDATE_LIMIT';
export const CLEAR_NORMALIZATION_DATA = 'CLEAR_NORMALIZATION_DATA';
