export const REDACTION = 'REDACTION';
export const MANUAL = 'MANUAL_CORRECTION';
const CORRECTION_BASE_URL = '/api/search/qcl/manual-correction';
const REDACTION_BASE_URL = '/api/search/qcl/redaction';

//Manual Redaction endpoints
export const ADD_MANUAL_REDACTION_URL = `${REDACTION_BASE_URL}/create`;
export const GET_MANUAL_QUERY_REDACTION_LIST_URL = `${REDACTION_BASE_URL}/get`;
export const UPDATE_MANUAL_REDACTION_URL = `${REDACTION_BASE_URL}/update`;
export const DELETE_MANUAL_REDACTION_URL = `${REDACTION_BASE_URL}/delete`;

//Manual Correction endpoints
export const ADD_MANUAL_CORRECTION_URL = `${CORRECTION_BASE_URL}/create`;
export const GET_QUERY_CORRECTION_LIST_URL = `${CORRECTION_BASE_URL}/get`;
export const UPDATE_MANUAL_CORRECTION_URL = `${CORRECTION_BASE_URL}/update`;
export const DELETE_MANUAL_CORRECTION_URL = `${CORRECTION_BASE_URL}/delete`;

export const OFFSET_KEY = '_OFFSET';
export const LIMIT_KEY = '_LIMIT';
export const SEARCH_KEY = '_SEARCH';
export const SEARCH_ERROR_KEY = '_SEARCH_ERROR';
export const CURRENT_PAGE_KEY = '_CURRENT_PAGE';
export const COUNT_KEY = '_COUNT';
