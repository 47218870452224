export const queryTaggingBlacklist = ['Query', 'Delete'];
export const tabList = [
  { eventKey: 'GVT', name: 'GVT' },
  { eventKey: 'RSVT', name: 'RSVT' },
];
export const gvtTableHeader = ['Value', 'Created At', 'Updated At', 'Edit'];
export const rsvtTableHeader = [
  'Query',
  'Value',
  'Status',
  'Created At',
  'Updated At',
  'Edit/Delete',
];
export const limitOptionList = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
  { value: 500, label: 500 },
  {
    value: 1000,
    label: 1000,
  },
];

//COMMON
export const IS_LOADING = 'IS_LOADING';

//BLACKLIST
export const ON_SUCCESS_BLACKLIST_CREATE = 'ON_SUCCESS_BLACKLIST_CREATE';
export const ON_SUCCESS_GET_BLACKLIST = 'ON_SUCCESS_GET_BLACKLIST';
export const ON_SUCCESS_DELETE_BLACKLIST = 'ON_SUCCESS_DELETE_BLACKLIST';
export const CLAER_BLACKLIST_DATA = 'CLAER_BLACKLIST_DATA'

//GVT
export const ON_SUCCESS_GET_GVT = 'ON_SUCCESS_GET_GVT';
export const ON_SUCCESS_UPDATE_GVT = 'ON_SUCCESS_UPDATE_GVT';

//RSVT
export const ON_SUCCESS_RSVT_CREATE = 'ON_SUCCESS_RSVT_CREATE';
export const ON_SUCCESS_GET_ALL_RSVT = 'ON_SUCCESS_GET_ALL_RSVT';
export const ON_SUCCESS_DELETE_RSVT = 'ON_SUCCESS_DELETE_RSVT';
export const CLEAR_RSVT_DATA = 'CLEAR_RSVT_DATA';

export const ON_ERROR = 'ON_ERROR';
