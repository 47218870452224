const initialState = {};

/**
 * Daily Trends Reducers
 * @param  {Object} state - The current state of the application
 * @param  {Object} action - The action object
 * @return {Object} The updated state
 */
export default (state = initialState, action = {}) => {
  switch (action.type) {
    default: return state;
  }
};
