import {
  IS_LOADING,
  ON_FAILURE_GET_EXPANSION_RULE,
  ON_SUCCESS_GET_EXPANSION_RULE,
  UPDATE_PAGE,
  UPDATE_INPUT_TEXT,
  CLEAR_EXPANSION_LIST
} from '../components/dashboard/SearchOps/queryExpansion/types';

import {
  MANUAL, REDACTION
} from '../components/dashboard/SearchOps/queryExpansion/constants';

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  manuals: [],
  [`${MANUAL}start`]: 0,
  [`${REDACTION}start`]: 0,
  count: 0,
  message: '',
  [`${MANUAL}page`]: 1,
  [`${REDACTION}page`]: 1,
  [`${MANUAL}SearchInputText`]: '',
  [`${REDACTION}SearchInputText`]: '',
  [MANUAL]: [],
  [REDACTION]: []
};

export default function queryNormalization(state = INITIAL_STATE, action) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ON_FAILURE_GET_EXPANSION_RULE:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case ON_SUCCESS_GET_EXPANSION_RULE:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case UPDATE_PAGE:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_INPUT_TEXT:
      return {
        ...state,
        ...action.payload
      };
    case CLEAR_EXPANSION_LIST:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
