const BASE_URL = '/api/search/expansion';
//Query expansion redaction endpoints
export const CREATE_REDACTION_URL = `${BASE_URL}/redaction/create`;
export const GET_REDACTION_URL = `${BASE_URL}/redaction/get`;
export const GET_REDACTION_SEARCH_URL = `${BASE_URL}/redaction/get-queries`;
export const UPDATE_REDACTION_URL = `${BASE_URL}/redaction/update`;
export const DELETE_REDACTION_URL = `${BASE_URL}/redaction/delete`;

//Blacklisted Expansion Terms endpoints
export const CREATE_BLACKLIST_URL = `${BASE_URL}/blacklist/create`;
export const UPDATE_BLACKLIST_URL = `${BASE_URL}/blacklist/update`;
export const GET_BLACKLIST_URL = `${BASE_URL}/blacklist/get`;
export const DELETE_BLACKLIST_URL = `${BASE_URL}/blacklist/delete`;

//Exceptional Expansion Tokens Endpoints
export const CREATE_EXCEPTIONAL_TOKEN_URL = `${BASE_URL}/manual/create`;
export const UPDATE_EXCEPTIONAL_TOKEN_URL = `${BASE_URL}/manual/update`;
export const GET_EXCEPTIONAL_TOKEN_URL = `${BASE_URL}/manual/get`;
export const GET_EXCEPTIONAL_TOKEN_SEARCH_URL = `${BASE_URL}/manual/get-queries`;
export const DELETE_EXCEPTIONAL_TOKEN_URL = `${BASE_URL}/manual/delete`;

//
export const REDACTION = 'REDACTION';
export const BLACKLIST = 'BLACKLIST';
export const MANUAL = 'EXCEPTIONAL_TOKEN';
