import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import React from 'react';
import queryString from 'query-string';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    const searchParams = queryString.parse(location.search);
    let navigate = useNavigate();
    let params = useParams();
    //params are added in query because in some places we are using params from query
    //because previously we were using react router v4. Otherwise we would have
    //only used searchParams
    location = { ...location, query: { ...params, ...searchParams } };

    return (
      <Component
        {...props}
        router={{ location, history: { push: navigate }, params }}
        location={location}
        history={{ push: navigate }}
        params={params}
      />
    );
  }

  return <ComponentWithRouterProp />;
}

export default withRouter;