import axios from 'axios';
import {downloadFileFromStream} from './constants';

export const REQUEST_FETCH_FILE_TYPES = 'REQUEST_FETCH_FILE_TYPES';
export const FETCH_FILE_TYPES_SUCCESS = 'FETCH_FILE_TYPES_SUCCESS';
export const FETCH_FILE_TYPES_FAILURE = 'FETCH_FILE_TYPES_FAILURE';
export const CHANGE_SELECTED_UPLOAD_INDEX = 'CHANGE_SELECTED_UPLOAD_INDEX';

export const REQUEST_FETCH_CATEGORY_TREE = 'REQUEST_FETCH_CATEGORY_TREE';
export const FETCH_CATEGORY_TREE_SUCCESS = 'FETCH_CATEGORY_TREE_SUCCESS';
export const FETCH_CATEGORY_TREE_FAILURE = 'FETCH_CATEGORY_TREE_FAILURE';

export const MODIFY_SELECTED_CATEGORY_OBJ = 'MODIFY_SELECTED_CATEGORY_OBJ';

export const REQUEST_FETCH_SCALEIDS = 'REQUEST_FETCH_SCALEIDS';
export const FETCH_SCALEIDS_SUCCESS = 'FETCH_SCALEIDS_SUCCESS';
export const FETCH_SCALEIDS_FAILURE = 'FETCH_SCALEIDS_FAILURE';

export const CHANGE_SELECTED_SCALEID = 'CHANGE_SELECTED_SCALEID';

export const INCREMENT_FILE_UPLOAD_STEP = 'INCREMENT_FILE_UPLOAD_STEP';

export const SET_FILE_UPLOAD_STEP = 'SET_FILE_UPLOAD_STEP';

export const REQUEST_DOWNLOAD_TEMPLATE = 'REQUEST_DOWNLOAD_TEMPLATE';
export const DOWNLOAD_TEMPLATE_SUCCESS = 'DOWNLOAD_TEMPLATE_SUCCESS';
export const DOWNLOAD_TEMPLATE_FAILURE = 'DOWNLOAD_TEMPLATE_FAILURE';

export const REQUEST_UPLOAD_GENERIC_EXCEL = 'REQUEST_UPLOAD_GENERIC_EXCEL';
export const UPLOAD_GENERIC_EXCEL_SUCCESS = 'UPLOAD_GENERIC_EXCEL_SUCCESS';
export const UPLOAD_GENERIC_EXCEL_FAILURE = 'UPLOAD_GENERIC_EXCEL_FAILURE';

export const RESET_EXCEL_UPLOAD = 'RESET_EXCEL_UPLOAD';

export const REQUEST_FETCH_ALL_SUPPLIERS = 'REQUEST_FETCH_ALL_SUPPLIERS';
export const FETCH_ALL_SUPPLIERS_SUCCESS = 'FETCH_ALL_SUPPLIERS_SUCCESS';
export const FETCH_ALL_SUPPLIERS_FAILURE = 'FETCH_ALL_SUPPLIERS_FAILURE';

export const SUPPLIER_SELECT = 'SUPPLIER_SELECT';

export const SET_EXCEL_FILE = 'SET_EXCEL_FILE';

export const SET_SELECTED_SOURCE = 'SET_SELECTED_SOURCE';

export const EXCEL_ROUTE_CHANGE = 'EXCEL_ROUTE_CHANGE';

export function fetchFileTypes() {

  return (dispatch) => {
    dispatch(requestFetchFileTypes());
    let response = axios.post('/api/fetchFileTypes');
    response.then((data) => {
      dispatch(fetchFileTypesSuccess(data));
    })
      .catch(error => {
        dispatch(fetchFileTypesFailure(error));
      });
    return response;
  };
}

export function requestFetchFileTypes() {
  return {
    type: REQUEST_FETCH_FILE_TYPES,
  };
}

export function fetchFileTypesSuccess(d) {
  return {
    type: FETCH_FILE_TYPES_SUCCESS,
    data: d.data
  };
}

export function fetchFileTypesFailure(d) {
  return {
    type: FETCH_FILE_TYPES_FAILURE,
    data: d
  };
}

export function changeSelectedUploadIndex(index) {
  return (dispatch, getState) => {
    var fileTypeData = getState().excelUpload.fileTypeData;
    var schema = JSON.parse(fileTypeData[index].file_type_key_schema);
    var keyObj = schema.properties.key;
    var isBulkUpload = (keyObj).hasOwnProperty('properties') ? true : false;
    var ts = isBulkUpload ? Object.keys(keyObj.properties).length + 1 : 1;
    var obj = {
      data: index,
      isBulkUpload: isBulkUpload,
      totalSteps: ts
    };
    dispatch(changeSelectedUploadInd(obj));
  };
}

export function changeSelectedUploadInd(obj) {
  return {
    type: CHANGE_SELECTED_UPLOAD_INDEX,
    data: obj.data,
    isBulkUpload: obj.isBulkUpload,
    totalSteps: obj.totalSteps
  };
}

export function fetchCategoryTree() {

  return (dispatch) => {
    dispatch(requestFetchCategoryTree());
    let response = axios.get('/api/fetchCategoryTree');
    response.then((data) => {
      dispatch(fetchCategoryTreeSuccess(data));
    })
      .catch(error => {
        dispatch(fetchCategoryTreeFailure(error));
      });
    return response;
  };
}

export function requestFetchCategoryTree() {
  return {
    type: REQUEST_FETCH_CATEGORY_TREE,
  };
}

export function fetchCategoryTreeSuccess(d) {
  let items = d.data.items;
  let superCategories = items.find(item => item.type === 'super-category').data;
  let categories = items.find(item => item.type === 'category').data;
  let subCategories = items.find(item => item.type === 'sub-category').data;
  let subSubCategories = items.find(item => item.type === 'sub-sub-category').data;

  let categoryTreeObj = {
    superCategories: superCategories,
    categories: categories,
    sub_categories: subCategories,
    sub_sub_categories: subSubCategories,
  };
  return {
    type: FETCH_CATEGORY_TREE_SUCCESS,
    data: categoryTreeObj
  };
}

export function fetchCategoryTreeFailure(d) {
  return {
    type: FETCH_CATEGORY_TREE_FAILURE,
    data: d
  };
}

export function modifySelectedCategoryObj(obj) {
  return {
    type: MODIFY_SELECTED_CATEGORY_OBJ,
    data: obj
  };
}

export function fetchScaleIds(data) {
  return (dispatch) => {
    dispatch(requestFetchScaleIds());
    let response = axios.get(`/api/fetchScaleIds/${data}`);
    response.then((data) => {
      dispatch(fetchScaleIdsSuccess(data));
    })
      .catch(error => {
        dispatch(fetchScaleIdsFailure(error));
      });
    return response;
  };
}

export function requestFetchScaleIds() {
  return {
    type: REQUEST_FETCH_SCALEIDS,
  };
}

export function fetchScaleIdsSuccess(d) {
  return {
    type: FETCH_SCALEIDS_SUCCESS,
    data: d.data.scale_ids
  };
}

export function fetchScaleIdsFailure(d) {
  return {
    type: FETCH_SCALEIDS_FAILURE,
    data: d
  };
}

export function changeSelectedScaleId(id) {
  return {
    type: CHANGE_SELECTED_SCALEID,
    data: id
  };
}

export function incrementFileUploadStep() {
  return {
    type: INCREMENT_FILE_UPLOAD_STEP
  };
}

export function setFileUploadStep(step) {
  return {
    type: SET_FILE_UPLOAD_STEP,
    data: step
  };
}

export function downloadTemplate(data) {
  let file_extension = data.file_extension;
  return (dispatch) => {
    dispatch(requestDownloadTemplate());
    let response = axios.post('/api/downloadTemplate', data, {responseType: 'blob'});
    response.then((data) => {
      dispatch(downloadTemplateSuccess(data, file_extension));
    })
      .catch(error => {
        dispatch(downloadTemplateFailure(error));
      });
    return response;
  };
}

export function requestDownloadTemplate() {
  return {
    type: REQUEST_DOWNLOAD_TEMPLATE,
  };
}

export function downloadTemplateSuccess(d, ext) {
  let data = d.data;
  let mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8';
  if(ext == 'csv'){
    mimeType = 'text/csv';
  }
  downloadFileFromStream(data, Date.now(), mimeType);
  return {
    type: DOWNLOAD_TEMPLATE_SUCCESS,
    data: d.data
  };
}

export function downloadTemplateFailure(d) {
  return {
    type: DOWNLOAD_TEMPLATE_FAILURE,
    data: d
  };
}

export function uploadGenericExcel(data) {
  return (dispatch) => {
    let formData = new FormData();
    formData.append('file_type', data.file_type);
    formData.append('params', JSON.stringify(data.params));
    formData.append('file', data.file);
    dispatch(requestUploadGenericExcel());
    let response = axios.post('/api/uploadGenericExcel', formData);
    response.then((data) => {
      dispatch(uploadGenericExcelSuccess(data));
    })
      .catch(error => {
        dispatch(uploadGenericExcelFailure(error));
      });
    return response;
  };
}

export function requestUploadGenericExcel() {
  return {
    type: REQUEST_UPLOAD_GENERIC_EXCEL,
  };
}

export function uploadGenericExcelSuccess(d) {
  return {
    type: UPLOAD_GENERIC_EXCEL_SUCCESS,
    data: d.data
  };
}

export function uploadGenericExcelFailure(d) {
  return {
    type: UPLOAD_GENERIC_EXCEL_FAILURE,
    data: d.response?.data?.errors?.form !== undefined ? d.response.data.errors.form  :  'File Upload Failed'
  };
}

export function resetExcelUpload() {
  return {
    type: RESET_EXCEL_UPLOAD,
  };
}

export function fetchAllSuppliers() {
  return (dispatch) => {
    dispatch(requestFetchAllSuppliers());
    let response = axios.post('/api/fetchAllSuppliers');
    response.then((data) => {
      dispatch(fetchAllSuppliersSuccess(data));
    })
      .catch(error => {
        dispatch(fetchAllSuppliersFailure(error));
      });
    return response;
  };
}

export function requestFetchAllSuppliers() {
  return {
    type: REQUEST_FETCH_ALL_SUPPLIERS,
  };
}

export function fetchAllSuppliersSuccess(d) {
  return {
    type: FETCH_ALL_SUPPLIERS_SUCCESS,
    data: d.data.data.suppliers
  };
}

export function fetchAllSuppliersFailure(d) {
  return {
    type: FETCH_ALL_SUPPLIERS_FAILURE,
    data: d
  };
}

export function supplierSelect(option) {
  return {
    type: SUPPLIER_SELECT,
    data: option
  };
}

export function setExcelFile(file) {
  return {
    type: SET_EXCEL_FILE,
    data: file
  };
}

export function setSelectedSource(data) {
  return {
    type: SET_SELECTED_SOURCE,
    data
  };
}

export function excelRouteChange() {
  return {
    type: EXCEL_ROUTE_CHANGE
  };
}
