import {
  IS_LOADING,
  ON_FAILURE_GET_LIST,
  ON_SUCCESS_GET_LIST,
  CLEAR_SNACKBAR_MESSAGE,
  UPDATE_OFFSET,
  UPDATE_LIMIT,
} from '../actions/types';

import {
  MANUAL,
  REDACTION,
  OFFSET_KEY,
  LIMIT_KEY,
  COUNT_KEY,
} from '../components/dashboard/SearchOps/qclCorrection/constants';

const INITIAL_STATE = {
  isLoading: false,
  manuals: [],
  message: '',
  [MANUAL + OFFSET_KEY]: 0,
  [MANUAL + LIMIT_KEY]: 500,
  [MANUAL + COUNT_KEY]: 0,
  [REDACTION + OFFSET_KEY]: 0,
  [REDACTION + LIMIT_KEY]: 500,
  [REDACTION + COUNT_KEY]: 0,
};

export default function qclCorrection(state = INITIAL_STATE, action) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ON_FAILURE_GET_LIST:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case ON_SUCCESS_GET_LIST:
      return {
        ...state,
        isLoading: false,
        ...action.payload,
      };
    case CLEAR_SNACKBAR_MESSAGE:
      return {
        ...state,
        message: '',
      };
    case UPDATE_OFFSET:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_LIMIT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
