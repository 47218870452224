export const SET_CATEGORY_TYPE = '@@searchIndexingRule/SET_CATEGORY_TYPE';
export const SET_ALL_CATEGORIES = '@@searchIndexingRule/SET_ALL_CATEGORIES';
export const SET_LOADER = '@@searchIndexingRule/SET_LOADER';
export const SET_SELECTED_CATEGORY_VALUE =
  '@@searchIndexingRule/SET_SELECTED_CATEGORY_VALUE';
export const SET_TIER = '@@searchIndexingRule/SET_TIER';
export const SET_AVAILABLE_FIELDS = '@@searchIndexingRule/SET_AVAILABLE_FIELDS';
export const ADD_SELECTED_FILEDS = '@@searchIndexingRule/ADD_SELECTED_FILEDS';
export const DELETE_SELECTED_FILEDS =
  '@@searchIndexingRule/DELETE_SELECTED_FILEDS';
export const SET_IS_RULE_EXIST = '@@searchIndexingRule/SET_IS_RULE_EXIST';
export const SET_FIELDS = '@@searchIndexingRule/SET_FIELDS';
export const RULE_UPDATED = '@@searchIndexingRule/RULE_UPDATED';
export const RULE_CREATED = '@@searchIndexingRule/RULE_CREATED';
export const REQUEST_FAILED = '@@searchIndexingRule/REQUEST_FAILED';
export const SET_ALL_RULES = '@@searchIndexingRule/SET_ALL_RULES';
export const RULE_DELETED = '@@searchIndexingRule/RULE_DELETED';
export const CLEAR_FIELDS_ON_ADD_RULE_PAGE_UNMOUNT =
  '@@searchIndexingRule/CLEAR_FIELDS_ON_ADD_RULE_PAGE_UNMOUNT';
export const RESET_REDUCER = '@@searchIndexingRule/RESET_REDUCER';
export const RESET_SNACKBAR_MESSAGE =
  '@@searchIndexingRule/RESET_SNACKBAR_MESSAGE';
export const CHANGE_ACTIVE_TAB =
  '@@searchIndexingRule/CHANGE_ACTIVE_TAB';
export const SET_MTRC_DATA =
  '@@searchIndexingRule/SET_MTRC_DATA';
export const MTRC_UPDATED = '@@searchIndexingRule/MTRC_UPDATED';
export const UPDATE_RULE = '@@searchIndexingRule/UPDATE_RULE';
export const RULE_STATUS_UPDATED = '@@searchIndexingRule/RULE_STATUS_UPDATED';
export const SET_CATALOG_BUCKET = '@@searchIndexingRule/SET_CATALOG_BUCKET';
export const SET_CATALOG_CREATION_DATE = '@@searchIndexingRule/SET_CATALOG_CREATION_DATE';
export const RULE_APPLICATBILITY_UPDATED = '@@searchIndexingRule/RULE_APPLICATBILITY_UPDATED';
export const SET_APPLICABILITY_TYPE = '@@searchIndexingRule/APPLICABILITY_TYPE_UPDATED';
export const SET_IS_RULE_FETCHING = '@@searchIndexingRule/SET_IS_RULE_FETCHING';
  
  

  