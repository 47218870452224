import {
    IS_LOADING,
    GET_STOP_WORDS_LIST,
    ON_FAILURE,
    CLEAR_SNACKBAR_MESSAGE,
    ADD_STOP_WORD,
    UPDATE_STOP_WORD,
    DELETE_STOP_WORD,
    UPDATE_STOP_WORD_SEARCH_QUERY,
	  UPDATE_STOP_WORD_SEARCH_ERROR
  } from '../components/dashboard/SearchOps/stopWords/types';
  
  const INITIAL_STATE = {
    isLoading: false,
    stopWordsList: [],
    totalPages: 0,
    message: null,
    page: 1,
    pageSize: 500,
    errorMessage: null,
    isSearchResults: false,
    searchQuery: '',
    searchError: '',
    count: 0
  };
  
  export default function stopWords(state = INITIAL_STATE, action) {
    switch (action.type) {
      case IS_LOADING:
        return {
          ...state,
          isLoading: action.payload,
        };
      case ON_FAILURE:
        return {
          ...state,
          isLoading: false,
					errorMessage: action.payload
        };
      case GET_STOP_WORDS_LIST:
        return {
          ...state,
          isLoading: false,
					stopWordsList: action.payload.stop_words,
					page: action.payload.currentPage,
					totalPages: Math.ceil(action.payload.total_count/state.pageSize),
          isSearchResults: action.payload.isSearchResults,
          count: action.payload.total_count
        };
      case ADD_STOP_WORD:
        return {
          ...state,
					message: action.payload
        };
      case UPDATE_STOP_WORD:
				const updatedList = state.stopWordsList.map((item) => {
					if(item.id === action.payload.data.id) {
						item.stop_word  = action.payload.data.updated_word;
					}
					return item;
				});
        return {
          ...state,
					stopWordsList: updatedList,
					message: action.payload.message
        };
      case DELETE_STOP_WORD:
        return {
          ...state,
          message: action.payload.message
        };
      case CLEAR_SNACKBAR_MESSAGE:
        return {
          ...state,
          message: null,
          errorMessage: null
        };
      case UPDATE_STOP_WORD_SEARCH_QUERY:
        return {
          ...state,
          searchQuery: action.payload,
          searchError: ''
        };
      case UPDATE_STOP_WORD_SEARCH_ERROR:
        return {
          ...state,
          searchError: action.payload
        };
      
      default:
        return state;
    }
  }
  