import axios from 'axios';
import { browserHistory } from '@browserHistory';

export const SET_SELECTED_USER_CALL = 'SET_SELECTED_USER_CALL';
export const FETCH_USER = 'FETCH_USER';
export const SET_USER = 'SET_USER';
export const SET_SELECTED_ORDER_ROW_DATA = 'SET_SELECTED_ORDER_ROW_DATA';
export const IS_SEARCH_BY_ORDER_NUM = 'IS_SEARCH_BY_ORDER_NUM';
export const CLEAR_ORDER_NUM = 'CLEAR_ORDER_NUM';
export const CLEAR_SELECTED_ORDER_IDS = 'CLEAR_SELECTED_ORDER_IDS';
export const FETCH_USER_FROM_SEARCH = 'FETCH_USER_FROM_SEARCH';

const setSelectedOrderRowDataCall = (data) => {
  return {
    type: SET_SELECTED_ORDER_ROW_DATA,
    data
  };
};

export const setSelectedOrderRowData = (orderRowDetails) => {
  return (dispatch) => {
    dispatch(setSelectedOrderRowDataCall(orderRowDetails));
  };
};

export const fetchPaymentTimeLine = (data) => {
  return (dispatch) => {
    let response = axios.post('/api/agentDashboard/fetchPaymentTimeLine', data);
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};

export const fetchOutstandingPayments = (data) => {
  return (dispatch) => {
    let response = axios.post('/api/agentDashboard/fetchOutstandingPayments', data);
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};

export const fetchUserFromSearch = (data) => {
  return (dispatch) => {
    let response = axios.post('/api/agentDashboard/searchForUser', data);
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};

export function setSelectedUser(user) {
  return (dispatch) => {
    dispatch(setSelectedUserCall(user));
  };
}

export function setSelectedUserCall(data) {
  return {
    type: SET_SELECTED_USER_CALL,
    data,
  };
}

export const fetchWalletDetails = (data) => {
  return (dispatch) => {
    let response = axios.post('/api/agentDashboard/fetchWalletDetails', data);
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};

export const fetchUserReferrals = (data) => {
  return (dispatch) => {
    let response = axios.post('/api/agentDashboard/fetchUserReferrals', data);
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};

export const fetchReferralDetailsByRefId = (data) => {
  return (dispatch) => {
    let response = axios.post(
      '/api/agentDashboard/fetchReferralDetailsByRefId',
      data
    );
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};

export const fetchReferralOrders = (data) => {
  return (dispatch) => {
    let response = axios.post('/api/agentDashboard/fetchReferralOrders', data);
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};

export const fetchTicketDetails = (data) => {
  return (dispatch) => {
    let response = axios.post(
      '/api/agentDashBoard/fetchTickets',
      data
    );
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};

export const fetchBonusDetails = (data) => {
  return (dispatch) => {
    let response = axios.post('/api/agentDashBoard/fetchBonusDetails', data);
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};

export const fetchUserDetails = (data) => {
  return (dispatch) => {
    dispatch(userLoading(true));
    let response = axios.post('/api/agentDashBoard/fetchUserDetails', data);
    response.then(
      function(res) {
        dispatch(setUser(res.data));
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};

const userLoading = (mode) => ({
  type: FETCH_USER,
  payload: mode,
});

const setUser = (data) => ({
  type: SET_USER,
  payload: data,
});


export const setSearchByOrderNum = (mode) => ({
  type: IS_SEARCH_BY_ORDER_NUM,
  payload: mode
});

export const clearOrderSearchField = () => ({
  type: CLEAR_ORDER_NUM
});

export const cleanSelectedOrderIdsFromDisposition = () => ({
  type: CLEAR_SELECTED_ORDER_IDS,
})

export const fetchReferralPayments = (data) => {
  return (dispatch) => {
    let response = axios.post(
      '/api/agentDashboard/fetchReferralPayments',
      data
    );
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};

export const fetchAgentDisposition = (data) => {
  return (dispatch) => {
    let response = axios.post(
      '/api/agentDashBoard/fetchAgentDisposition',
      data
    );
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};


export const fetchUserOrders = (data) => {
  return (dispatch) => {
    let response = axios.post(
      '/api/agentDashBoard/fetchOrders',
      data
    );
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};

export const fetchSingleOrders = (data) => {
  return (dispatch) => {
    let response = axios.post(
      '/api/agentDashBoard/fetchSingleOrders',
      data
    );
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};

export const fetchUserAccountDetails = (data) => {
  return (dispatch) => {
    let response = axios.post(
      '/api/agentDashboard/fetchUserAccountDetails',
      data
    );
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};

export const postUserAccountDetailsUpdate = (data) => {
  return (dispatch) => {
    let response = axios.post(
      '/api/agentDashboard/postUserAccountDetails',
      data
    );
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};

export const getProductReturnReason = (data) => {
  return (dispatch) => {
    let response = axios.post('/api/agentDashboard/fetchReturnReason', data);
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};


export const sendMessage = (data) => {
  return (dispatch) => {
    let response = axios.post(
      '/api/agentDashboard/sendMessage',
      data
    );
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};


export const getResellerInvoice = (data) => {
  return (dispatch) => {
    let response = axios.post(
      '/api/agentDashboard/getResellerInvoice',
      data
    );
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};


export const getCustomerInvoice = (data) => {
  return (dispatch) => {
    let response = axios.post(
      '/api/agentDashboard/getCustomerInvoice',
      data
    );
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};

export const postCreateDispositionTicket = (data) => {
  return (dispatch) => {
    if (!!data.call_new_api) {
      // 1. remove call_new_api
      // 2. remove order_data
      // 3. remove courier_escalation_type

      if(!!data.call_new_api) {
         delete data.call_new_api;
      }
      if(!!data.order_data) {
        delete data.order_data;
      }
      if(!!data.courier_escalation_type) {
         delete data.courier_escalation_type;
      }
      if(!!data.type_of_user && data.type_of_user.toLocaleLowerCase() === 'any') {
        delete data.type_of_user;
      }

      let response = axios.post(
        '/api/agentDashboard/createUserDispositionTicket',
        data
      );
      response.then(
        function () {
        },
        function (error) {
          if (
            error.response !== undefined &&
            error.response.status !== undefined &&
            error.response.status === 401
          ) {
            browserHistory.push('/login');
          }
        }
      );
      return response;
    } else {
      // 1. remove call_new_api flag
      if(!!data.call_new_api) {
         delete data.call_new_api;
      }
      if(!!data.type_of_user && data.type_of_user.toLocaleLowerCase() === 'any') {
         delete data.type_of_user;
      }

      let response = axios.post(
        '/api/agentDashboard/createDispositionTicket',
        data
      );
      response.then(
        function () {
        },
        function (error) {
          if (
            error.response !== undefined &&
            error.response.status !== undefined &&
            error.response.status === 401
          ) {
            browserHistory.push('/login');
          }
        }
      );
      return response;
    }
  }
};

export const postSaveDispositionTicket = (data) => {
  return (dispatch) => {
    let response = axios.post(
      '/api/agentDashboard/saveDispositionTicket',
      data
    );
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};

export function returnExchangeFetch(data) {
  return dispatch => {
    // let response = axios.post('/api/order/request/fetch', data);
    let response = axios.post('/api/agentDashboard/request/fetch', data);
    response.then(function() {
    }, function(error) {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        browserHistory.push('/login');
      }
    });
    return response;
  };
}

export const fetchOrdersMeta = (data) => {
  return (dispatch) => {
    let response = axios.post(
      '/api/agentDashboard/fetchOrdersMeta',
      data
    );
    response.then(
      function() {
      },
      function(error) {
        if(
          error.response !== undefined &&
          error.response.status !== undefined &&
          error.response.status === 401
        ) {
          browserHistory.push('/login');
        }
      }
    );
    return response;
  };
};

export function returnExchangeReasons(data) {
  return dispatch => {
    let response = axios.post('/api/agentDashboard/request/params', data);
    response.then(function() {
    }, function(error) {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        browserHistory.push('/login');
      }
    });
    return response;
  };
}

export function returnExchangeRequestAdd(data) {
  return dispatch => {
    let fd = new FormData();
    fd.append('order_id', data.order_id);
    fd.append('sub_order_num', data.sub_order_num);

    if(data.description !== undefined) {
      fd.append('description', data.description);
    }
    if(data.comment !== undefined) {
      fd.append('comment', data.comment);
    }
    if(data.reason_id !== undefined) {
      fd.append('reason_id', data.reason_id);
    }
    if(data.quantity !== undefined) {
      fd.append('quantity', data.quantity);
    }
    if(data.type !== undefined) {
      fd.append('type', data.type);
    }
    if(data.address !== undefined) {
      fd.append('address', JSON.stringify(data.address));
    }
    if(data.status !== undefined) {
      fd.append('status', data.status);
    }
    if(data.expected_dispatch_date !== undefined) {
      fd.append('expected_dispatch_date', data.expected_dispatch_date);
    }
    if(data.image_ids !== undefined) {
      fd.append('image_ids', JSON.stringify(data.image_ids));
    }
    if(data.rejection_reason !== undefined) {
      fd.append('rejection_reason', data.rejection_reason);
    }
    if(data.variation !== undefined) {
      fd.append('variation', data.variation);
    }
    if(data.image_1 !== undefined) {
      fd.append('image_1', data.image_1);
    }
    if(data.image_2 !== undefined) {
      fd.append('image_2', data.image_2);
    }
    if(data.image_3 !== undefined) {
      fd.append('image_3', data.image_3);
    }
    if(data.image_4 !== undefined) {
      fd.append('image_4', data.image_4);
    }
    let opts = {
      transformRequest: function(fd) {
        return fd;
      }
    };
    let response = axios.post('/api/agentDashboard/return-exchange/edit', fd, opts);
    response.then(function() {
    }, function(error) {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        browserHistory.push('/login');
      }
    });
    return response;
  };
}

export function fetchResellerBonusDetailsAgent(data) {
  return dispatch => {
    let response = axios.post('/api/agentDashBoard/fetchBonusDetailsForUser', data);
    response.then(function() {
    }, function(error) {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        browserHistory.push('/login');
      }
    });
    return response;
  };
} 