import moment from 'moment-timezone';

export default class Globalize {
  constructor(user) {
    let language_code =
      user !== undefined &&
      user.language !== undefined &&
      user.language.iso_language_code !== undefined
        ? user.language.iso_language_code
        : 'EN';
    let country_code =
      user !== undefined &&
      user.country !== undefined &&
      user.country.iso_country_code !== undefined
        ? user.country.iso_country_code
        : 'IN';
    Globalize.language = language_code;
    Globalize.country = country_code;
  }

  static getString(str) {
    if (Globalize.constants[str] !== undefined) {
      if (
        Globalize.constants[str][this.language] !== undefined &&
        Globalize.constants[str][this.language].length !== 0
      ) {
        return Globalize.constants[str][this.language];
      } else if (
        Globalize.constants[str]['EN'] !== undefined &&
        Globalize.constants[str]['EN'].length !== 0
      ) {
        return Globalize.constants[str]['EN'];
      } else {
        return str.toUpperCase().replace(/_/g, ' ');
      }
    } else {
      return str.toUpperCase().replace(/_/g, ' ');
    }
  }

  static getCountry() {
    return this.country;
  }

  static getCurrencyText() {
    return this.country === 'IN' ? 'Rs. ' : 'Rp. ';
  }

  static getCurrencySymbol() {
    return this.country === 'IN' ? '₹ ' : 'Rp. ';
  }

  static getLanguage() {
    return this.language;
  }
}

Globalize.constants = {
  welcome_string: {
    EN: 'Welcome to Meesho Supply',
    ID: 'Ubah target audiens otomatis',
  },
  farmiso: {
    EN: 'Farmiso',
    ID: '',
  },
  search_ops: {
    EN: 'Search',
    ID: '',
  },
  search_engine_review: {
    EN: 'Search Engine Review',
    ID: '',
  },
  edit_automated_audience: {
    EN: 'Edit Automated Audience',
    ID: 'Ubah target audiens otomatis',
  },
  add_new_automated_audience: {
    EN: 'Add New Automated Audience',
    ID: 'Tambahkan target audiens otomatis',
  },
  download_file: {
    EN: 'Download File',
    ID: 'Download file',
  },
  description: {
    EN: 'Description',
    ID: 'Deskripsi',
  },
  sql_query: {
    EN: 'SQL Query',
    ID: 'SQL Query',
  },
  submit: {
    EN: 'Submit',
    ID: 'Kirim',
  },
  actions: {
    EN: 'Actions',
    ID: 'Tindakan',
  },
  all: {
    EN: 'All',
    ID: '',
  },
  asc: {
    EN: 'ASC',
    ID: 'ASC',
  },
  desc: {
    EN: 'DESC',
    ID: 'DESC',
  },
  view_order: {
    EN: 'View Order',
    ID: 'Lihat Pesanan',
  },
  view_timeline: {
    EN: 'View Timeline',
    ID: 'Lihat Perkiraan Waktu',
  },
  order_selected: {
    EN: 'Order Selected',
    ID: 'Pesanan Yang Dipilih',
  },
  see_all_referrals: {
    EN: 'See all referrals',
    ID: '',
  },
  acknowledged: {
    EN: 'Acknowledged',
    ID: 'Acknowledged',
  },
  audience_create_success_message: {
    EN: 'We’ll provide you with an email confirmation once the audience has been created!',
    ID: 'Kami akan mengonfirmasi melalui email setelah audiens berhasil dibuat!',
  },
  created_date_filter: {
    EN: 'Created Date Filter',
    ID: '',
  },
  updated_date_filter: {
    EN: 'Updated Date Filter',
    ID: '',
  },
  date_filter: {
    EN: 'Date Filter',
    ID: 'Date Filter',
  },
  dlt_status_filter: {
    EN: 'DLT Status Filter',
    ID: 'DLT Status Filter',
  },
  template_details_updated: {
    EN: 'Template details updated!',
    ID: 'Template details updated!',
  },
  create_sms_template: {
    EN: 'Create SMS Template',
    ID: '',
  },
  sms_type_filter: {
    EN: 'SMS Type Filter',
    ID: 'SMS Type Filter',
  },
  no_messages_found: {
    EN: 'No Messages Found',
    ID: '',
  },
  audience_create_id: {
    EN: 'Audience created with Audience ID',
    ID: 'Audiens telah dibuat dengan ID Audiens',
  },
  audience_users_uploaded: {
    EN: 'Number of users uploaded',
    ID: 'Jumlah pengguna yang meng-upload',
  },
  na: {
    EN: 'N.A',
    ID: '',
  },
  name_is_required: {
    EN: 'Name is required',
    ID: 'Nama diperlukan',
  },
  description_is_required: {
    EN: 'Description is required',
    ID: 'Deskripsi diperlukan',
  },
  sms_templates: {
    EN: 'SMS Templates',
    ID: 'SMS Templates',
  },
  verify_supplier_kyc: {
    EN: 'Verify Supplier KYC',
    ID: 'Verify Supplier KYC',
  },
  supplier_finance: {
    EN: 'Supplier Finance',
    ID: 'Supplier Finance',
  },
  supplier_finance_bank_account_error: {
    EN: 'Account Holder Name cannot be empty',
    ID: 'Account Holder Name cannot be empty',
  },
  supplier_finance_kyc_empty_error: {
    EN: 'Select a valid option for KYC details verification status',
    ID: 'Select a valid option for KYC details verification status',
  },
  supplier_finance_bank_details_empty_error: {
    EN: 'Select a valid option for Bank details verification status',
    ID: 'Select a valid option for Bank details verification status',
  },
  available_sms_templates: {
    EN: 'Available SMS Templates',
    ID: 'Available SMS Templates',
  },
  file_is_required: {
    EN: 'File is required',
    ID: 'File diperlukan',
  },
  edit_manual_audience: {
    EN: 'Edit Manual Audience',
    ID: 'Ubah target audiens manual',
  },
  add_new_manual_audience: {
    EN: 'Add New Manual Audience',
    ID: 'Tambahkan target audiens manual',
  },
  search_by_supplier_name_or_supplier_id: {
    EN: 'Search by Supplier Name or Id',
    ID: 'Search by Supplier Name or Id',
  },
  upload_new_file: {
    EN: 'Upload New File',
    ID: 'Upload File Baru',
  },
  remove: {
    EN: 'Remove',
    ID: 'Hapus',
  },
  audience_system: {
    EN: 'Audience System',
    ID: 'Sistem Audiens',
  },
  bank_account_holder_name: {
    EN: 'Bank Account Holder Name',
    ID: '',
  },
  provide_a_meaningful_name_to_the_sms_template: {
    EN: 'Provide a meaningful name to the sms template',
    ID: '',
  },
  select_the_language_of_the_template: {
    EN: 'Select the language of the template',
    ID: '',
  },
  select_the_sms_type: {
    EN: 'Select the SMS Type',
    ID: '',
  },
  select_a_sender_id_as_per_the_chosen_sms_type: {
    EN: 'Select a sender id as per the chosen SMS type',
    ID: '',
  },
  is_the_template_used_for_campaigns: {
    EN: 'Is the template used for campaigns?',
    ID: '',
  },
  provide_a_priority: {
    EN: 'Provide a priority',
    ID: '',
  },
  content: {
    EN: 'Content',
    ID: '',
  },
  provide_the_sms_content_for_the_template: {
    EN: 'Provide the SMS content of the template',
    ID: '',
  },
  read_guidelines: {
    EN: 'Read Guidelines',
    ID: '',
  },
  enter_sms_content_with_variables: {
    EN: 'Enter SMS content with variables.',
    ID: '',
  },
  add_manual_audience_system: {
    EN: 'Add Manual Audience System',
    ID: 'Tambahkan sistem audiens manual',
  },
  add_automated_audience_system: {
    EN: 'Add Automated Audience System',
    ID: 'Tambahkan sistem audiens otomatis',
  },
  show_only_my_audiences: {
    EN: 'Show only my audiences',
    ID: 'Tampilkan hanya audiens saya',
  },
  no_data_found: {
    EN: 'No data found',
    ID: 'Tidak ada data ditemukan',
  },
  id: {
    EN: 'ID',
    ID: 'ID',
  },
  audience_name: {
    EN: 'Audience Name',
    ID: 'Nama audiensi',
  },
  audience_size: {
    EN: 'Audience Size',
    ID: '',
  },
  count: {
    EN: 'Count',
    ID: 'Jumlah',
  },
  owner: {
    EN: 'Owner',
    ID: 'Pemilik',
  },
  type: {
    EN: 'Type',
    ID: 'Tipe',
  },
  action: {
    EN: 'Action',
    ID: 'Tindakan',
  },
  catalog_screen: {
    EN: 'Catalog Screen',
    ID: 'Tampilan Katalog',
  },
  catalog_id: {
    EN: 'Catalog Id',
    ID: 'ID Katalog',
  },
  catalog_name: {
    EN: 'Catalog Name',
    ID: 'Nama Katalog',
  },
  collection_id: {
    EN: 'Collection Id',
    ID: 'ID Koleksi',
  },
  collection_name: {
    EN: 'Collection Name',
    ID: 'Nama Koleksi',
  },
  enter_collection_id: {
    EN: 'Enter Collection Id',
    ID: 'Enter Collection Id',
  },
  start_time: {
    EN: 'Start Time',
    ID: 'Waktu mulai',
  },
  end_time: {
    EN: 'End Time',
    ID: 'Waktu berakhir',
  },
  time_taken: {
    EN: 'Time Taken',
    ID: 'Waktu berakhir',
  },
  priority: {
    EN: 'Priority',
    ID: 'Prioritas',
  },
  hidden: {
    EN: 'Hidden',
    ID: '',
  },
  app_version: {
    EN: 'App Version',
    ID: '',
  },
  min_app_version: {
    EN: 'Min App Android Version',
    ID: 'Versi minimal Android aplikasi',
  },
  max_app_version: {
    EN: 'Max App Android Version',
    ID: 'Versi maksimal Android aplikasi',
  },
  min_app_ios_version: {
    EN: 'Min App IOS Version',
    ID: 'Versi minimal IOS aplikasi',
  },
  max_app_ios_version: {
    EN: 'Max App IOS Version',
    ID: 'Versi maksimal IOS aplikasi',
  },
  old_audience: {
    EN: 'Old Audience',
    ID: 'Audiens lama',
  },
  new_audience: {
    EN: 'New Audience',
    ID: 'Audiens baru',
  },
  audience: {
    EN: 'Audience',
    ID: 'Audiens',
  },
  edit: {
    EN: 'Edit',
    ID: 'Edit',
  },
  banner_image_dimension_message: {
    EN: 'The dimensions of the image should be either 1080*720 or 1200x466.',
    ID: 'Dimensi gambar harus 1080x720 atau 1200x466.',
  },
  banner_image_caraousel_dimension_message: {
    EN: 'Aspect ratio of the image should be 2.41.',
    ID: 'Rasio aspek gambar harus 2,41.',
  },
  banner_image_size_message: {
    EN: 'The size of the should be less than 120 KB',
    ID: 'Ukuran file harus kurang dari 120 KB',
  },
  input_fields_missing: {
    EN: 'Input Fields Missing.',
    ID: 'Halaman input hilang.',
  },
  edit_banner: {
    EN: 'Edit Banner',
    ID: 'Edit banner',
  },
  add_banner: {
    EN: 'Add Banner',
    ID: 'Tambahkan banner',
  },
  banner_image_dimension_heading_message: {
    EN: 'Banner Image[ Dimensions either 1200x466 or 1080x720 and Size &lt;= 120 KB ]',
    ID: 'Gambar banner [Dimensi harus 1200x466 atau 1080x720 dan ukuran file &lt;= 120 KB]',
  },
  banner_caraousel_image_dimension_heading_message: {
    EN: 'Banner Carousel Image [Aspect ratio should be 2.41 and size &lt;= 120KB]',
    ID: 'Gambar Korsel Spanduk [Rasio aspek harus 2,41 dan ukuran & lt; = 120KB]',
  },
  carousel_community_image_message: {
    EN: 'Can not select carousel and showcasing screen community at a time',
    ID: 'Tidak dapat memilih carousel dan menampilkan komunitas layar dalam satu waktu',
  },
  community_banner_image_dimension_message: {
    EN: 'For Community showcasing screen [Dimensions should be 328x112]',
    ID: 'untuk layar casing acara komunitas [Dimensi harus 328x112]',
  },
  community_banner_image_dimension_error_message: {
    EN: 'Dimensions should be 328x112',
    ID: 'Dimensi harus 328x112',
  },
  error_awb_number_or_delivery_partner_not_found: {
    EN: 'Error: AWB Number or Delivery partner for one or more orders not found.',
    ID: '',
  },
  banner_show_casing_text: {
    EN: 'Banner showcasing screen',
    ID: 'Layar pajangan spanduk',
  },
  old_banner_audiences: {
    EN: 'Old Banner Audiences',
    ID: 'Banner Audiens Lama',
  },
  select_banner_audiences: {
    EN: 'Select Banner Audiences',
    ID: 'Pilih Banner Audiens',
  },
  select_widget_audiences: {
    EN: 'Select Widget Audiences',
    ID: 'Pilih Widget Audiens',
  },
  exclude_widget_audiences: {
    EN: 'Select Widget Audiences To Be Excluded',
    ID: 'Select Widget Audiences To Be Excluded',
  },
  new_banner_audiences: {
    EN: 'New Banner Audiences',
    ID: 'Banner Audiens Baru',
  },
  new_widget_audiences: {
    EN: 'New Widget Audiences',
    ID: 'Audiens Widget Baru',
  },
  banner_screen: {
    EN: 'Banner Screen',
    ID: 'Tampilan Banner',
  },
  lc_category_enabled: {
    EN: 'LC Category Banner Enabled',
  },
  catalog_name_search: {
    EN: 'Catalog Name Search',
    ID: 'Cari Nama Katalog',
  },
  collection_name_search: {
    EN: 'Collection Name Search',
    ID: 'Cari Nama Koleksi',
  },
  web_view_title: {
    EN: 'Web View Title',
    ID: 'Judul Tampilan Web',
  },
  view_ticket: {
    EN: 'View Ticket',
    ID: 'Lihat Tiket',
  },
  web_view_url: {
    EN: 'Web View URL',
    ID: 'URL Tampilan Web',
  },
  live_stream_url: {
    EN: 'Live Stream URL',
    ID: 'URL Streaming Language'
  },
  live_stream_title: {
    EN: 'Live Stream Title',
    ID: 'Judul Streaming Language'
  },
  browser_url: {
    EN: 'Browser URL',
    ID: 'Peramban URL',
  },
  community_url: {
    EN: 'Community URL',
    ID: 'URL Komunitas',
  },
  target_url: {
    EN: 'Target URL',
    ID: 'URL Target',
  },
  valid: {
    EN: 'Valid',
    ID: 'Valid',
  },
  yes: {
    EN: 'Yes',
    ID: 'Iya',
  },
  no: {
    EN: 'No',
    ID: 'Tidak',
  },
  upcoming_banners: {
    EN: 'Upcoming Banners',
    ID: 'Banner Yang Akan Datang',
  },
  no_upcoming_banners: {
    EN: 'No Upcoming Banners',
    ID: 'Tidak Ada Banner Baru',
  },
  current_banners: {
    EN: 'Current Banners',
    ID: 'Banner Saat Ini',
  },
  no_current_banners: {
    EN: 'No Current Banners',
    ID: 'Tidak Ada Banner Saat Ini',
  },
  past_banners: {
    EN: 'Past Banners',
    ID: 'Banner Sebelumnya',
  },
  no_past_banners: {
    EN: 'No Past Banners',
    ID: 'Tidak Ada Banner Sebelumnya',
  },
  catalog_order_actions: {
    EN: 'Catalog Order Actions',
    ID: 'Tindakan Pemesanan Katalog',
  },
  no_supplier_added_in_catalog: {
    EN: 'No Supplier Added in this Catalog',
    ID: 'Belum ada Supplier Yang Sudah Dimasukkan di Dalam Katalog',
  },
  search_catalog_to_pre_fill_data: {
    EN: 'Search Catalog to pre fill data',
    ID: 'Cari Katalog Untuk Mengisi Data',
  },
  catalog_fetch_error: {
    EN: 'Catalog Fetch Error retry',
    ID: 'Kesalahan Pengambilan Katalog, Coba Lagi',
  },
  drop_files_message: {
    EN: 'Try dropping some files here, or click to select files',
    ID: 'Coba Taruh Beberapa File Disini, Atau Klik Beberapa FIle',
  },
  open_drop_zone: {
    EN: 'Open Drop zone',
    ID: 'Buka Dropzone',
  },
  add_photo: {
    EN: 'Add Photo',
    ID: 'Tambah Photo',
  },
  ref_image: {
    EN: 'Ref Image',
    ID: 'Foto Referensi',
  },
  auto_fill: {
    EN: 'Auto fill',
    ID: 'Isi Otomatis',
  },
  products: {
    EN: 'Products',
    ID: 'Produk',
  },
  re_upload_failed_taxonomy: {
    EN: 'Re Upload Failed Taxonomy',
    ID: 'Upload Kembali Taksonomi Yang Gagal',
  },
  re_upload_failed_products: {
    EN: 'Re Upload Failed Products',
    ID: 'Upload Kembali Produk Yang Gagal',
  },
  catalog_products_edit: {
    EN: 'Catalog Products Edit',
    ID: 'Edit Produk Katalog',
  },
  delete_all_products: {
    EN: 'Delete All Products',
    ID: 'Hapus Semua Produk',
  },
  no_products_added_in_catalog: {
    EN: 'No Products added in this catalog.',
    ID: 'Tidak Ada Produk yang Ditambahkan di Katalog Ini',
  },
  add_product: {
    EN: 'Add Product',
    ID: 'Tambah Produk',
  },
  sub_sub_category: {
    EN: 'Sub Sub Category',
    ID: 'Sub Sub Kategori',
  },
  sub_category: {
    EN: 'Sub Category',
    ID: 'Sub Kategori',
  },
  category: {
    EN: 'Category',
    ID: 'Kategori',
  },
  super_category: {
    EN: 'Super Category',
    ID: 'Kategori Super',
  },
  delete: {
    EN: 'Delete',
    ID: 'Hapus',
  },
  weight_in_grams: {
    EN: 'Weight in Grams',
    ID: 'Berat Dalam Satuan Gram',
  },
  slu: {
    EN: 'SKU',
    ID: 'SKU',
  },
  image: {
    EN: 'Image',
    ID: 'Gambar',
  },
  warning_message: {
    EN: 'Warning Message',
    ID: 'Pesan Peringatan',
  },
  catalog_warning_message: {
    EN: 'This Catalog Created 4 days ago. Have you confirmed stock availability',
    ID: 'Katalog ini sudah dibuat 4 hari lalu. Apakah Anda sudah konfirmasi persediaan stok',
  },
  yes_proceed: {
    EN: 'Yes, Proceed',
    ID: 'Ya, Lanjutkan',
  },
  schedule: {
    EN: 'Schedule',
    ID: 'Jadwal',
  },
  send_notification: {
    EN: 'Send Notification',
    ID: 'Kirim Notifikasi',
  },
  deactivate: {
    EN: 'Deactivate',
    ID: 'Dinonaktifkan',
  },
  enter_metadata_with_variables: {
    EN: 'Enter metadata with variables',
    ID: 'Masukkan data-data dengan nilai',
  },
  invalid_json: {
    EN: 'Enter valid JSON data',
    ID: 'Masukkan data JSON yang valid',
  },
  date_created: {
    EN: 'Date Created',
    ID: 'Date Created',
  },
  template_name: {
    EN: 'Template Name',
    ID: 'Template Name',
  },
  receiver_type:{
    EN: 'Receiver Type',
    ID: 'Receiver Type'
  },
  sender_id: {
    EN: 'Sender Id',
    ID: 'Sender Id',
  },
  provide_a_service_name: {
    EN: 'Provide a Service name',
    ID: '',
  },
  provider_acc_id: {
    EN: 'Provider acc. Id',
    ID: 'Provider acc. Id',
  },
  sms_type: {
    EN: 'SMS Type',
    ID: 'SMS Type',
  },
  sms_body: {
    EN: 'SMS Body',
    ID: 'SMS Body',
  },
  dlt_template_id: {
    EN: 'DLT Template Id',
    ID: 'DLT Template Id',
  },
  dlt_status: {
    EN: 'DLT Status',
    ID: 'DLT Status',
  },
  provider_status: {
    EN: 'Provider Status',
    ID: 'Provider Status',
  },
  select_valid_date_catalog_activation: {
    EN: 'Select valid date for catalog activation',
    ID: 'Pilih Tanggal Berlaku untuk Aktivasi Katalog',
  },
  select_valid_date_catalog_notification: {
    EN: 'Select valid date for catalog notification',
    ID: 'Pilih Tanggal Berlaku untuk Notifikasi Katalog',
  },
  select_valid_date_catalog_activation_notification: {
    EN: 'Select valid date for catalog activation and notification',
    ID: 'Pilih Tanggal Berlaku untuk Aktivasi dan Notifikasi Katalog',
  },
  select_notification_date: {
    EN: 'Please select a Notification Date',
    ID: 'Silahkan Pilih Tanggal Notifikasi',
  },
  select_activation_date: {
    EN: 'Please select a Activation Date',
    ID: 'Silahkan Pilih Tanggal Aktivasi',
  },
  catalog_activation: {
    EN: 'Catalog Activation',
    ID: 'Aktivasi Katalog',
  },
  stop_pre_booking_message: {
    EN: 'Are you sure you want to stop Pre-Booking for this catalog?',
    ID: 'Apakah Anda Yakin untuk menghentikan pra-pemesanan di Katalog ini?',
  },
  stop_pre_booking_supplier_message: {
    EN: 'Please make sure the respective supplier is aligned with this change - else it could become a problem!',
    ID: 'Pastikan Para Supplier Terkait Mengetahui Perubahan Ini Untuk Menghindari Masalah',
  },
  filter: {
    EN: 'Filter',
    ID: 'Filter',
  },
  filter_by_date: {
    EN: 'Filter by Date',
    ID: 'Filter by Date',
  },
  filter_by_created_date: {
    EN: 'Filter by Created Date',
    ID: '',
  },
  filter_by_updated_date: {
    EN: 'Filter by Updated Date',
    ID: '',
  },
  filter_by_dlt_status: {
    EN: 'Filter by DLT Status',
    ID: 'Filter by DLT Status',
  },
  filter_by_sms_type: {
    EN: 'Filter by SMS Type',
    ID: 'Filter by SMS Type',
  },
  search_by_sender_id: {
    EN: 'Search by Sender Id',
    ID: 'Search by Sender Id',
  },
  filter_by_transaction_date: {
    EN: 'Filter by Transaction Date',
    ID: '',
  },
  filter_by_type: {
    EN: 'Filter by Type',
    ID: '',
  },
  old_category_id: {
    EN: 'Old Category Id',
    ID: 'ID Kategori Lama',
  },
  activation_state: {
    EN: 'Activation State',
    ID: 'Status aktivasi',
  },
  select: {
    EN: 'Select',
    ID: 'Pilih',
  },
  activated: {
    EN: 'Activated',
    ID: 'Diaktifkan',
  },
  deactivated: {
    EN: 'Deactivated',
    ID: 'Dinonaktifkan',
  },
  for_you_notification_sent: {
    EN: 'For You Notification Sent',
    ID: 'Notifikasi terkirim untuk Anda',
  },
  not_for_you: {
    EN: 'Not For You',
    ID: 'Bukan untuk Anda',
  },
  activation_history: {
    EN: 'Activation History',
    ID: 'Histori aktivasi',
  },
  never_activated: {
    EN: 'Never Activated',
    ID: 'Belum pernah diaktifkan',
  },
  activated_once: {
    EN: 'Activated At Least Once',
    ID: 'Diaktifkan Paling Tidak Satu Kali',
  },
  created_from: {
    EN: 'Created From',
    ID: 'Dibuat Dari',
  },
  created_to: {
    EN: 'Created To',
    ID: 'Dibuat Untuk',
  },
  order_from: {
    EN: 'Order From',
    ID: 'Order Dari',
  },
  order_to: {
    EN: 'Order To',
    ID: 'Order Untuk',
  },
  activated_from: {
    EN: 'Activated From',
    ID: 'Diaktifkan Dari',
  },
  activated_to: {
    EN: 'Activated To',
    ID: 'Diaktifkan Untuk',
  },
  price_min: {
    EN: 'Price Min',
    ID: 'Harga Minimum',
  },
  price_max: {
    EN: 'Price Max',
    ID: 'Harga Maksimum',
  },
  product_id: {
    EN: 'Product Id',
    ID: 'ID Produk',
  },
  catalog_organize_id: {
    EN: 'Catalog Organize Id',
    ID: 'ID Pengatur Katalog',
  },
  audience_type: {
    EN: 'Audience Type',
    ID: 'Jenis Audiens',
  },
  clear: {
    EN: 'Clear',
    ID: 'Hapus Semua',
  },
  clear_all: {
    EN: 'Clear All',
    ID: 'Clear All',
  },
  search_catalog: {
    EN: 'Search Catalog',
    ID: 'Cari di katalog',
  },
  catalog_schedule_warning_message: {
    EN: 'You Cann\'t Schedule this Catalog. No collection Added in this catalog. Add Collection and try again.',
    ID: 'Anda tidak dapat menjadwalkan katalog ini. Tidak ada koleksi yang ditambahkan dalam katalog ini. Tambahkan koleksi dan coba lagi.',
  },
  order_range_quantity: {
    EN: 'Orders Range Quantity',
    ID: 'Rentang jumlah pesanan',
  },
  orders_quantity: {
    EN: 'Orders Quantity',
    ID: 'Jumlah Pesanan',
  },
  share: {
    EN: 'Share',
    ID: 'Bagikan',
  },
  starting_from: {
    EN: 'Starting From',
    ID: 'Mulai Dari Rp',
  },
  designs: {
    EN: 'Designs',
    ID: 'Desain',
  },
  design: {
    EN: 'Design',
    ID: 'Desain',
  },
  created: {
    EN: 'Created',
    ID: 'Dibuat',
  },
  notification_time: {
    EN: 'Notification Time',
    ID: 'Waktu Notifikasi',
  },
  activation_scheduled: {
    EN: 'Activation Scheduled',
    ID: 'Aktivasi telah dijadwalkan',
  },
  notification_scheduled: {
    EN: 'Notification Scheduled',
    ID: 'Pemberitahuan telah dijadwalkan',
  },
  more: {
    EN: 'More',
    ID: 'Lebih banyak',
  },
  edit_supplier: {
    EN: 'Edit Supplier',
    ID: 'Edit Supplier',
  },
  add_new_supplier: {
    EN: 'Add New Supplier',
    ID: 'Tambah Supplier Baru',
  },
  edit_products: {
    EN: 'Edit Products',
    ID: 'Edit Produk',
  },
  stop_pre_booking: {
    EN: 'Stop Pre booking',
    ID: 'Stop pra-pemesanan',
  },
  pre_booking: {
    EN: 'Pre booking',
    ID: 'pra-pemesanan',
  },
  collections: {
    EN: 'Collections',
    ID: 'Koleksi',
  },
  hvf_admin: {
    EN: 'HVF Admin',
    ID: 'Admin HVF',
  },
  create_filter_group: {
    EN: ' Create A Filter Group',
    ID: ' Buat Grup Filter',
  },
  add_new_filter_group: {
    EN: 'Add A New Filter Group',
    ID: 'Tambahkan Grup Filter Baru',
  },
  edit_filter_group: {
    EN: 'Edit Filter Group',
    ID: 'Edit Grup Filter',
  },
  name_of_filter_group: {
    EN: 'Name of the filter group',
    ID: 'Nama grup filter',
  },
  hvf_filters: {
    EN: 'HVF Filters',
    ID: 'Filter HVF',
  },
  added_collection_tags: {
    EN: 'Added Collection Tags',
    ID: 'Menambahkan Tag Koleksi',
  },
  added_filters: {
    EN: 'Added Filters',
    ID: 'Filter Ditambahkan',
  },
  added_filter_group: {
    EN: 'Added Filter Group',
    ID: 'Menambahkan Grup Filter',
  },
  group_name: {
    EN: 'Group Name',
    ID: 'Nama Grup',
  },
  search_collection_tags: {
    EN: 'Search Collection Tags',
    ID: 'Telusuri Tag Koleksi',
  },
  search_filter_groups: {
    EN: 'Search Filter Groups',
    ID: 'Grup Filter Pencarian',
  },
  is_filter_group_valid: {
    EN: 'Is Filter Group Valid?',
    ID: 'Apakah Grup Filter Valid?',
  },
  no_filters_present: {
    EN: 'No Filters Present',
    ID: 'Tidak Ada Filter',
  },
  tips_and_guide: {
    EN: 'Tips & Guidelines',
    ID: 'Tips & Pedoman',
  },
  added_by: {
    EN: 'Added by',
    ID: 'Ditambahkan oleh',
  },
  success: {
    EN: 'Success!',
    ID: 'Keberhasilan!',
  },
  list_end: {
    EN: 'That\'s all folks!',
    ID: 'Itu semua orang!',
  },
  change_monetization_message: {
    EN: 'Are you sure want to change Default Monetization Values?',
    ID: 'Apakah Anda yakin ingin mengubah nilai standar monetisasi?',
  },
  change: {
    EN: 'Change',
    ID: 'Ubah',
  },
  save: {
    EN: 'Save',
    ID: 'Menyimpan',
  },
  cancel: {
    EN: 'Cancel',
    ID: 'Batal',
  },
  add: {
    EN: 'Add',
    ID: 'Tambah',
  },
  add_variation: {
    EN: 'Add Variation',
    ID: 'Tambah Variasi',
  },
  selected_variation_scale_message: {
    EN: 'No Variation Scale Selected. Select Variation Scale',
    ID: 'Skala Variasi Belum Dipilih. Pilih Skala Variasi',
  },
  name: {
    EN: 'Name',
    ID: 'Nama',
  },
  product_image: {
    EN: 'Product Image',
    ID: 'Gambar Produk',
  },
  discounted_price: {
    EN: 'Discounted Price',
    ID: 'Harga setelah diskon',
  },
  whole_sale_price: {
    EN: 'Wholesale Price',
    ID: 'Harga grosir',
  },
  input_supplier_price: {
    EN: 'Input Supplier Price',
    ID: 'Masukkan Harga Supplier',
  },
  actual_supplier_cost: {
    EN: 'Actual Supplier Cost',
    ID: 'Biaya Supplier Sebenarnya',
  },
  monetization_percentage: {
    EN: 'Monetization Percentage',
    ID: 'Persentase Monetisasi',
  },
  hsn_code: {
    EN: 'HSN Code',
    ID: 'Kode HSN',
  },
  shipping_time: {
    EN: 'Shipping Time',
    ID: 'Waktu Pengiriman',
  },
  tax_rate: {
    EN: 'Tax Rate',
    ID: '',
  },
  sizes: {
    EN: 'Sizes',
    ID: 'Ukuran',
  },
  deleted: {
    EN: 'Deleted',
    ID: 'Dihapus',
  },
  supplier_id: {
    EN: 'Supplier ID',
    ID: 'ID Supplier',
  },
  phone: {
    EN: 'Phone',
    ID: 'Telepon',
  },
  email: {
    EN: 'Email',
    ID: 'Email',
  },
  password: {
    EN: 'Password',
    ID: '',
  },
  supplier_replica_qc_sampling_message: {
    EN: 'This Supplier Provides replica and QC Sampling. Have you uploaded original Images?',
    ID: 'Supplier ini menyediakan replika dan uji sampel. Apakah Anda telah meng-upload gambar asli?',
  },
  supplier_replica_message: {
    EN: 'This Supplier Provides replica. Have you uploaded original Images?',
    ID: 'Supplier ini menyediakan replika. Apakah Anda telah meng-upload gambar asli?',
  },
  supplier_qc_sampling_message: {
    EN: 'This Supplier Status is QC Sampling. Have you uploaded original Images?',
    ID: 'Status Pemasok ini uji sampel. Apakah Anda telah meng-upload gambar asli?',
  },
  variation_scale_change_message: {
    EN: 'Are you sure want to change Variation Scale?',
    ID: 'Apakah Anda yakin ingin mengubah Skala Variasi?',
  },
  catalog_added_suppliers: {
    EN: 'Catalog Added Suppliers',
    ID: 'Katalog yang ditambahkan oleh Supplier',
  },
  catalog_add_new_suppliers: {
    EN: 'Catalog Add New Suppliers',
    ID: 'Tambahkan Supplier Baru di Katalog',
  },
  add_catalog: {
    EN: 'Add Catalog',
    ID: 'Tambahkan Katalog',
  },
  sort_by: {
    EN: 'Default Sort Option',
    ID: 'Opsi Pengurutan Default',
  },
  select_sort_option: {
    EN: 'Select Sort Option',
  },
  orders_low_to_high: {
    EN: 'Orders- Low to High',
    ID: 'Pesanan - Rendah ke Tinggi',
  },
  orders_high_to_low: {
    EN: 'Orders- High to Low',
    ID: 'Pesanan - Tinggi ke Rendah',
  },
  shares_high_to_low: {
    EN: 'Shares- High to Low',
    ID: 'Jumlah dibagikan - Tinggi ke Rendah',
  },
  shares_low_to_high: {
    EN: 'Shares- Low to High',
    ID: 'Dibagikan - Tinggi ke Rendah',
  },
  no_catalog_found: {
    EN: 'No Catalog found',
    ID: 'Tidak ada Katalog yang ditemukan',
  },
  catalog_cta: {
    EN: 'Catalog CTA',
    ID: 'Katalog CTA',
  },
  input_changed_save_warning: {
    EN: 'Input Fields Changed. Do you want to save changes?',
    ID: 'Kolom masukan telah berubah. Apakah Anda ingin menyimpan perubahan?',
  },
  pan: {
    EN: 'PAN',
    ID: '',
  },
  change_verification_status: {
    EN: 'Change Verification Status',
    ID: 'Change Verification Status',
  },
  catalog_info: {
    EN: 'Catalog Info',
    ID: 'Info Katalog',
  },
  upload_story_image: {
    EN: 'Upload Story Image',
    ID: 'Upload Gambar Story',
  },
  full_details: {
    EN: 'Full Details',
    ID: 'Detail lengkap',
  },
  share_text: {
    EN: 'Share Text',
    ID: 'Bagikan Teks',
  },
  catalog_type: {
    EN: 'Catalog Type',
    ID: 'Jenis Katalog',
  },
  catalog_trend: {
    EN: 'Catalog Trend',
    ID: 'Tren Katalog',
  },
  catalog_collection: {
    EN: 'Catalog Collection',
    ID: 'Koleksi Katalog',
  },
  popular_priority: {
    EN: 'Popular Priority',
    ID: 'Prioritas populer',
  },
  hot: {
    EN: 'Hot',
    ID: 'Ramai',
  },
  activate: {
    EN: 'Activate',
    ID: 'Aktifkan',
  },
  taxonomy_qc_status: {
    EN: 'Taxonomy QC Status',
    ID: 'Status Taksonomi QC',
  },
  qc_status: {
    EN: 'QC Status',
    ID: 'Status QC',
  },
  users: {
    EN: 'Users',
    ID: 'Pengguna',
  },
  file: {
    EN: 'File',
    ID: 'File',
  },
  add_new_collection: {
    EN: 'Create a Collection',
    ID: 'Membuat Koleksi',
  },
  edit_collection: {
    EN: 'Edit Collection',
    ID: 'Edit Koleksi',
  },
  name_of_collection: {
    EN: 'Name of the Collection',
    ID: 'Nama Koleksi',
  },
  overall_details: {
    EN: 'Overall Details',
    ID: '',
  },
  upload_collection_cover_image: {
    EN: 'Please Upload Collection Cover Image',
    ID: 'Silahkan Upload Gambar Cover Koleksi',
  },
  priority_is_required: {
    EN: 'Priority is required',
    ID: 'Prioritas diperlukan',
  },
  collection_info: {
    EN: 'Collection Info',
    ID: 'Info Koleksi',
  },
  upload_cover_image: {
    EN: 'Upload A Cover Image',
    ID: 'Upload Gambar Cover',
  },
  choose_filter_mapping: {
    EN: 'Choose a filter mapping method',
    ID: 'Pilih metode pemetaan filter',
  },
  choose_hvf_mapping: {
    EN: 'Choose a default HVF mapping method',
    ID: 'Pilih metode pemetaan HVF default',
  },
  collection_tags: {
    EN: 'Collection Tags',
    ID: 'Tag Koleksi',
  },
  filter_groups: {
    EN: 'Filter Groups',
    ID: 'Filter Grup',
  },
  default_approach: {
    EN: 'This is the default approach',
    ID: 'Ini adalah pendekatan default',
  },
  pre_defined_group: {
    EN: 'Select a pre-defined group',
    ID: 'Pilih grup yang ditentukan sebelumnya',
  },
  new_arrival: {
    EN: 'New Arrivals (catalog created)',
    ID: '',
  },
  most_relevant: {
    EN: 'Most Relevant (previously New Arrivals)',
    ID: '',
  },
  most_popular: {
    EN: 'Most Popular',
    ID: 'Paling populer',
  },
  new_deal: {
    EN: 'New Deal',
    ID: '',
  },
  collection_widget_groups: {
    EN: 'Collection Widget Groups',
    ID: 'Group Widget Koleksi',
  },
  no_widget_found: {
    EN: 'No Widget found',
    ID: 'Tidak ada Widget ditemukan',
  },
  add_collection: {
    EN: 'Add Collection',
    ID: 'Tambahkan Koleksi',
  },
  no_collection_found: {
    EN: 'No Collection found',
    ID: 'Tidak ada Koleksi ditemukan',
  },
  search_collection_name_id: {
    EN: 'Search Collection (name or id)',
    ID: '',
  },
  dashboard: {
    EN: 'Dashboard',
    ID: 'Dashboard',
  },
  catalogs: {
    EN: 'Catalogs',
    ID: 'Katalog',
  },
  widget_group: {
    EN: 'Widgets Group',
    ID: 'Grup Widget',
  },
  landing_pages: {
    EN: 'Landing Pages',
    ID: 'Landing Page',
  },
  orders: {
    EN: 'Orders',
    ID: 'Pesanan',
  },
  bank_account_details: {
    EN: 'Bank Account Details',
    Id: 'Detail Akun Bank',
  },
  edit_bank_account_details: {
    EN: 'Edit Bank Account details',
    ID: '',
  },
  reseller: {
    EN: 'Reseller',
    ID: 'Reseller',
  },
  supplier_payment: {
    EN: 'Supplier Payment',
    ID: 'Pembayaran Supplier',
  },
  app_banners: {
    EN: 'App Banners',
    ID: 'Banner Aplikasi',
  },
  cod_verification: {
    EN: 'COD Verification',
    ID: 'Verifikasi COD',
  },
  daily_trends: {
    EN: 'Daily Trends',
    ID: 'Tren Harian',
  },
  bulk_cancellation: {
    EN: 'Bulk Cancellation',
    ID: 'Pembatalan Massal',
  },
  tax_invoices: {
    EN: 'Tax Invoices',
    ID: 'Faktur pajak',
  },
  supply_tasks: {
    EN: 'Supply Tasks',
    ID: 'Tugas Pasokan',
  },
  review_ratings: {
    EN: 'Review Ratings',
    ID: 'Rating ulasan',
  },
  deals: {
    EN: 'Deals',
    ID: 'Penawaran',
  },
  price_scheduling: {
    EN: 'Price Scheduling',
    ID: 'Penjadwalan Harga',
  },
  tinder_ratings: {
    EN: 'Tinder Ratings',
    ID: 'Rating Tinder',
  },
  supplier_inventory_update: {
    EN: 'Supplier Inventory Update',
    ID: 'Perbarui Inventori Supplier',
  },
  files_upload: {
    EN: 'Files Upload',
    ID: 'Upload File',
  },
  visual_search: {
    EN: 'Visual Search',
    ID: 'Pencarian Visual',
  },
  update_pow_static_assets: {
    EN: 'Update POW Home Static Assets',
  },
  ab_experiment: {
    EN: 'Ab Experiment',
    ID: 'Ab Percobaan',
  },
  abacus: {
    EN: 'ABacus',
    ID: '',
  },
  gamification: {
    EN: 'Gamification',
    ID: 'Gamification',
  },
  current_month: {
    EN: 'Current Month',
    ID: 'Bulan ini',
  },
  verified_gmv: {
    EN: 'Verified GMV',
    ID: 'Nilai Bruto Terverifikasi',
  },
  verified_orders: {
    EN: 'Verified Orders',
    ID: 'Pesanan diverifikasi',
  },
  forecasted_gmv: {
    EN: 'Forecasted GMV',
    ID: 'Estimasi Nilai Bruto',
  },
  forecasted_orders: {
    EN: 'Forecasted Orders',
    ID: 'Estimasi Pesanan',
  },
  last_15_days_table_8_days_graph: {
    EN: 'Last 15 Days Table / Last 8 Days Graph',
    ID: 'Tabel 15 Hari Terakhir/Grafik 8 Hari Terakhir',
  },
  includes_cancelled_orders: {
    EN: 'This includes cancelled orders',
    ID: 'Termasuk pesanan yang dibatalkan',
  },
  advance_search_tooltip_msg: {
    EN: 'Default search searches beginning of the text. With advance search you can search between the text',
    ID: '',
  },
  excludes_cancelled_orders: {
    EN: 'This is after removing cancellations',
    ID: 'Tidak termasuk pesanan yang dibatalkan',
  },
  date: {
    EN: 'Date',
    ID: 'Tanggal',
  },
  today_net_gmv: {
    EN: 'Today Net GMV',
    ID: 'Nilai Bersih Hari ini',
  },
  today_net_orders: {
    EN: 'Today Net Orders',
    ID: 'Pesanan Bersih Hari ini',
  },
  yesterday_net_gmv: {
    EN: 'Yesterday Net GMV',
    ID: 'Nilai Bersih Kemarin',
  },
  yesterday_net_orders: {
    EN: 'Yesterday Net Orders',
    ID: 'Pesanan Bersih Kemarin',
  },
  weekly: {
    EN: 'Weekly',
    ID: 'Mingguan',
  },
  add_deal: {
    EN: 'Add Deal',
    ID: 'Tambahkan Penawaran',
  },
  back: {
    EN: 'Back',
    ID: 'Kembali ke penawaran',
  },
  deal_created_successfully: {
    EN: 'Deal Created successfully. ID:',
    ID: 'penawaran berhasil dibuat. ID:',
  },
  upload_file: {
    EN: 'Upload File',
    ID: 'Upload data',
  },
  pick_up_address: {
    EN: 'Pick Up Address',
    ID: 'Alamat Pengambilan Barang',
  },
  address_line_1: {
    EN: 'Address Line 1',
    ID: 'Alamat Baris 1',
  },
  address_line_2: {
    EN: 'Address Line 2',
    ID: 'Alamat Baris 2',
  },
  landmark: {
    EN: 'Landmark',
    ID: 'Gedung',
  },
  city: {
    EN: 'City',
    ID: 'Kota',
  },
  state: {
    EN: 'State',
    ID: 'Negara',
  },
  pin: {
    EN: 'Pin',
    ID: 'Pin',
  },
  mobile: {
    EN: 'Mobile',
    ID: 'Ponsel',
  },
  address_line_1_required: {
    EN: 'Line 1 is required',
    ID: 'Baris 1 diperlukan',
  },
  address_line_2_required: {
    EN: 'Line 2 is required',
    ID: 'Baris 2 diperlukan',
  },
  city_is_required: {
    EN: 'City is required',
    ID: 'Kota diperlukan',
  },
  state_is_required: {
    EN: 'State is required',
    ID: 'Negara diperlukan',
  },
  pin_code_not_valid: {
    EN: 'Pin is not valid',
    ID: 'Pin tidak valid',
  },
  mobile_not_valid: {
    EN: 'Mobile is not valid',
    ID: 'Ponsel tidak valid',
  },
  user_description: {
    EN: 'User Description',
    ID: 'Deskripsi pengguna',
  },
  exchange_dispatch_date: {
    EN: 'Exchange Dispatch Date',
    ID: 'Tanggal barang penukaran dikirimkan',
  },
  no_images_added: {
    EN: 'No Images Added',
    ID: 'Tidak ada gambar ditambahkan',
  },
  first_request_date: {
    EN: 'First Request Date',
    ID: 'Tanggal permintaan pertama',
  },
  product_original_image: {
    EN: 'Product Original Image',
    ID: 'Gambar produk asli',
  },
  product_original_description: {
    EN: 'Product Original Description',
    ID: 'Deskripsi produk asli',
  },
  no_description_available: {
    EN: 'No Description available',
    ID: 'Deskripsi tidak tersedia',
  },
  select_quantity: {
    EN: 'Select Quantity',
    ID: 'Pilih jumlah',
  },
  select_reason: {
    EN: 'Select Reason',
    ID: 'Pilih alasan',
  },
  select_a_value: {
    EN: 'Select a value',
    ID: '',
  },
  exchange_disabled_message: {
    EN: 'Exchange disabled as this product is out of stock',
    ID: 'Penukaran barang tidak tersedia karena produk ini sudah habis',
  },
  selected_variation_not_available: {
    EN: 'User selected variation is not available',
    ID: 'Variasi yang dipilih pengguna tidak tersedia',
  },
  status: {
    EN: 'Status',
    ID: 'Status',
  },
  exchange_dispatch_date_error: {
    EN: 'Exchange dispatch date cannot be past date.',
    ID: 'Tanggal pengiriman barang yang ditukarkan tidak bisa tanggal yang sudah lewat.',
  },
  select_exchange_dispatch_date: {
    EN: 'Select exchange dispatch date',
    ID: 'Pilih tanggal pengiriman barang yang ditukarkan',
  },
  request_id: {
    EN: 'Request ID',
    ID: 'ID Permintaan',
  },
  request_type: {
    EN: 'Request Type',
    ID: 'Jenis Permintaan',
  },
  exchange: {
    EN: 'Exchange',
    ID: 'Penukaran',
  },
  message: {
    EN: 'Message',
    ID: 'Pesan',
  },
  re_init_count: {
    EN: 'Re Init Count',
    ID: 'Hitung Ulang',
  },
  exchange_reason: {
    EN: 'Exchange Reason',
    ID: 'Alasan Penukaran',
  },
  address: {
    EN: 'Address',
    ID: 'Alamat',
  },
  return_details: {
    EN: 'Return Details',
    ID: 'Detail Kembali',
  },
  awb: {
    EN: 'AWB',
    ID: 'AWB',
  },
  tracking_url: {
    EN: 'Tracking Url',
    ID: 'URL Pelacakan',
  },
  start_date: {
    EN: 'Start Date',
    ID: 'Tanggal Mulai',
  },
  exchange_details: {
    EN: 'Exchange Details',
    ID: 'Rincian Penukaran',
  },
  exchange_label: {
    EN: 'Exchange Label',
    ID: 'Label Penukaran',
  },
  submit_comment_edit: {
    EN: 'Submit Comment Edit',
    ID: 'Kirim Ubahan Komentar',
  },
  submit_details: {
    EN: 'Submit Details',
    ID: '',
  },
  submit_dispatch_date: {
    EN: 'Submit Dispatch Date',
    ID: 'Kirim Tanggal Pengiriman',
  },
  cancel_exchange: {
    EN: 'Cancel Exchange',
    ID: 'Batalkan Penukaran',
  },
  shift_exchange_to_return: {
    EN: 'Shift Exchange to Return',
    ID: 'Ubah Penukaran Menjadi Pengembalian',
  },
  re_init_exchange_request: {
    EN: 'Re Init Request',
    ID: 'Ajukan Permintaan Penukaran Kembali',
  },
  close: {
    EN: 'Close',
    ID: 'Tutup',
  },
  cancel_request_heading: {
    EN: 'Do you want to cancel exchange request?',
    ID: 'Apakah Anda ingin membatalkan permintaan penukaran?',
  },
  exchange_init: {
    EN: 'Exchange Init',
    ID: 'Ajukan Penukaran',
  },
  return_reason_required: {
    EN: 'Return reason is required',
    ID: 'Alasan pengembalian diperlukan',
  },
  quantity_is_required: {
    EN: 'Quantity is required',
    ID: 'Jumlah diperlukan',
  },
  variation_is_required: {
    EN: 'Variation is required',
    ID: 'Variasi diperlukan',
  },
  image_is_required: {
    EN: 'At least 1 image is required',
    ID: 'Paling tidak 1 gambar diperlukan',
  },
  exchange_re_init: {
    EN: 'Exchange Re Init',
    ID: 'Ajukan kembali penukaran',
  },
  shift_exchange_to_return_message: {
    EN: 'Do you want to shift Exchange to Return?',
    ID: 'Apakah Anda ingin mengubah penukaran menjadi pengembalian?',
  },
  shift_to_return: {
    EN: 'Shift To Return',
    ID: 'Ubah jadi pengembalian',
  },
  exchange_reason_required: {
    EN: 'Exchange reason is required',
    ID: 'Alasan penukaran diperlukan',
  },
  reject: {
    EN: 'Reject',
    ID: 'Tolak',
  },
  cancel_return_request: {
    EN: 'Do you want to cancel return request?',
    ID: 'Apakah Anda ingin membatalkan permintaan pengembalian?',
  },
  service: {
    EN: 'Service',
    ID: 'Service',
  },
  service_explicit: {
    EN: 'Service Explicit',
    ID: 'Service Explicit',
  },
  service_implicit: {
    EN: 'Service Implicit',
    ID: 'Service Implicit',
  },
  serviceable: {
    EN: 'Serviceable',
    ID: 'Bisa dilayani',
  },
  cancel_return: {
    EN: 'Cancel Return',
    ID: 'Batal Kembali',
  },
  shift_return_to_exchange: {
    EN: 'Shift Return to Exchange',
    ID: 'Ubah Pengembalian ke Penukaran',
  },
  re_init_return_request: {
    EN: 'Re Init Return Request',
    ID: 'Ajukan Permintaan Pengembalian Kembali',
  },
  return_init: {
    EN: 'Return Init',
    ID: 'Mulai Permintaan Pengembalian',
  },
  return_re_init: {
    EN: 'Return Re Init',
    ID: '',
  },
  shift_return_to_exchange_message: {
    EN: 'Do you want to shift return to exchange ?',
    ID: 'Apakah Anda ingin mengubah pengembalian ke penukaran?',
  },
  select_exchange_variation: {
    EN: 'Select Exchange Variation',
    ID: 'Pilih varian yang ingin ditukar',
  },
  shift_to_exchange: {
    EN: 'Shift To Exchange',
    ID: 'Ubah ke pengembalian',
  },
  approve: {
    EN: 'Approve',
    ID: 'Setuju',
  },
  re_init_pickup_request: {
    EN: 'Re Init Pickup Request',
    ID: 'Ajukan Permintaan Pengambilan',
  },
  select_reject_reason: {
    EN: 'Select Reject Reason',
    ID: 'Pilih Alasan Penolakan',
  },
  reject_reason: {
    EN: 'Reject Reason',
    ID: 'Alasan penolakan',
  },
  reject_reason_other: {
    EN: 'Reject Reason Other',
    ID: 'Penolakan Alasan Lainnya',
  },
  order: {
    EN: 'Order',
    ID: 'Pesan',
  },
  return: {
    EN: 'Return',
    ID: 'Kembalikan',
  },
  sub_order_num: {
    EN: 'Sub Order Num',
    ID: 'Nomor Sub Order',
  },
  sub_order_number: {
    EN: 'Sub Order Number',
    ID: 'Nomor Sub-Pesanan',
  },
  customer_address: {
    EN: 'Customer Address',
    ID: 'Alamat pelanggan',
  },
  sender_info: {
    EN: 'Sender\'s Info',
    ID: 'Info pengirim',
  },
  sender_not_found: {
    EN: 'Sender\'s not found',
    ID: 'Pengirim tidak ditemukan',
  },
  view_details: {
    EN: 'View Details',
    ID: 'Lihat rincian',
  },
  book: {
    EN: 'Book',
    ID: 'Pesan',
  },
  booking_details: {
    EN: 'Booking Details',
    ID: 'Detail pemesanan',
  },
  supplier_name: {
    EN: 'Supplier Name',
    ID: 'Nama Supplier',
  },
  product_name: {
    EN: 'Product Name',
    ID: 'Nama Produk',
  },
  supplier_expected_dispatch: {
    EN: 'Supplier Expected Dispatch',
    ID: 'Perkiraan Pengiriman dari Supplier',
  },
  dispatch_days: {
    EN: 'Dispatch Days',
    ID: 'Hari Pengiriman',
  },
  exchange_dispatch_days: {
    EN: 'Expected Dispatch Date',
    ID: 'Perkiraan tanggal pengiriman',
  },
  delays: {
    EN: 'Delays',
    ID: 'Penundaan',
  },
  comment: {
    EN: 'Comment',
    ID: 'Komentar',
  },
  pick_up_confirmation_state: {
    EN: 'Pickup Confirmation State',
    ID: 'Status konfirmasi pengambilan barang',
  },
  pick_up_date_is_required: {
    EN: 'Pickup Date is required',
    ID: 'Tanggal pengambilan barang diperlukan',
  },
  payment_screen_shot: {
    EN: 'Payment Screen Shot',
    ID: 'Screenshot Pembayaran',
  },
  payment_screen_shot_not_found: {
    EN: 'Payment Screen Shoot Not Found',
    ID: 'Screenshot Pembayaran Tidak Ditemukan',
  },
  order_status_edit: {
    EN: 'Order Status Edit',
    ID: 'Edit Status Pesanan',
  },
  url_not_valid: {
    EN: 'URL is not valid',
    ID: 'URL tidak valid',
  },
  tracking_url_is_required: {
    EN: 'Tracking ID is required',
    ID: 'ID Pelacakan Diperlukan',
  },
  dispatch_date_required: {
    EN: 'Dispatch date is required',
    ID: 'Tanggal pengiriman diperlukan',
  },
  courier_name_required: {
    EN: 'Courier Name is required',
    ID: 'Nama kurir diperlukan',
  },
  other: {
    EN: 'Other',
    ID: 'Lainnya',
  },
  supplier_shipping_charges_required: {
    EN: 'Supplier Shipping Charges is required',
    ID: 'Biaya Pengiriman dari Supplier Diperlukan',
  },
  dispatch_details: {
    EN: 'Dispatch Details',
    ID: 'Rincian Pengiriman',
  },
  order_cancellation_details: {
    EN: 'Order Cancellation Details',
    ID: 'Rincian Pembatalan',
  },
  payment_date_is_required: {
    EN: 'Payment date is required',
    ID: 'Tanggal pembayaran diperlukan',
  },
  supplier_shipping_charges: {
    EN: 'Supplier Shipping Charges',
    ID: 'Biaya Pengiriman dari Supplier',
  },
  select_payment_date: {
    EN: 'Select Payment Date',
    ID: 'Pilih Tanggal Pembayaran',
  },
  select_dispatch_date: {
    EN: 'Select Dispatch Date',
    ID: 'Pilih Tanggal Pengiriman',
  },
  tracking_id: {
    EN: 'Tracking Id',
    ID: 'ID Pelacakan',
  },
  choose_courier: {
    EN: 'Choose Courier',
    ID: 'Pilih Kurir',
  },
  select_cancel_date: {
    EN: 'Select Cancel Date',
    ID: 'Pilih Tanggal Pembatalan',
  },
  shipping_details_update_error: {
    EN: 'Shipping Details update error',
    ID: 'Pembaruan rincian pengiriman salah',
  },
  ordered_shipping_details_change: {
    EN: 'Ordered Shipping Details Change',
    ID: 'Ubahan detail pengiriman pesanan',
  },
  shipping_charge_required: {
    EN: 'Shipping Charge is required',
    ID: 'Biaya Pengiriman Diperlukan',
  },
  supplier_order_value: {
    EN: 'Supplier Order Value',
    ID: 'Nilai Pesanan Supplier',
  },
  verify: {
    EN: 'Verify',
    ID: '',
  },
  verify_order: {
    EN: 'Verify Order',
    ID: 'Verifikasi Pesanan',
  },
  verify_bank_account_details: {
    EN: 'Verify Bank Account Details',
    ID: '',
  },
  customer_cod_amount: {
    EN: 'Customer COD Amount',
    ID: 'Jumlah COD Pelanggan',
  },
  customer_max_cod_amount: {
    EN: 'Customer MAX cod amount allowed is',
    ID: 'Jumlah nilai COD maksimal pelanggan yang diperbolehkan adalah',
  },
  reseller_details: {
    EN: 'Customer Details',
    ID: 'Rincian Reseller',
  },
  userId: {
    EN: 'User Id',
    ID: '',
  },
  select_expected_dispatch_date: {
    EN: 'Select Expected Dispatch Date',
    ID: 'Pilih Perkiraan Tanggal Pengiriman',
  },
  supplier_details: {
    EN: 'Supplier Details',
    ID: 'Rincian Supplier',
  },
  view: {
    EN: 'View',
    ID: 'Lihat',
  },
  customer_message_order_delivery_status: {
    EN: 'Custom Message and Order Delivery Status',
    ID: 'Pesan Custom dan Status Pengiriman Pesanan',
  },
  custom_message: {
    EN: 'Custom Message',
    ID: 'Pesan Custom',
  },
  supplier_expected_dispatch_date: {
    EN: 'Supplier Expected Dispatch Date',
    ID: 'Tanggal Perkiraan Pengiriman dari Supplier',
  },
  min_date_max_date: {
    EN: 'Min date should be less then Max date',
    ID: 'tanggal Min harus lebih awal dari tanggal Max',
  },
  order_status: {
    EN: 'Order Status',
    ID: 'Status pemesanan',
  },
  supplier_order_status: {
    EN: 'Supplier Order Status',
    ID: 'Status Pesanan Supplier',
  },
  order_request_status: {
    EN: 'Order Request Status',
    ID: 'Status Permintaan Pesanan',
  },
  order_request_type: {
    EN: 'Order Request Type',
    ID: 'Tipe Permintaan Pesanan',
  },
  exchange_supplier_status: {
    EN: 'Exchange Supplier Status',
    ID: 'Status Penukaran dari Supplier',
  },
  payment_mode: {
    EN: 'Payment Mode',
    ID: 'Metode Pembayaran',
  },
  shipment_status: {
    EN: 'Shipment Status',
    ID: 'Status pengiriman',
  },
  delayed_dispatch: {
    EN: 'Delayed Dispatch',
    ID: 'Pengiriman tertunda',
  },
  min_delays: {
    EN: 'Min Delays',
    ID: 'Minimum Penundaan',
  },
  order_date_from: {
    EN: 'Order Date From',
    ID: 'Tanggal Pesanan Dari',
  },
  order_date_to: {
    EN: 'Order Date To',
    ID: '',
  },
  booked: {
    EN: 'Booked',
    ID: 'Dipesan',
  },
  paid_to_supplier: {
    EN: 'Paid to supplier',
    ID: 'Dibayarkan ke supplier',
  },
  shipped_with_meesho: {
    EN: 'Shipped With Meesho',
    ID: 'Dikirimkan oleh Meesho',
  },
  order_number: {
    EN: 'Order Number',
    ID: 'Nomor Pesanan',
  },
  order_id: {
    EN: 'Order Id',
    ID: 'ID Pesanan',
  },
  reseller_name: {
    EN: 'Reseller Name',
    ID: 'Nama reseller',
  },
  order_total: {
    EN: 'Order Total',
    ID: 'Total pesanan',
  },
  sub_total: {
    EN: 'Sub Total',
    ID: 'Sub total',
  },
  discount: {
    EN: 'Discount',
    ID: 'Diskon',
  },
  debit: {
    EN: 'Debit',
    ID: 'Debet',
  },
  credit: {
    EN: 'Credit',
    ID: 'Kredit',
  },
  credits_deducted: {
    EN: 'Credits Deducted',
    ID: 'Kredit yang dikurangi',
  },
  effective_total: {
    EN: 'Effective Total (Order value - Meesho credits)',
    ID: 'Total (Nilai Pesanan - Meesho Credit)',
  },
  final_customer_amount: {
    EN: 'Final Customer Amount',
    ID: 'Jumlah akhir untuk pelanggan',
  },
  total: {
    EN: 'Total',
    ID: 'Total',
  },
  cod_amount: {
    EN: 'COD Amount',
    ID: 'Nilai COD',
  },
  senders_phone: {
    EN: 'Sender\'s Phone',
    ID: 'Telepon pengirim',
  },
  senders_name: {
    EN: 'Sender\'s Name',
    ID: 'Nama pengirim',
  },
  order_price_edit: {
    EN: 'Order Price Edit',
    ID: 'Ubah Harga Pesanan',
  },
  order_cod_amount: {
    EN: 'Order COD Amount',
    ID: 'Nilai COD',
  },
  new_price: {
    EN: 'New Price',
    ID: 'Harga baru',
  },
  failed_to_update_order_info: {
    EN: 'Failed to update order info please refresh the page',
    ID: 'Gagal memperbarui informasi pesanan, silakan refresh halaman',
  },
  failed_to_update_retry: {
    EN: 'Failed to update. Retry.',
    ID: '',
  },
  order_date: {
    EN: 'Order Date',
    ID: 'Tanggal pemesanan',
  },
  time: {
    EN: 'Time',
    ID: 'Waktu',
  },
  verified: {
    EN: 'Verified',
    ID: 'Terverifikasi',
  },
  sub_order_id: {
    EN: 'Sub Order Id',
    ID: 'ID Sub Order',
  },
  net_order_value: {
    EN: 'Net Order Value (Incl. Shipping) / Amount to Pay',
    ID: 'Nilai Total Pesanan (Termasuk pengiriman) / Jumlah yang harus dibayar',
  },
  payment: {
    EN: 'Payment',
    ID: 'Pembayaran',
  },
  product_variation: {
    EN: 'Product Variation',
    ID: 'Varian produk',
  },
  quantity: {
    EN: 'Quantity',
    ID: 'Jumlah',
  },
  price: {
    EN: 'Price',
    ID: 'Harga baru',
  },
  dispatch: {
    EN: 'Dispatch',
    ID: 'Pengiriman',
  },
  supplier: {
    EN: 'Supplier',
    ID: 'Supplier',
  },
  suppliers: {
    EN: 'Suppliers',
    ID: 'Supplier',
  },
  supplier_order_value_incl_shipping: {
    EN: 'Supplier Order Value (Incl. Shipping) / Amount to Pay',
    ID: 'Nilai Pesanan Supplier (Termasuk Pengiriman) / Jumlah yang harus dibayar',
  },
  supplier_expected_dispatch_pickup: {
    EN: 'Supplier Expected Dispatch/Pickup',
    ID: 'Perkiraan pengiriman/pengambilan dari supplier',
  },
  supplier_side_comment: {
    EN: 'Supplier Side Comment',
    ID: 'Komentar Supplier',
  },
  confirmed_pickup: {
    EN: 'Confirmed Pickup',
    ID: 'Pengambilan Terkonfirmasi',
  },
  pickup_date: {
    EN: 'Pickup Date',
    ID: 'Tanggal Pengambilan',
  },
  shipped_by_meesho: {
    EN: 'Shipped by Meesho',
    ID: 'Dikirim oleh Meesho',
  },
  reseller_side_comment: {
    EN: 'Reseller Side Comment',
    ID: 'Komentar Reseller',
  },
  order_cancel_details: {
    EN: 'Order Cancel Details',
    ID: 'Detail pembatalan pesanan',
  },
  awb_num: {
    EN: 'AWB Number',
    ID: 'Nomor AWB',
  },
  order_timeline: {
    EN: 'Order Timeline',
    ID: 'Timeline pesanan',
  },
  reship: {
    EN: 'Reship',
    ID: 'Kirim ulang',
  },
  return_exchange: {
    EN: 'Return / Exchange',
    ID: 'Kembalikan/Tukar',
  },
  expected_delivery_date: {
    EN: 'Expected Delivery Date',
    ID: 'Perkiraan Tanggal Pengiriman',
  },
  no_order_found: {
    EN: 'No Order Found',
    ID: 'Tidak ada pesanan ditemukan',
  },
  appy: {
    EN: 'Apply',
    ID: 'Apply',
  },
  apply: {
    EN: 'Apply',
    ID: 'Apply',
  },
  apply_filter_to_fetch_orders: {
    EN: 'Apply filter to fetch orders',
    ID: 'Terapkan filter untuk mengambil pesanan',
  },
  apply_mandatory_filters: {
    EN: 'Please select any of these filters to fetch data - Reseller Name, Search Supplier, Order Number, Reseller Code, Date Range, Customer Code or Customer number',
    ID: '',
  },
  catalog_add_product: {
    EN: 'Catalog Add Product',
    ID: 'Tambah Produk ke Katalog',
  },
  supplier_additional_info: {
    EN: 'Supplier Additional Info',
    ID: 'Info tambahan supplier',
  },
  exclusive: {
    EN: 'Exclusive',
    ID: 'Eksklusif',
  },
  order_confirmation_email_enabled: {
    EN: 'Order Confirmation Email Enabled',
    ID: 'Email konfirmasi pemesanan diaktifkan',
  },
  return_penalty_disabled: {
    EN: 'Return Penalty Disabled',
    ID: 'Penalti pengembalian dinonaktifkan',
  },
  exchange_penalty_disabled: {
    EN: 'Exchange Penalty Disabled',
    ID: 'Penalti penukaran dinonaktifkan',
  },
  trusted_enable: {
    EN: 'Trusted Enable',
    ID: 'Status Terpercaya Aktif',
  },
  supplier_address_details: {
    EN: 'Supplier Address Details',
    ID: 'Alamat detail supplier',
  },
  pickup_address: {
    EN: 'Pickup address',
    ID: 'Alamat pengambilan',
  },
  country: {
    EN: 'Country',
    ID: 'Negara',
  },
  same_pickup_address: {
    EN: 'Is same as pickup address?',
    ID: 'Apakah sama dengan alamat pengambilan?',
  },
  registered_address: {
    EN: 'Registered Address',
    ID: 'Alamat yang sudah terdaftar',
  },
  registered_country: {
    EN: 'Registered Country',
    ID: 'Negara yang sudah terdaftar',
  },
  supplier_bank_details: {
    EN: 'Supplier Bank Details',
    ID: 'Rincian Bank Supplier',
  },
  account_holder: {
    EN: 'Account Holder',
    ID: '',
  },
  account_holder_name: {
    EN: 'Account Holder Name',
    ID: 'Nama pemilik akun',
  },
  enter_account_name: {
    EN: 'Enter Account Name',
    ID: 'Masukkan nama akun',
  },
  ifsc_code: {
    EN: 'IFSC Code',
    ID: 'Kode IFSC',
  },
  enter_ifsc_code: {
    EN: 'Enter IFSC Code',
    ID: 'Masukkan kode IFSC',
  },
  account_number: {
    EN: 'Account Number',
    ID: 'Nomor akun',
  },
  enter_account_no: {
    EN: 'Enter Account No',
    ID: 'Tekan nomor akun',
  },
  bank_name: {
    EN: 'Bank Name',
    ID: 'Nama bank',
  },
  cancelled_cheque: {
    EN: 'Cancelled Cheque',
    ID: 'Cek dibatalkan',
  },
  brand_name: {
    EN: 'Brand Name',
    ID: 'Nama merk',
  },
  registered_name: {
    EN: 'Registered Name',
    ID: 'Nama yang sudah didaftarkan',
  },
  primary_number: {
    EN: 'Primary Number',
    ID: 'Nomor utama',
  },
  alternate_number: {
    EN: 'Alternate number',
    ID: 'Nomor alternatif',
  },
  poc_name: {
    EN: 'POC Name',
    ID: 'Nama penanggung jawab',
  },
  supplier_quality_check_state: {
    EN: 'Supplier Quality Check State',
    ID: 'Status pemeriksaan kualitas supplier',
  },
  supplier_business_details: {
    EN: 'Supplier Business Details',
    ID: 'Detail bisnis supplier',
  },
  replica_supplies: {
    EN: 'Replica supplies',
    ID: 'Persediaan replika',
  },
  sells_to_reseller: {
    EN: 'Sells to Reseller',
    ID: 'Menjual ke reseller',
  },
  supplier_type: {
    EN: 'Supplier Type',
    ID: 'Tipe supplier',
  },
  select_start_date: {
    EN: 'Select Start Date',
    ID: 'Pilih Tanggal Awal',
  },
  select_end_date: {
    EN: 'Select End Date',
    ID: 'Pilih Tanggal Akhir',
  },
  select_supplier_type: {
    EN: 'Select Supplier Type',
    ID: 'Pilih Tipe supplier',
  },
  website_link: {
    EN: 'Website Link',
    ID: 'Link website',
  },
  facebook_link: {
    EN: 'Facebook Link',
    ID: 'Link facebook',
  },
  primary_category: {
    EN: 'Primary Category',
    ID: 'Kategori utama',
  },
  additional_comments: {
    EN: 'Additional Comments',
    ID: 'Komen tambahan',
  },
  supplier_delete_details: {
    EN: 'Supplier Delete Details',
    ID: 'Hapus detail supplier',
  },
  reason_for_last_deactivation: {
    EN: 'Reason For Last Deactivation',
    ID: 'Alasan penonaktifan terakhir',
  },
  deactivated_by: {
    EN: 'Deactivated By',
    ID: 'Dinonaktifkan oleh',
  },
  last_updated: {
    EN: 'Last Updated',
    ID: '',
  },
  last_deactivation_date: {
    EN: 'Last Deactivation Date',
    ID: 'Tanggal penonaktifan terakhir',
  },
  supplier_executives_details: {
    EN: 'Supplier Executives Details',
    ID: 'Detail supplier eksekutif',
  },
  account_manager: {
    EN: 'Account Manager',
    ID: 'Account Manager',
  },
  select_account_manager: {
    EN: 'Select Account Manager',
    ID: 'Pilih Account Manager',
  },
  bd_executive: {
    EN: 'BD Executive',
    ID: 'BD executive',
  },
  select_bd_executive: {
    EN: 'Select BD Executive',
    ID: 'Pilih BD executive',
  },
  ops_executive: {
    EN: 'OPS Executive',
    ID: 'OPS executive',
  },
  select_ops_executive: {
    EN: 'Select OPS Executive',
    ID: 'Pilih OPS executive',
  },
  supplier_kyc_details: {
    EN: 'Supplier KYC Details',
    ID: 'Detail Supplier KYC',
  },
  supplier_npwp_no: {
    EN: 'Supplier NPWP No',
    ID: 'Nomor NPWP supplier',
  },
  npwp: {
    EN: 'NPWP',
    ID: 'NPWP',
  },
  npwp_certificate: {
    EN: 'NPWP Certificate',
    ID: 'Sertifikat NPWP',
  },
  supplier_ktp: {
    EN: 'Supplier KTP',
    ID: 'KTP supplier',
  },
  ktp_card: {
    EN: 'KTP Card',
    ID: 'KTP',
  },
  select_verification_status: {
    EN: 'Select verification status',
    ID: 'Pilih status verifikasi',
  },
  supplier_payment_plan_details: {
    EN: 'Supplier Payment Plan Details',
    ID: 'Detail rencana pembayaran supplier',
  },
  monetization_type: {
    EN: 'Monetization Type',
    ID: 'Tipe monetisasi',
  },
  select_monetization_type: {
    EN: 'Select Monetization Type',
    ID: 'Pilih tipe monetisasi',
  },
  commission: {
    EN: 'Commission',
    ID: 'Komisi',
  },
  markup: {
    EN: 'Markup',
    ID: 'Menaikan',
  },
  default_monetization_percent: {
    EN: 'Default Monetization Percent',
    ID: 'Persentase Monetisasi Standar',
  },
  payment_type: {
    EN: 'Payment Type',
    ID: 'Tipe pembayaran',
  },
  select_payment_type: {
    EN: 'Select Payment Type',
    ID: 'Pilih tipe pembayaran',
  },
  outside_system: {
    EN: 'Outside System',
    ID: 'Sistem luar',
  },
  dispatch_plus_x_days: {
    EN: 'Dispatch + X',
    ID: 'Pengiriman + X',
  },
  credit_cycle: {
    EN: 'Credit Cycle',
    ID: 'Siklus kredit',
  },
  delivery_plus_x_days: {
    EN: 'Delivery + X',
    ID: 'Pengiriman + X',
  },
  rolling_credit_period: {
    EN: 'Rolling Credit Period',
    ID: 'Periode kredit berjalan',
  },
  credit_period: {
    EN: 'Credit Period',
    ID: 'Periode kredit',
  },
  in_days: {
    EN: 'In Days',
    ID: 'Dalam hari',
  },
  payment_expiry: {
    EN: 'Payment Expiry',
    ID: 'Tenggat waktu pembayaran',
  },
  select_expiry: {
    EN: 'Select Expiry',
    ID: 'Pilih tenggat waktu',
  },
  never: {
    EN: 'Never',
    ID: 'Tidak pernah',
  },
  expires_in: {
    EN: 'Expires In',
    ID: 'Tenggat waktu',
  },
  validity: {
    EN: 'Validity',
    ID: 'Validitas',
  },
  next_payment_plan: {
    EN: 'Next Payment Plan',
    ID: 'Rencana pembayaran selanjutnya',
  },
  supplier_shipment_details: {
    EN: 'Supplier Shipment Details',
    ID: 'Detail pengiriman supplier',
  },
  meesho_fulfilled: {
    EN: 'Meesho Fulfilled',
    ID: 'Dipenuhi oleh Meesho',
  },
  weight_bracket_in_grams: {
    EN: 'Weight Bracket in grams',
    ID: 'Satuan berat dalam gram',
  },
  shipping_price_bracket: {
    EN: 'Shipping Price/Bracket',
    ID: 'Harga pengiriman',
  },
  cod_option: {
    EN: 'COD Option',
    ID: 'Opsi COD',
  },
  cod_charges: {
    EN: 'COD Charges',
    ID: 'Biaya COD',
  },
  ops_status: {
    EN: 'Ops Status',
    ID: 'Status ops',
  },
  returns: {
    EN: 'Returns',
    ID: 'Kembali',
  },
  sla_min: {
    EN: 'SLA Min',
    ID: 'Minimal SLA',
  },
  sla_max: {
    EN: 'SLA Max',
    ID: 'Maksimal SLA',
  },
  exchange_enable: {
    EN: 'Exchange Enable',
    ID: 'Bisa ditukar',
  },
  exchange_unavailable_message: {
    EN: 'Exchange Unavailable Message',
    ID: 'Pesan penukaran tidak tersedia',
  },
  select_exchange_unavailable_message: {
    EN: 'Select Exchange Unavailable Message',
    ID: 'Pilih pesan penukaran tidak tersedia',
  },
  deactivation_reason_is_required: {
    EN: 'Deactivation Reason is required',
    ID: 'Alasan penonaktifan diperlukan',
  },
  supplier_delete_message: {
    EN: 'Are you sure want to delete this Supplier?',
    ID: 'Apakah anda yakin ingin menghapus supplier?',
  },
  supplier_activate_message: {
    EN: 'Are you sure want to activate this Supplier?',
    ID: 'Apakah anda yakin ingin mengaktifkan supplier?',
  },
  reason_for_deactivation: {
    EN: 'Reason For Deactivation',
    ID: 'Alasan dinonaktifkan',
  },
  permanent_delete: {
    EN: 'Permanent Delete',
    ID: 'Hapus permanen',
  },
  temporary_delete: {
    EN: 'Temporary Delete',
    ID: 'Hapus sementara',
  },
  active_restore_products: {
    EN: 'Active & Restore Products',
    ID: 'Aktif & Produk dikembalikan',
  },
  restore_products_message: {
    EN: 'Do you really want to restore the products?',
    ID: 'Apakah anda yakin ingin mengembalikan produk?',
  },
  yes_restore_products: {
    EN: 'Yes Restore Products',
    ID: 'Ya, Produk dikembalikan',
  },
  panel_link: {
    EN: 'Panel Link',
    ID: 'Link panel',
  },
  supplier_add_error: {
    EN: 'Supplier add error. try again',
    ID: 'Tambah supplier error. Coba lagi.',
  },
  supplier_update_error: {
    EN: 'Supplier update error. try again',
    ID: 'Perbarui supplier error. Coba lagi.',
  },
  upload: {
    EN: 'Upload',
    ID: 'Upload',
  },
  select_verification: {
    EN: 'Select verification',
    ID: 'Pilih verifikasi',
  },
  supplier_basic_info: {
    EN: 'Supplier Basic Info',
    ID: 'Informasi Dasar Supplier',
  },
  select_banner_screen: {
    EN: 'Select Banner Screen',
    ID: '',
  },
  banner_title: {
    EN: 'Title is required',
    ID: 'Judul harus diisi',
  },
  banner_sub_title: {
    EN: 'Sub title is required',
    ID: 'Sub judul wajib diisi',
  },
  show_time: {
    EN: 'Show Time',
    ID: '',
  },
  old_audience_is_required: {
    EN: 'Old Audience is required',
    ID: 'Audiens lama diperlukan',
  },
  new_audience_is_required: {
    EN: 'New Audience is required',
    ID: 'Audiens baru diperlukan',
  },
  audience_is_required: {
    EN: 'Audience is required',
    ID: 'Audiens diperlukan',
  },
  banner_screen_is_required: {
    EN: 'Banner Screen is required',
    ID: 'Tampilan Banner diperlukan',
  },
  catalog_id_is_required: {
    EN: 'Catalog Id is required',
    ID: 'ID Katalog diperlukan',
  },
  catalog_name_is_required: {
    EN: 'Catalog Name is required',
    ID: 'Nama Katalog diperlukan',
  },
  collection_id_is_required: {
    EN: 'Collection Id is required',
    ID: 'Id Koleksi diperlukan',
  },
  collection_name_is_required: {
    EN: 'Collection Name is required',
    ID: 'Nama Koleksi diperlukan',
  },
  web_view_title_is_required: {
    EN: 'Web View Title is required',
    ID: 'Judul tampilan web diperlukan',
  },
  web_view_url_is_required: {
    EN: 'Web View URL is required',
    ID: 'URL tampilan web diperlukan',
  },
  web_view_url_is_not_valid: {
    EN: 'Web View URL is not valid',
    ID: 'URL tampilan web tidak valid',
  },
  browser_url_is_required: {
    EN: 'Browser URL is required',
    ID: 'URL Browser diperlukan',
  },
  browser_url_is_not_valid: {
    EN: 'Browser URL is not valid',
    ID: 'URL Browser tidak valid',
  },
  community_url_is_required: {
    EN: 'Community URL is required',
    ID: 'URL komunitas diperlukan',
  },
  community_url_is_not_valid: {
    EN: 'Community URL is not valid',
    ID: 'URL komunitas tidak valid',
  },
  target_url_is_required: {
    EN: 'Target URL is required',
    ID: 'URL Target diperlukan',
  },
  target_url_is_not_valid: {
    EN: 'Target URL is not valid',
    ID: 'URL Target tidak valid',
  },
  priority_is_not_valid_0: {
    EN: 'Priority is not valid < 0',
    ID: 'Prioritas tidak valid < 0',
  },
  start_time_is_required: {
    EN: 'Start time is required',
    ID: 'Waktu mulai diperlukan',
  },
  start_time_is_not_valid: {
    EN: 'Start time is not valid',
    ID: 'Waktu mulai tidak valid',
  },
  end_time_is_required: {
    EN: 'End time is required',
    ID: 'Waktu berakhir diperlukan',
  },
  end_time_is_not_valid: {
    EN: 'End time is not valid',
    ID: 'Waktu berakhir tidak valid',
  },
  min_version_is_required: {
    EN: 'Min version is required',
    ID: 'Versi Minimum diperlukan',
  },
  min_version_is_not_valid: {
    EN: 'Min version is not valid',
    ID: 'Versi Minimum tidak valid',
  },
  max_version_is_required: {
    EN: 'Max version is required',
    ID: 'Versi Maksimum diperlukan',
  },
  max_version_is_not_valid: {
    EN: 'Max version is not valid',
    ID: 'Versi Maksimum tidak valid',
  },
  min_ios_version_is_required: {
    EN: 'Min ios version is required',
    ID: 'Versi Minimum ios diperlukan',
  },
  min_ios_version_is_not_valid: {
    EN: 'Min ios version is not valid',
    ID: 'Versi Minimum ios tidak valid',
  },
  max_ios_version_is_required: {
    EN: 'Max ios version is required',
    ID: 'Versi Maksimum ios diperlukan',
  },
  max_ios_version_is_not_valid: {
    EN: 'Max ios version is not valid',
    ID: 'Versi Maksimum ios tidak valid',
  },
  discount_value_is_required: {
    EN: 'Discount Value is required',
    ID: 'Nilai Diskon diperlukan',
  },
  discount_value_is_not_valid: {
    EN: 'Discount Value is not valid',
    ID: 'Nilai Diskon tidak valid',
  },
  max_apply_count_is_required: {
    EN: 'Max Apply Count is required',
    ID: 'Jumlah maksimal penerapan diperlukan',
  },
  expiry_days_is_required: {
    EN: 'Expiry Days is required',
    ID: 'Hari kadaluwarsa diperlukan',
  },
  extended_expiry_days_is_required: {
    EN: 'Extended Expiry Days is required',
    ID: 'Hari kadaluwarsa diperpanjang diperlukan',
  },
  please_upload_banner_image: {
    EN: 'Please Upload Banner Image',
    ID: 'Silahkan Upload Gambar Banner',
  },
  banner_images_is_required: {
    EN: 'Banner Images is required',
    ID: 'Gambar banner diperlukan',
  },
  banner_images_not_valid: {
    EN: 'Banner Images not valid',
    ID: 'Gambar banner tidak valid',
  },
  notification_details_is_required: {
    EN: 'Notification Details is required',
    ID: 'Rincian notifikasi diperlukan',
  },
  notification_details_not_valid: {
    EN: 'Notification Details not valid',
    ID: 'Rincian notifikasi tidak valid',
  },
  schedule_notification: {
    EN: 'Schedule Notification',
    ID: '',
  },
  schedule_notification_required: {
    EN: 'Schedule Notification is required',
    ID: 'Jadwal Pemberitahuan diperlukan',
  },
  select_template_required: {
    EN: 'Select Template Required',
    ID: 'Template Terpilih Diperlukan',
  },
  sms_sender_id_required: {
    EN: 'SMS Sender Id Required',
    ID: 'ID Pengirim SMS Diperlukan',
  },
  schedule_sms_required: {
    EN: 'Schedule SMS Required',
    ID: 'Jadwal SMS Diperlukan',
  },
  select_type_required: {
    EN: 'Select Type Required',
    ID: 'Tipe Terpilih Diperlukan',
  },
  campaign_name_required: {
    EN: 'Campaign name Required',
    ID: 'Nama program diperlukan',
  },
  campaign_name_already_exist: {
    EN: 'Campaign already exist, Please change campaign name.',
    ID: 'Nama program diperlukan',
  },
  upload_file_required: {
    EN: 'Upload File Required',
    ID: 'Upload File Diperlukan',
  },
  time_required: {
    EN: 'Time Required',
    ID: 'Waktu dibutuhkan',
  },
  date_required: {
    EN: 'Date Required',
    ID: 'Tanggal dibutuhkan',
  },
  please_check_inputs: {
    EN: 'Please check inputs!',
    ID: 'Silakan cek input!',
  },
  full_details_is_required: {
    EN: 'Full Details is required',
    ID: 'Detial lengkap diperlukan',
  },
  catalog_type_is_required: {
    EN: 'Catalog Type is required',
    ID: 'Tipe katalog diperlukan',
  },
  category_is_required: {
    EN: 'Category is required',
    ID: 'Kategori diperlukan',
  },
  at_least_1_collection_is_required: {
    EN: 'At least 1 collection is required',
    ID: 'Paling tidak 1 koleksi diperlukan',
  },
  popular_priority_is_required: {
    EN: 'Popular Priority is required',
    ID: 'Prioritas populer diperlukan',
  },
  title_is_required: {
    EN: 'Title is required',
    ID: 'Judul diperlukan',
  },
  location_screen_is_required: {
    EN: 'Location Screen is required',
    ID: 'Tampilan Lokasi diperlukan',
  },
  landing_page_id_is_required: {
    EN: 'Landing Page Id is required',
    ID: 'ID Landing Page diperlukan',
  },
  landing_page_title_is_required: {
    EN: 'Landing Page Title is required',
    ID: 'Judul Landing Page diperlukan',
  },
  widget_group_id_is_required: {
    EN: 'Widget Group Id is required',
    ID: 'ID Grup Widget diperlukan',
  },
  widget_group_id_is_not_valid: {
    EN: 'Widget Group Id is not valid',
    ID: 'ID Grup Widget tidak valid',
  },
  position_is_required: {
    EN: 'Position is required',
    ID: 'Posisi diperlukan',
  },
  position_is_not_valid: {
    EN: 'Position is not valid',
    ID: 'Posisi tidak valid',
  },
  type_is_required: {
    EN: 'Type is required',
    ID: 'Tipe diperlukan',
  },
  audience_id_is_required: {
    EN: 'Audience Id is required',
    ID: 'ID audiens diperlukan',
  },
  configuration_is_required: {
    EN: 'Configuration is required',
    ID: 'Konfigurasi diperlukan',
  },
  configuration_json_is_not_required: {
    EN: 'Configuration JSON is not required',
    ID: 'Konfigurasi JSON tidak diperlukan',
  },
  missing_mandatory_fields_try_again_with_valid_inputs: {
    EN: 'Missing mandatory fields. Try Again with valid inputs.',
    ID: 'Bagian yang wajib belum terisi. Coba Lagi dengan input yang valid.',
  },
  please_fill_all_widget_tiles: {
    EN: 'Please fill all widget tiles',
    ID: 'Silahkan isi semua ubin widget',
  },
  please_fill_at_least_1_widget_tile: {
    EN: 'Please fill at least 1 widget tile',
    ID: 'Silahkan isi minimal 1 ubin widget',
  },
  please_fill_widget_in_4_s_multiply: {
    EN: 'Please fill widget in 4\'s multiply',
    ID: 'Silahkan isi widget dalam kelipatan 4',
  },
  please_fill_widget_in_3_s_multiply: {
    EN: 'Please fill widget in 3\'s multiply',
    ID: 'Silahkan isi widget dalam kelipatan 3',
  },
  please_fill_widget_in_2_s_multiply: {
    EN: 'Please fill widget in 2\'s multiply',
    ID: 'Silahkan isi widget dalam kelipatan 2',
  },
  invalid_image_width: {
    EN: 'Invalid image width',
    ID: 'Lebar gambar tidak valid',
  },
  min_version_can_t_less_than: {
    EN: 'Min version can\'t less than',
    ID: 'Versi minimum tidak bisa kurang dari',
  },
  max_version_can_t_greater_than: {
    EN: 'Max version can\'t greater than',
    ID: 'Versi maksimal tidak bisa lebih dari',
  },
  min_ios_version_can_t_less_than: {
    EN: 'Min ios version can\'t less than',
    ID: 'Versi minimum ios tidak bisa kurang dari',
  },
  max_ios_version_can_t_greater_than: {
    EN: 'Max ios version can\'t greater than',
    ID: 'Versi Max ios tidak boleh lebih dari',
  },
  priority_is_not_valid: {
    EN: 'Priority is not valid',
    ID: 'Prioritas tidak valid',
  },
  destination_screen_is_required: {
    EN: 'Destination Screen is required',
    ID: 'Tampilan Tujuan diperlukan',
  },
  image_width_screen_is_not_valid: {
    EN: 'Image Width Screen is not valid',
    ID: 'Tampilan Lebar Gambar tidak valid',
  },
  sub_title_is_required: {
    EN: 'Sub Title is required',
    ID: 'Sub Judul diperlukan',
  },
  landing_page_is_not_valid: {
    EN: 'Landing Page is not valid',
    ID: 'Landing Page tidak valid',
  },
  phone_number_is_not_valid: {
    EN: 'Phone number is not valid',
    ID: '',
  },
  email_is_not_valid: {
    EN: 'Email is not valid',
    ID: '',
  },
  meesho_fulfilled_is_required: {
    EN: 'Meesho Fulfilled is required',
    ID: '',
  },
  weight_bracket_is_required: {
    EN: 'Weight Bracket is required',
    ID: '',
  },
  shipping_price_is_required: {
    EN: 'Shipping Price is required',
    ID: '',
  },
  margin_free_period_should_be_zero_in_markup: {
    EN: 'Margin free period should be Zero in markup',
    ID: '',
  },
  address_is_required: {
    EN: 'Address is required',
    ID: '',
  },
  pin_code_is_required: {
    EN: 'Pin code is required',
    ID: '',
  },
  select_payment_plan: {
    EN: 'Select Payment Plan',
    ID: '',
  },
  invalid_account_holder_name: {
    EN: 'Invalid Account Holder Name',
    ID: '',
  },
  no_special_characters_allowed: {
    EN: 'No Special Characters Allowed',
    ID: '',
  },
  max_account_holder_name_length_is_100: {
    EN: 'Max Account Holder Name length is 100',
    ID: '',
  },
  invalid_ifsc_code: {
    EN: 'Invalid IFSC Code',
    ID: '',
  },
  invalid_account_number: {
    EN: 'Invalid Account Number',
    ID: '',
  },
  select_supplies_replica: {
    EN: 'Select Supplies Replica',
    ID: '',
  },
  supplier_type_is_required: {
    EN: 'Supplier Type is required',
    ID: '',
  },
  poc_name_is_required: {
    EN: 'POC Name is required',
    ID: '',
  },
  ktp_number_is_required: {
    EN: 'KTP number is required',
    ID: '',
  },
  bank_name_is_required: {
    EN: 'Bank Name is required',
    ID: '',
  },
  primary_category_is_required: {
    EN: 'Primary Category is required',
    ID: '',
  },
  ior_is_required: {
    EN: 'IOR is required',
    ID: '',
  },
  eor_is_required: {
    EN: 'EOR is required',
    ID: '',
  },
  manifestation_stage_is_required: {
    EN: 'Manifestation Stage is required',
    ID: '',
  },
  return_phone_number_is_not_valid: {
    EN: 'Return phone number is not valid',
    ID: '',
  },
  sla_min_is_required: {
    EN: 'SLA Min is required',
    ID: '',
  },
  sla_max_is_required: {
    EN: 'SLA Max is required',
    ID: '',
  },
  sla_max_is_not_valid: {
    EN: 'SLA Max is not valid',
    ID: '',
  },
  exchange_unavailable_message_required_in_case_of_exchange_disable: {
    EN: 'Exchange Unavailable Message required in case of Exchange Disable',
    ID: '',
  },
  ktp_card_image_is_required: {
    EN: 'KTP card image is required',
    ID: '',
  },
  gst_certificate_is_required: {
    EN: 'Gst Certificate is required',
    ID: '',
  },
  cancelled_cheque_is_required: {
    EN: 'Cancelled Cheque is required',
    ID: '',
  },
  gold_dispatch_delta_is_required: {
    EN: 'Gold Dispatch Delta is required',
    ID: '',
  },
  gold_dispatch_delta_is_not_valid: {
    EN: 'Gold Dispatch Delta is not valid',
    ID: '',
  },
  unexpected_error_retry_again: {
    EN: 'Unexpected Error. retry again',
    ID: '',
  },
  user_status: {
    EN: 'User Status',
    ID: '',
  },
  user_sync_success: {
    EN: 'User sync success',
    ID: '',
  },
  user_update_successfully: {
    EN: 'User Update Successfully',
    ID: '',
  },
  user_admin_access: {
    EN: 'User Admin Access',
    ID: '',
  },
  sync_users: {
    EN: 'Sync Users',
    ID: '',
  },
  date_updated: {
    EN: 'Date Updated',
    ID: '',
  },
  user_access_management: {
    EN: 'User Access Management',
    ID: '',
  },
  user_team_grouping: {
    EN: 'User Team Grouping',
    ID: '',
  },
  user_account: {
    EN: 'User Account',
    ID: 'Akun Pengguna',
  },
  team_actions_grouping: {
    EN: 'Team Actions Grouping',
    ID: '',
  },
  select_team: {
    EN: 'Select Team',
    ID: '',
  },
  team: {
    EN: 'Team',
    ID: '',
  },
  select_user: {
    EN: 'Select User',
    ID: '',
  },
  all_roles: {
    EN: 'All Roles',
    ID: '',
  },
  team_access_updated_successfully: {
    EN: 'Team Access Updated Successfully',
    ID: '',
  },
  updated_successfully: {
    EN: 'Updated Successfully',
    ID: '',
  },
  search_supplier: {
    EN: 'Search Supplier',
    ID: '',
  },
  no_supplier_found: {
    EN: 'No supplier found',
    ID: '',
  },
  sql_query_is_required: {
    EN: 'SQL Query is required',
    ID: '',
  },
  download_response_file: {
    EN: 'Download Response File',
    ID: '',
  },
  response: {
    EN: 'Response',
    ID: '',
  },
  cancel_reason: {
    EN: 'Cancel Reason',
    ID: '',
  },
  this_catalog_created_more_than_3_days_ago_have_you_confirmed_stock_availability:
    {
      EN: 'This Catalog Created more than 3 days ago. Have you confirmed stock availability?',
      ID: '',
    },
  updating_details: {
    EN: 'Updating details...',
    ID: '',
  },
  updating_failed_retry: {
    EN: 'Updating Failed. Retry',
    ID: '',
  },
  min_price_should_be_less_then_max_price: {
    EN: 'Min price should be less then Max price',
    ID: '',
  },
  search_collection: {
    EN: 'Search Collection',
    ID: '',
  },
  dispatched_on: {
    EN: 'Dispatched On',
    ID: '',
  },
  dispatched_in: {
    EN: 'Dispatched in',
    ID: '',
  },
  days: {
    EN: 'Days',
    ID: '',
  },
  enter_supplier_cost: {
    EN: 'Enter Supplier Cost',
    ID: '',
  },
  enter_input_price: {
    EN: 'Enter Input Price',
    ID: '',
  },
  enter_tax_rate: {
    EN: 'Enter Tax Rate',
    ID: '',
  },
  enter_monetization_percentage: {
    EN: 'Enter Monetization Percentage',
    ID: '',
  },
  wholesale_price_should_be_greater_than_discounted_price: {
    EN: 'Wholesale Price should be greater than Discounted Price',
    ID: '',
  },
  mrp_should_be_greater_than_discounted_price: {
    EN: 'MRP should be greater than Discounted Price',
    ID: '',
  },
  enter_shipping_time: {
    EN: 'Enter Shipping Time',
    ID: '',
  },
  supplier_price_or_tax_rate_or_wholesale_price_changed_please_recompute_price_and_submit:
    {
      EN: 'Supplier Price or Tax Rate or Wholesale Price changed. Please recompute price and Submit.',
      ID: '',
    },
  monetization_values_changed_please_recompute_price_and_submit: {
    EN: 'Monetization values changed. Please recompute price and Submit.',
    ID: '',
  },
  invalid_inputs_price_supplier_cost_supplier_cost_shipping_time_and_1_size_is_mandatory:
    {
      EN: 'Invalid Inputs. Price',
      ID: '',
    },
  select_tax_type: {
    EN: 'Select Tax Type',
    ID: '',
  },
  input_fields_missing_or_wrong_inputs_check_input_fields: {
    EN: 'Input fields missing or wrong inputs. check input fields',
    ID: '',
  },
  product_s_sku_found_duplicates: {
    EN: 'Product\'s SKU found duplicates',
    ID: '',
  },
  product_s_sku_doesn_t_match: {
    EN: 'Product\'s SKU doesn\'t match',
    ID: '',
  },
  valid_products_doesn_t_match: {
    EN: 'Valid Products doesn\'t match',
    ID: '',
  },
  no_product_added: {
    EN: 'No product added',
    ID: '',
  },
  add_products_to_supplier: {
    EN: 'Add Products to Supplier',
    ID: '',
  },
  supplier_status: {
    EN: 'Supplier Status',
    ID: '',
  },
  uploading_real_image_is_mandatory_for_this_catalog: {
    EN: 'Uploading real image is mandatory for this catalog.',
    ID: '',
  },
  inc_excl_gst: {
    EN: 'Inc/Excl GST',
    ID: '',
  },
  price_includes_gst: {
    EN: 'Price Includes GST',
    ID: '',
  },
  price_excludes_gst: {
    EN: 'Price Excludes GST',
    ID: '',
  },
  compute_price: {
    EN: 'Compute Price',
    ID: '',
  },
  variation_scale: {
    EN: 'Variation Scale',
    ID: '',
  },
  select_variation_scale: {
    EN: 'Select Variation Scale',
    ID: '',
  },
  add_new_catalog: {
    EN: 'Add New Catalog',
    ID: '',
  },
  edit_catalog: {
    EN: 'Edit Catalog',
    ID: '',
  },
  input_fields_changed_do_you_want_to_save_changes: {
    EN: 'Input Fields Changed. Do you want to save changes?',
    ID: '',
  },
  no_collection_selected: {
    EN: 'No Collection Selected',
    ID: '',
  },
  files: {
    EN: 'Files',
    ID: '',
  },
  catalog_ratings_rewards_file_upload: {
    EN: 'Catalog Ratings Rewards File Upload',
    ID: '',
  },
  uploaded_files: {
    EN: 'Uploaded Files',
    ID: '',
  },
  uploaded_file_response: {
    EN: 'Uploaded File Response',
    ID: '',
  },
  uploaded_file_view: {
    EN: 'Uploaded File View',
    ID: '',
  },
  users_data: {
    EN: 'Users Data',
    ID: '',
  },
  order_num: {
    EN: 'Order Num',
    ID: '',
  },
  request: {
    EN: 'Request',
    ID: '',
  },
  show_in_collection_tab: {
    EN: 'Show In Collection Tab',
    ID: '',
  },
  order_count: {
    EN: 'Order Count',
    ID: '',
  },
  logout: {
    EN: 'Logout',
    ID: '',
  },
  widgets_group: {
    EN: 'Widgets Group',
    ID: '',
  },
  firebase_admin: {
    EN: 'Firebase Admin',
    ID: '',
  },
  admin_access: {
    EN: 'Admin Access',
    ID: '',
  },
  file_upload: {
    EN: 'File Upload',
    ID: 'Upload File',
  },
  select_audience_input: {
    EN: 'Select Audience Input',
    ID: '',
  },
  audience_id: {
    EN: 'Audience Id',
    ID: '',
  },
  user_audience_ids: {
    EN: 'User Audience Ids',
    ID: '',
  },
  anonymous_audience_ids: {
    EN: 'Anonymous Audience Ids',
    ID: '',
  },
  select_audience_input_required: {
    EN: 'Select Audience Input Required',
    ID: '',
  },
  audience_Id_required: {
    EN: 'Audience Id Required',
    ID: '',
  },
  whatsapp_notification: {
    EN: 'Whatsapp Notification',
    ID: '',
  },
  whatsapp_notifications_portal: {
    EN: 'WhatsApp Notifications Portal',
    ID: '',
  },
  widget_group_template_id_is_required: {
    EN: 'Widget Group Template Id is required',
    ID: '',
  },
  please_upload_widget_image: {
    EN: 'Please Upload Widget Image',
    ID: '',
  },
  npwp_is_required: {
    EN: 'NPWP is required',
    ID: '',
  },
  uploaded_file_orders: {
    EN: 'Uploaded File Orders',
    ID: '',
  },
  reason_is_required: {
    EN: 'Reason is required',
    ID: '',
  },
  return_reason: {
    EN: 'Return Reason',
    ID: '',
  },
  price_is_required: {
    EN: 'Price is required',
    ID: '',
  },
  booking_date_is_required: {
    EN: 'Booking Date is required',
    ID: '',
  },
  booking_date: {
    EN: 'Booking Date',
    ID: '',
  },
  product_price: {
    EN: 'Product Price',
    ID: '',
  },
  courier_name: {
    EN: 'Courier Name',
    ID: '',
  },
  search_reseller: {
    EN: 'Search Reseller',
    ID: '',
  },
  mobile_is_required: {
    EN: 'Mobile is required',
    ID: '',
  },
  shipping_charges_is_not_valid: {
    EN: 'Shipping Charges is not valid',
    ID: '',
  },
  discount_is_not_valid: {
    EN: 'Discount is not valid',
    ID: '',
  },
  cod_amount_is_not_valid: {
    EN: 'COD Amount is not valid',
    ID: '',
  },
  sender_name_is_required: {
    EN: 'Sender name is required',
    ID: '',
  },
  order_details: {
    EN: 'Order Details',
    ID: '',
  },
  place_order: {
    EN: 'Place Order',
    ID: '',
  },
  shipping_charges: {
    EN: 'Shipping Charges',
    ID: '',
  },
  profile_tab_is_required: {
    EN: 'Profile tab is required',
    ID: '',
  },
  name_can_be_maximum_50_characters_long: {
    EN: 'Name can be maximum 50 characters long',
    ID: '',
  },
  description_can_be_maximum_128_characters_long: {
    EN: 'Description can be maximum 128 characters long',
    ID: '',
  },
  comment_can_be_maximum_128_characters_long: {
    EN: 'Comment can be maximum 128 characters long',
    ID: '',
  },
  completion_text_is_required: {
    EN: 'Completion Text is Required',
    ID: '',
  },
  completion_text_can_be_maximum_128_characters_long: {
    EN: 'Completion Text can be maximum 128 characters long',
    ID: '',
  },
  pro_tip_can_be_maximum_120_characters_long: {
    EN: 'Pro Tip can be maximum 120 characters long',
    ID: '',
  },
  action_is_required: {
    EN: 'Action is Required',
    ID: '',
  },
  reward_option_is_required: {
    EN: 'Reward Option is required',
    ID: '',
  },
  challenge_option_value_is_required: {
    EN: 'Challenge Option value is Required',
    ID: '',
  },
  challenge_type_is_required: {
    EN: 'Challenge type is required',
    ID: '',
  },
  streak_interval_is_required: {
    EN: 'Streak interval is required',
    ID: '',
  },
  streak_count_is_required: {
    EN: 'Streak count is required',
    ID: '',
  },
  link_text_is_required: {
    EN: 'Link text is required',
    ID: '',
  },
  audience_ids_cannot_be_empty: {
    EN: 'Include audience Ids cannot be empty',
    ID: '',
  },
  payment_pending: {
    EN: 'Payment Pending',
    ID: '',
  },
  shipped: {
    EN: 'Shipped',
    ID: '',
  },
  delivered: {
    EN: 'Delivered',
    ID: 'Diterima',
  },
  cancelled: {
    EN: 'Cancelled',
    ID: 'Dibatalkan',
  },
  hold: {
    EN: 'hold',
    ID: '',
  },
  refund: {
    EN: 'Refund',
    ID: '',
  },
  re_ship: {
    EN: 'Re-Ship',
    ID: '',
  },
  dispute_con_call: {
    EN: 'Dispute Con. Call',
    ID: '',
  },
  supplier_accepted: {
    EN: 'Supplier Accepted',
    ID: '',
  },
  catalog: {
    EN: 'Catalog',
    ID: '',
  },
  non_catalog: {
    EN: 'Non Catalog',
    ID: '',
  },
  bank_transfer: {
    EN: 'Bank Transfer',
    ID: '',
  },
  cod: {
    EN: 'COD',
    ID: '',
  },
  online: {
    EN: 'Online',
    ID: '',
  },
  paytm: {
    EN: 'Paytm',
    ID: '',
  },
  credits_wallet: {
    EN: 'Credits (Wallet)',
    ID: '',
  },
  wallet: {
    EN: 'Wallet',
    ID: 'Dompet',
  },
  pending: {
    EN: 'Pending',
    ID: '',
  },
  picked_up: {
    EN: 'Picked Up',
    ID: '',
  },
  return_pending: {
    EN: 'Return Pending',
    ID: '',
  },
  return_picked_up_or_delivered: {
    EN: 'Return Picked Up Or Delivered',
    ID: '',
  },
  exchange_dispatched: {
    EN: 'Exchange Dispatched',
    ID: '',
  },
  exchange_delivered: {
    EN: 'Exchange Delivered',
    ID: '',
  },
  maybe: {
    EN: 'Maybe',
    ID: '',
  },
  done: {
    EN: 'Done',
    ID: '',
  },
  product_not_available: {
    EN: 'Product Not Available',
    ID: '',
  },
  cancelled_by_reseller: {
    EN: 'Cancelled by Reseller',
    ID: '',
  },
  quality_issue: {
    EN: 'Quality issue',
    ID: '',
  },
  test_order_product_cancelled: {
    EN: 'Test Order(Product Cancelled)',
    ID: '',
  },
  test_order_order_cancelled: {
    EN: 'Test Order(Order Cancelled)',
    ID: '',
  },
  placed_multiple_duplicate_orders: {
    EN: 'Placed multiple / duplicate orders',
    ID: '',
  },
  reseller_unresponsive: {
    EN: 'Reseller Unresponsive',
    ID: '',
  },
  bank_transfer_pending: {
    EN: 'Bank transfer pending',
    ID: '',
  },
  cod_verification_pending: {
    EN: 'COD verification pending',
    ID: '',
  },
  delayed_delivery_shipping: {
    EN: 'Delayed delivery / shipping',
    ID: '',
  },
  pincode_not_serviceable: {
    EN: 'Pincode Not Serviceable',
    ID: '',
  },
  wants_to_change_address: {
    EN: 'Wants to change address',
    ID: '',
  },
  exchange_product_not_available: {
    EN: 'Exchange Product Not Available',
    ID: '',
  },
  change_in_supplier: {
    EN: 'Change in supplier',
    ID: '',
  },
  rto_returned_to_supplier: {
    EN: 'RTO (returned to supplier)',
    ID: '',
  },
  better_price_available: {
    EN: 'Better price available',
    ID: '',
  },
  customer_changed_his_her_mind: {
    EN: 'Customer changed his/her mind',
    ID: '',
  },
  unhappy_with_delivery_time: {
    EN: 'Unhappy with delivery time',
    ID: '',
  },
  wants_to_avail_discounts: {
    EN: 'Wants to avail discounts',
    ID: '',
  },
  wants_to_change_contact_number: {
    EN: 'Wants to change contact number',
    ID: '',
  },
  order_placed_by_mistake: {
    EN: 'Order placed by mistake',
    ID: '',
  },
  wants_to_change_payment_mode: {
    EN: 'Wants to change payment mode',
    ID: '',
  },
  unaware_of_placing_order: {
    EN: 'Unaware of placing order',
    ID: '',
  },
  unhappy_with_previous_order: {
    EN: 'Unhappy with previous order',
    ID: '',
  },
  please_save_the_template: {
    EN: 'Please save the template',
    ID: '',
  },
  wants_to_change_size: {
    EN: 'Wants to change size',
    ID: '',
  },
  wants_to_change_color: {
    EN: 'Wants to change color',
    ID: '',
  },
  sla_breached_by_supplier: {
    EN: 'SLA breached by supplier',
    ID: '',
  },
  sla_breached_by_supplier_with_compensation: {
    EN: 'SLA breached by supplier (With Compensation)',
    ID: '',
  },
  delayed_delivery_shipping_without_bonus: {
    EN: 'Delayed delivery / shipping (without bonus)',
    ID: '',
  },
  fraud_orders_fake_orders: {
    EN: 'Fraud orders / Fake orders',
    ID: '',
  },
  out_of_stock: {
    EN: 'Out of stock',
    ID: '',
  },
  initiated: {
    EN: 'Initiated',
    ID: '',
  },
  accepted: {
    EN: 'Accepted',
    ID: '',
  },
  rejected: {
    EN: 'Rejected',
    ID: '',
  },
  re_initiated: {
    EN: 'Re Initiated',
    ID: '',
  },
  cancelled_by_delhivery: {
    EN: 'Cancelled By Delhivery',
    ID: '',
  },
  it_doesnt_meet_our_return_policy: {
    EN: 'it doesn\'t meet our return policy.',
    ID: '',
  },
  the_product_is_not_in_original_condition: {
    EN: 'the product is not in original condition.',
    ID: '',
  },
  the_request_has_been_raised_late_per_our_return_policy: {
    EN: 'the request has been raised late per our return policy.',
    ID: '',
  },
  per_your_request: {
    EN: 'per your request.',
    ID: '',
  },
  it_doesnt_meet_our_exchange_policy: {
    EN: 'it doesn\'t meet our exchange policy.',
    ID: '',
  },
  the_requested_product_is_not_available: {
    EN: 'the requested product is not available.',
    ID: '',
  },
  unprocessed: {
    EN: 'unprocessed',
    ID: '',
  },
  flagged_as_reseller: {
    EN: 'flagged as reseller',
    ID: '',
  },
  flagged_as_customer: {
    EN: 'flagged as customer',
    ID: '',
  },
  blocked_as_customer: {
    EN: 'blocked as customer',
    ID: '',
  },
  unflagged_from_customer: {
    EN: 'unflagged from customer',
    ID: '',
  },
  no_screen: {
    EN: 'No Screen',
    ID: '',
  },
  single_collection: {
    EN: 'Single Collection',
    ID: '',
  },
  referral_id: {
    EN: 'Referral ID',
    ID: '',
  },
  referee_name: {
    EN: 'Referee Name',
  },

  referral_program: {
    EN: 'Referral Program',
    ID: '',
  },
  referral_details: {
    EN: 'Referral Details',
    ID: '',
  },
  challenges_screen: {
    EN: 'Challenges Screen',
    ID: '',
  },
  bonus_tracker: {
    EN: 'Bonus Tracker',
    ID: '',
  },
  collection_screen: {
    EN: 'Collection Screen',
    ID: '',
  },
  web_view: {
    EN: 'Web View',
    ID: '',
  },
  page: {
    EN: 'page',
    ID: '',
  },
  pages: {
    EN: 'pages',
    ID: '',
  },
  landing_page: {
    EN: 'Landing Page',
    ID: '',
  },
  app_update: {
    EN: 'App Update',
    ID: '',
  },
  complete_profile: {
    EN: 'Complete Profile',
    ID: '',
  },
  browser: {
    EN: 'Browser',
    ID: '',
  },
  community: {
    EN: 'Community',
    ID: 'Community',
  },
  community_moderation: {
    EN: 'Community Moderation',
    ID: 'Community Moderation',
  },
  popup_title: {
    EN: 'Title: ',
    ID: 'Title: ',
  },
  popup_close: {
    EN: 'Close',
    ID: 'Close',
  },
  show_more_cta: {
    EN: 'Show more',
    ID: 'Show more',
  },
  post_moderated: {
    EN: 'Moderated',
    ID: 'Moderated',
  },
  post_non_moderated: {
    EN: 'Non Moderated',
    ID: 'Non Moderated',
  },
  post_under_review: {
    EN: 'Under Review',
    ID: 'Under Review',
  },
  post_blocked: {
    EN: 'Blocked',
    ID: 'Blocked',
  },
  post_status: {
    EN: 'Status',
    ID: 'Status',
  },
  post_date_from: {
    EN: 'Post Date from',
    ID: 'Post Date from',
  },
  post_date_to: {
    EN: 'Post Date to',
    ID: 'Post Date to',
  },
  post_from: {
    EN: 'Post From',
    ID: 'Post From',
  },
  post_to: {
    EN: 'Post To',
    ID: 'Post To',
  },
  post_permalink: {
    EN: 'Permalink',
    ID: 'Permalink',
  },
  created_at: {
    EN: 'Created at',
    ID: 'Created at',
  },
  user_details: {
    EN: 'User details',
    ID: 'User details',
  },
  post_url: {
    EN: 'URL',
    ID: 'URL',
  },
  reported: {
    EN: 'Reported',
    ID: 'Reported',
  },
  moderation_remark: {
    EN: 'Moderation Remark',
    ID: 'Moderation Remark',
  },
  moderation_remark_competitor_comparison: {
    EN: 'Competitor Products/Comparison',
    ID: 'Competitor Products/Comparison',
  },
  moderation_remark_complaints: {
    EN: 'Complaints',
    ID: 'Complaints',
  },
  moderation_remark_compliments_suggestions: {
    EN: 'Compliments/Suggestions to Meesho',
    ID: 'Compliments/Suggestions to Meesho',
  },
  moderation_remark_engagement_post: {
    EN: 'Engagement Post',
    ID: 'Engagement Post',
  },
  moderation_remark_greetings_festive_wishes: {
    EN: 'Greetings/Festive Wishes',
    ID: 'Greetings/Festive Wishes',
  },
  moderation_remark_new_products_stock_requests: {
    EN: 'New Products/Stock Requests',
    ID: 'New Products/Stock Requests',
  },
  moderation_remark_personal_account_promotion: {
    EN: 'Personal Account Promotion',
    ID: 'Personal Account Promotion',
  },
  moderation_remark_product_feedback: {
    EN: 'Product Feedback',
    ID: 'Product Feedback',
  },
  moderation_remark_product_recommendations: {
    EN: 'Product Recommendations',
    ID: 'Product Recommendations',
  },
  moderation_remark_product_sharing: {
    EN: 'Product Sharing',
    ID: 'Product Sharing',
  },
  moderation_remark_reselling_tips_questions: {
    EN: 'Reselling Tips & Questions',
    ID: 'Reselling Tips & Questions',
  },
  moderation_remark_price: {
    EN: 'Price',
    ID: 'Price',
  },
  moderation_remark_quality: {
    EN: 'Quality',
    ID: 'Quality',
  },
  moderation_remark_competitor_apps: {
    EN: 'Competitor Apps',
    ID: 'Competitor Apps',
  },
  moderation_remark_rant: {
    EN: 'Rant',
    ID: 'Rant',
  },
  moderation_remark_negative_adjectives: {
    EN: 'Negative Adjectives',
    ID: 'Negative Adjectives',
  },
  moderation_remark_order_concerns: {
    EN: 'Return/Refund/Exchange concerns',
    ID: 'Return/Refund/Exchange concerns',
  },
  moderation_remark_delivery_pickup_issues: {
    EN: 'Delivery/Pickup issues',
    ID: 'Delivery/Pickup issues',
  },
  moderation_remark_order_related_issues: {
    EN: 'Order related problems',
    ID: 'Order related problems',
  },
  moderation_remark_amount_related_issues: {
    EN: 'Payment/Bonus/Margin amount related issues',
    ID: 'Payment/Bonus/Margin amount related issues',
  },
  moderation_remark_spam: {
    EN: 'Spam',
    ID: 'Spam',
  },
  moderation_remark_trying_to_sell: {
    EN: 'Trying to sell',
    ID: 'Trying to sell',
  },
  moderation_remark_others: {
    EN: 'Others',
    ID: 'Others',
  },
  moderation_remark_please_specify: {
    EN: 'Please specify',
    ID: 'Please specify',
  },
  system_moderation_details: {
    EN: 'System moderation details',
    ID: 'System moderation details',
  },
  no_system_moderation_details: {
    EN: 'No system moderation details',
    ID: 'No system moderation details',
  },
  no_post_description: {
    EN: 'No descripton',
    ID: 'No descripton',
  },
  no_post_media: {
    EN: 'No media',
    ID: 'No media',
  },
  post_title: {
    EN: 'Title',
    ID: 'Title',
  },
  post_description: {
    EN: 'Description',
    ID: 'Description',
  },
  post_media: {
    EN: 'Media',
    ID: 'Media',
  },
  post_hashtags: {
    EN: 'Hashtags',
    ID: 'Hashtags',
  },
  post_topic: {
    EN: 'Topic',
    ID: 'Topic',
  },
  post_views: {
    EN: 'Views',
    ID: 'Views',
  },
  post_likes: {
    EN: 'Likes',
    ID: 'Likes',
  },
  post_comments: {
    EN: 'Comments',
    ID: 'Comments',
  },
  post_shares: {
    EN: 'Shares',
    ID: 'Shares',
  },
  post_saves: {
    EN: 'Saves',
    ID: 'Saves',
  },
  post_moderation_timelines: {
    EN: 'Moderated by',
    ID: 'Moderated by',
  },
  no_posts_found: {
    EN: 'No posts found',
    ID: 'No posts found',
  },
  confirm_post_status_change: {
    EN: 'Are you sure you want to change the status of the post: ',
    ID: 'Are you sure you want to change the status of the post: ',
  },
  please_select_date_range: {
    EN: 'Please select the date range!',
    ID: 'Please select the date range!',
  },
  from_greater_than_to_date: {
    EN: 'From date cannot be greater than To date!',
    ID: 'From date cannot be greater than To date!',
  },
  fetch_posts_failed: {
    EN: 'Fetch posts failed. Please try again',
    ID: 'Fetch posts failed. Please try again',
  },
  post_moderation_failed: {
    EN: 'Post moderation failed. Please try again',
    ID: 'Post moderation failed. Please try again',
  },
  canvas_id: {
    EN: 'Canvas ID',
    ID: 'Canvas ID',
  },
  community_moderation_mandatory_filters: {
    EN: 'Please select any of these filters to fetch data - User ID, Title, Status, Date Range or Permalink',
    ID: 'Please select any of these filters to fetch data - User ID, Title, Status, Date Range or Permalink',
  },
  reached_last_page: {
    EN: 'You have reached the last page!',
    ID: 'You have reached the last page!',
  },
  reached_first_page: {
    EN: 'You are on the first page!',
    ID: 'You are on the first page!',
  },
  search_by_title: {
    EN: 'Search by title',
    ID: 'Search by title',
  },
  community_upload: {
    EN: 'Community Upload',
    ID: '',
  },
  search_by_post_id: {
    EN: 'Post ID',
    ID: '',
  },
  upload_posts_csv_file: {
    EN: 'Upload CSV File',
    ID: '',
  },
  pinned_posts: {
    EN: 'Pinned Posts',
    ID: 'post_priority',
  },
  post_priority: {
    EN: 'Priority',
    ID: '',
  },
  post_start_time: {
    EN: 'Start Time',
    ID: '',
  },
  post_end_time: {
    EN: 'End Time',
    ID: '',
  },
  post_user_action: {
    EN: 'Action',
    ID: '',
  },
  confirm_pinned_post: {
    EN: 'Are you sure you want to pin this post: ',
    ID: '',
  },
  confirm_post_delete: {
    EN: 'Are you sure you want to delete this post: ',
    ID: '',
  },
  confirm: {
    EN: 'Confirm',
    ID: '',
  },
  post_edit_button: {
    EN: 'Edit',
    ID: '',
  },
  post_delete_button: {
    EN: 'Delete',
    ID: '',
  },
  post_created: {
    EN: 'Post Created',
    ID: '',
  },
  post_type: {
    EN: 'Type',
    ID: '',
  },
  post_name: {
    EN: 'Name',
    ID: '',
  },
  delete_post_success: {
    EN: 'Delete Post Success!',
    ID: '',
  },
  pin_post_success: {
    EN: 'Pin Post Success!',
    ID: '',
  },
  edit_post_success: {
    EN: 'Edit Post Success!',
    ID: '',
  },
  training: {
    EN: 'Training',
    ID: '',
  },
  travel_landing_page: {
    EN: 'Travel Landing Page',
    ID: '',
  },
  spin_rewards: {
    EN: 'Spin Rewards',
    ID: '',
  },
  join_mentorship: {
    EN: 'Join Mentorship',
    ID: '',
  },
  hour: {
    EN: 'Hour',
    ID: '',
  },
  day: {
    EN: 'Day',
    ID: '',
  },
  week: {
    EN: 'Week',
    ID: '',
  },
  month: {
    EN: 'Month',
    ID: '',
  },
  scheduled: {
    EN: 'Scheduled',
    ID: '',
  },
  failed: {
    EN: 'Failed',
    ID: '',
  },
  paid: {
    EN: 'Paid',
    ID: 'Dibayar',
  },
  no_returns: {
    EN: 'No returns',
    ID: '',
  },
  exchange_only: {
    EN: 'Exchange only',
    ID: '',
  },
  rto: {
    EN: 'RTO',
    ID: '',
  },
  product_missing: {
    EN: 'Product Missing',
    ID: '',
  },
  to_be_dispatched: {
    EN: 'To Be Dispatched',
    ID: '',
  },
  ready_to_ship: {
    EN: 'Ready To Ship',
    ID: '',
  },
  qc_testing: {
    EN: 'QC Testing',
    ID: '',
  },
  qc_sampling: {
    EN: 'QC Sampling',
    ID: '',
  },
  acceptable_quality: {
    EN: 'Acceptable Quality',
    ID: '',
  },
  qc_deactivation: {
    EN: 'QC Deactivation',
    ID: '',
  },
  acceptable_quality_ncb: {
    EN: 'Acceptable Quality - NCB',
    ID: '',
  },
  qc_reactivation: {
    EN: 'QC Reactivation',
    ID: '',
  },
  qc_testing_under_review: {
    EN: 'QC Testing - Under Review',
    ID: '',
  },
  qc_reactivation_under_review: {
    EN: 'QC Reactivation - Under Review',
    ID: '',
  },
  edge_suppliers: {
    EN: 'Edge Suppliers',
    ID: '',
  },
  beauty_qc_testing: {
    EN: 'Beauty QC Testing',
    ID: '',
  },
  qc_testing_dropped: {
    EN: 'QC Testing - Dropped',
    ID: '',
  },
  supplier_cancellation_deactivation: {
    EN: 'Supplier Cancellation Deactivation',
    ID: '',
  },
  fraud_supplier_deactivation: {
    EN: 'Fraud supplier deactivation',
    ID: '',
  },
  approved: {
    EN: 'Approved',
    ID: '',
  },
  none: {
    EN: 'None',
    ID: '',
  },
  for_you_exclusion: {
    EN: 'For You Exclusion',
    ID: '',
  },
  manufacturer: {
    EN: 'Manufacturer',
    ID: '',
  },
  wholesaler: {
    EN: 'Wholesaler',
    ID: '',
  },
  hybrid: {
    EN: 'Hybrid',
    ID: '',
  },
  big_manufacturers: {
    EN: 'Big Manufacturers',
    ID: '',
  },
  medium_manufacturers: {
    EN: 'Medium Manufacturers',
    ID: '',
  },
  small_manufacturers: {
    EN: 'Small Manufacturers',
    ID: '',
  },
  big_wholesalers: {
    EN: 'Big Wholesalers',
    ID: '',
  },
  medium_wholesalers: {
    EN: 'Medium Wholesalers',
    ID: '',
  },
  small_wholesalers: {
    EN: 'Small Wholesalers',
    ID: '',
  },
  exchange_is_not_allowed_for_international_products: {
    EN: 'Exchange is not allowed for International Products',
    ID: '',
  },
  exchange_is_not_allowed_for_cpg_products: {
    EN: 'Exchange is not allowed for CPG Products',
    ID: '',
  },
  exchange_is_not_allowed_for_this_product: {
    EN: 'Exchange is not allowed for this Product',
    ID: '',
  },
  gstin_is_required: {
    EN: 'GSTIN is required',
    ID: '',
  },
  enrolment_is_required: {
    EN: 'ENROLMENT No. is required',
    ID: '',
  },
  gstin_not_valid: {
    EN: 'GSTIN not valid (Valid Length 15)',
    ID: '',
  },
  enrolment_exceeding_max_limit: {
    EN: 'ENROLMENT No. not valid (Exceeding Max Length Of 25)',
    ID: '',
  },
  gstin_must_be_alpha_numeric: {
    EN: 'GSTIN must be alpha numeric.',
    ID: '',
  },
  pan_card_image_is_required: {
    EN: 'Pan card image is required',
    ID: '',
  },
  attribute_value_is_required: {
    EN: 'Attribute Value is required',
    ID: '',
  },
  for_you: {
    EN: 'For You',
    ID: 'Beranda',
  },
  home_page: {
    EN: 'Home Page',
    ID: '',
  },
  collections_tab: {
    EN: 'Collections Tab',
    ID: '',
  },
  user_sync_error_retry: {
    EN: 'User sync error. retry',
    ID: '',
  },
  deselect_all_roles: {
    EN: 'Deselect All Roles',
    ID: '',
  },
  select_all_roles: {
    EN: 'Select All Roles',
    ID: '',
  },
  change_country: {
    EN: 'Change Country',
    ID: '',
  },
  change_panel_language: {
    EN: 'Change Panel Language',
    ID: '',
  },
  search_user: {
    EN: 'Search User',
    ID: '',
  },
  search_team: {
    EN: 'Search Team',
    ID: '',
  },
  start_position: {
    EN: 'Start Position',
    ID: '',
  },
  items: {
    EN: 'Items',
    ID: '',
  },
  items_returned: {
    EN: 'Items Returned',
    ID: '',
  },
  items_per_page: {
    EN: 'Items per page',
    ID: '',
  },
  search_team_required: {
    EN: 'Search Team Required',
    ID: '',
  },
  start_position_required: {
    EN: 'Start Position Required',
    ID: '',
  },
  start_position_invalid: {
    EN: 'Invalid Start Position',
    ID: '',
  },
  items_returned_required: {
    EN: 'Items Returned Required',
    ID: '',
  },
  items_returned_invalid: {
    EN: 'Invalid Items Returned',
    ID: '',
  },
  user_profile_update: {
    EN: 'User Profile Update',
    ID: '',
  },
  user_performance: {
    EN: 'User Performance',
    ID: 'Performa Pengguna ',
  },
  unexpected_error_retry: {
    EN: 'Unexpected error retry',
    ID: '',
  },
  profile_tab: {
    EN: 'Profile Tab',
    ID: '',
  },
  select_profile_tab: {
    EN: 'Select Profile Tab',
    ID: '',
  },
  contact: {
    EN: 'Contact',
    ID: '',
  },
  personal: {
    EN: 'Personal',
    ID: '',
  },
  work_and_education: {
    EN: 'Work and Education',
    ID: '',
  },
  min_version_code: {
    EN: 'Min Android Version Code',
    ID: '',
  },
  max_version_code: {
    EN: 'Max Android Version Code',
    ID: '',
  },
  min_version_code_ios: {
    EN: 'Min IOS Version Code',
    ID: '',
  },
  max_version_code_ios: {
    EN: 'Max IOS Version Code',
    ID: '',
  },
  id_not_found: {
    EN: 'Id not found',
    ID: '',
  },
  request_pending: {
    EN: 'Request Pending',
    ID: '',
  },
  link: {
    EN: 'Link',
    ID: '',
  },
  uploaded_file_data: {
    EN: 'Uploaded File Data',
    ID: '',
  },
  preview_file_data: {
    EN: 'Preview File Data',
    ID: '',
  },
  user_id: {
    EN: 'User ID',
    ID: 'ID Pengguna',
  },
  sub_orders: {
    EN: 'Sub Orders',
    ID: '',
  },
  uploaded_by: {
    EN: 'Uploaded By',
    ID: '',
  },
  deal_id: {
    EN: 'Deal Id',
    ID: '',
  },
  title: {
    EN: 'Title',
    ID: '',
  },
  possible_gst_mismatch: {
    EN: 'Possible GST Mismatch',
    ID: '',
  },
  price_excluding_gst__is_greater_than: {
    EN: 'Price (excluding GST) is greater than Rs. 999 and GST < 12%. Please check if this is correct.',
    ID: '',
  },
  price_excluding_gst_is_less_than: {
    EN: 'Price (excluding GST) is less than Rs. 999 and GST > 5%. Please check if this is correct.',
    ID: '',
  },
  correct: {
    EN: 'CORRECT',
    ID: '',
  },
  incorrect: {
    EN: 'INCORRECT',
    ID: '',
  },
  price_exceeding_gst_slab: {
    EN: 'Price Exceeding GST Slab',
    ID: '',
  },
  price_excluding_gst_after_markup_is_greater_than: {
    EN: 'Price (excluding GST) after markup is greater than Rs. 1000. Please cap selling price to Rs. 999 if needed. Enter the suggested markup percentage if you want to do this.',
    ID: '',
  },
  modify_markup: {
    EN: 'MODIFY MARKUP',
    ID: '',
  },
  proceed_as_it_is: {
    EN: 'PROCEED AS IT IS',
    ID: '',
  },
  wholesale_price: {
    EN: 'Wholesale Price',
    ID: '',
  },
  wholesale_price_percentage: {
    EN: 'Wholesale Price (%)',
    ID: '',
  },
  gst_rate: {
    EN: 'GST Rate',
    ID: 'Tax Rate',
  },
  fill_supplier_cost: {
    EN: 'Fill Supplier Cost',
    ID: '',
  },
  basic_return_price_error: {
    EN: 'Basic price return error',
    ID: '',
  },
  fill_input_price: {
    EN: 'Fill Input Price',
    ID: '',
  },
  fill_gst_rate: {
    EN: 'Fill Gst Rate',
    ID: '',
  },
  fill_monetization_percentage: {
    EN: 'Fill Monetization Percentage',
    ID: '',
  },
  fill_shipping_time: {
    EN: 'Fill Shipping Time',
    ID: '',
  },
  supplier_price_or_gst_rate_or_wholesale_price_changed_please_recompute_price_and_submit:
    {
      EN: 'Supplier Price or GST Rate or Wholesale Price changed. Please recompute price and Submit.',
      ID: '',
    },
  supplier_price_or_gst_rate_or_wholesale_price_changed_please_recompute_price_and_submit_brp:
    {
      EN: 'Supplier Price or GST Rate or Wholesale Price or Input Basic Return Price changed. Please recompute price and Submit.',
      ID: '',
    },
  invalid_inputs_psm: {
    EN: 'Invalid Inputs. Price, Supplier Cost (Exclusive GST), Supplier Cost, Shipping Time and 1 Size is mandatory.',
    ID: '',
  },
  invalid_inputs_psm_brp: {
    EN: 'Invalid Inputs. Price, Input Basic Return Price, MRP, Manufacturer details, Packer details, Supplier Cost (Exclusive GST), Supplier Cost, Shipping Time and 1 Size is mandatory.',
    ID: '',
  },
  select_gst_type: {
    EN: 'Select GST Type',
    ID: '',
  },
  mark_up_error: {
    EN: 'Mark up error',
    ID: '',
  },
  sku_of_affected_rows: {
    EN: 'SKU of affected rows:',
    ID: '',
  },
  mark_up_price_affected_row: {
    EN: 'Mark up price affected row',
    ID: '',
  },
  suggested_mark_up_is: {
    EN: 'Suggested mark up is',
    ID: '',
  },
  gst_rate_error: {
    EN: 'GST Rate error',
    ID: '',
  },
  all_products: {
    EN: 'All Products',
    ID: '',
  },
  gst_rate_affected_rows: {
    EN: 'GST Rate affected rows:',
    ID: '',
  },
  suggested_catalog_data: {
    EN: 'Suggested Catalog Data',
    ID: '',
  },
  catalog_image: {
    EN: 'Catalog Image',
    ID: '',
  },
  user_name: {
    EN: 'User Name',
    ID: 'Nama Pengguna',
  },
  user_token: {
    EN: 'User Token',
    ID: '',
  },
  date_start: {
    EN: 'Date Start',
    ID: '',
  },
  date_end: {
    EN: 'Date End',
    ID: '',
  },
  total_votes: {
    EN: 'Total Votes',
    ID: '',
  },
  earnings: {
    EN: 'Earnings',
    ID: '',
  },
  sub_order_count: {
    EN: 'Sub Order Count',
    ID: '',
  },
  settings: {
    EN: 'Settings',
    ID: '',
  },
  supplier_penalty: {
    EN: 'Supplier Penalty',
    ID: '',
  },
  video_influencer: {
    EN: 'Video Influencer',
    ID: '',
  },
  meesho_rewards: {
    EN: 'Meesho Rewards',
    ID: '',
  },
  mentor_dashboard: {
    EN: 'Mentor Dashboard',
    ID: '',
  },
  supplier_manifest: {
    EN: 'Supplier manifest',
    ID: '',
  },
  marketing_dashboard: {
    EN: 'Marketing Dashboard',
    ID: '',
  },
  notification_portal: {
    EN: 'Notification Portal',
    ID: 'Portal Pemberitahuan',
  },
  notification_sample_body: {
    EN: '${author_name} added a new post.Click to see more details',
    ID: '$ {author_name} menambahkan posting baru. Klik untuk melihat lebih detail',
  },
  notification_sample_title: {
    EN: 'See our latest post on ${post_name}',
    ID: 'Waktu berakhir diperlukan',
  },
  sms_portal: {
    EN: 'Sms Portal',
    ID: '',
  },
  make_calls: {
    EN: 'Make calls',
    ID: '',
  },
  s3_file_uploader: {
    EN: 'S3 File Uploader',
    ID: '',
  },
  failed_to_create_deal: {
    EN: 'Failed to create Deal',
    ID: '',
  },
  back_to_deals: {
    EN: 'Back to deals',
    ID: '',
  },
  deal_color: {
    EN: 'Deal Color',
    ID: '',
  },
  no_deals_found: {
    EN: 'No deals found',
    ID: '',
  },
  app_banner_file_upload: {
    EN: 'App Banner File Upload',
    ID: '',
  },
  app_information: {
    EN: 'App Information',
    ID: '',
  },
  pro_tip: {
    EN: 'Pro Tip',
    ID: '',
  },
  invoices: {
    EN: 'Invoices',
    ID: '',
  },
  app_banner_files: {
    EN: 'App Banner Files',
    ID: '',
  },
  add_app_banner: {
    EN: 'Add App Banner',
    ID: '',
  },
  banner_id: {
    EN: 'Banner id',
    ID: '',
  },
  audience_ids: {
    EN: 'Audience Ids',
    ID: '',
  },
  include_audience_ids: {
    EN: 'Include Audience Ids',
    ID: '',
  },
  exclude_audience_ids: {
    EN: 'Exclude Audience Ids',
    ID: '',
  },
  awb_invoices_file_upload: {
    EN: 'AWB Invoices File Upload',
    ID: '',
  },
  awb_invoice_file: {
    EN: 'AWB Invoice File',
    ID: '',
  },
  boe_invoices_file_upload: {
    EN: 'BOE Invoices File Upload',
    ID: '',
  },
  boe_invoice_file: {
    EN: 'BOE Invoice File',
    ID: '',
  },
  boe_invoice_file_upload: {
    EN: 'BOE Invoice File Upload',
    ID: '',
  },
  awb_invoice_file_upload: {
    EN: 'AWB Invoice File Upload',
    ID: '',
  },
  catalog_product_map_file_upload: {
    EN: 'Catalog Product Map File Upload',
    ID: '',
  },
  catalog_product_map_file: {
    EN: 'Catalog Product Map File',
    ID: '',
  },
  catalog_product_videos_merchandising: {
    EN: 'Catalog Product Videos Merchandising',
    ID: '',
  },
  catalog_videos_merchandising: {
    EN: 'Catalog Videos Merchandising',
    ID: '',
  },
  catalog_product_videos_merchandising_file_upload: {
    EN: 'Catalog/Product Videos Merchandising File Upload',
    ID: '',
  },
  fetch_catalog_product_video: {
    EN: 'Fetch Catalog Product Video',
    ID: '',
  },
  china_vsku_file_upload: {
    EN: 'China VSKU File Upload',
    ID: '',
  },
  order_shipped_delivered: {
    EN: 'Order Shipped Delivered',
    ID: '',
  },
  edit_price_scheduling_file_upload: {
    EN: 'Edit Price Scheduling File Upload',
    ID: '',
  },
  price_scheduling_file_upload: {
    EN: 'Price Scheduling File Upload',
    ID: '',
  },
  edit_price_scheduling_file: {
    EN: 'Edit Price Scheduling File',
    ID: '',
  },
  upload_price_scheduling_file: {
    EN: 'Upload Price Scheduling File',
    ID: '',
  },
  input_price: {
    EN: 'Input Price',
    ID: '',
  },
  original_price: {
    EN: 'Original Price',
    ID: '',
  },
  shipping_charges_adjustment: {
    EN: 'Shipping Charges Adjustment',
    ID: '',
  },
  show_as_percent: {
    EN: 'Show As Percent',
    ID: '',
  },
  system_shipping: {
    EN: 'System Shipping',
    ID: '',
  },
  valid_from: {
    EN: 'Valid from',
    ID: '',
  },
  valid_to: {
    EN: 'Valid To',
    ID: '',
  },
  cost_price: {
    EN: 'Cost Price',
    ID: '',
  },
  currency: {
    EN: 'Currency',
    ID: '',
  },
  product_activate_deactivate_file_upload: {
    EN: 'Product Activate/Deactivate File Upload',
    ID: '',
  },
  product_activate_deactivate_uploads: {
    EN: 'Product Activate/Deactivate Uploads',
    ID: '',
  },
  product_delete_file_upload: {
    EN: 'Product Delete File Upload',
    ID: '',
  },
  product_delete_file: {
    EN: 'Product Delete File',
    ID: '',
  },
  product_supplier_price_file_upload: {
    EN: 'Product Supplier Price File Upload',
    ID: '',
  },
  initial_catalog_id: {
    EN: 'Initial Catalog Id',
    ID: '',
  },
  source: {
    EN: 'Source',
    ID: '',
  },
  source_catalog_id: {
    EN: 'Source Catalog Id',
    ID: '',
  },
  supplier_link: {
    EN: 'Supplier Link',
    ID: '',
  },
  source_product_id: {
    EN: 'Source Product Id',
    ID: '',
  },
  product_description: {
    EN: 'Product Description',
    ID: 'Deskripsi Produk',
  },
  shipping_charge: {
    EN: 'Shipping Charge',
    ID: '',
  },
  image_url: {
    EN: 'Image Url',
    ID: '',
  },
  reverse_tinder: {
    EN: 'Reverse Tinder',
    ID: '',
  },
  reverse_tinder_file_upload: {
    EN: 'Reverse Tinder File Upload',
    ID: '',
  },
  attributes: {
    EN: 'Attributes',
    ID: '',
  },
  tinder_taxonomy_product_update_file_upload: {
    EN: 'Tinder Taxonomy Product Update File Upload',
    ID: '',
  },
  sub_order_carrier_change: {
    EN: 'Sub Order Carrier Change',
    ID: '',
  },
  carrier_id: {
    EN: 'Carrier Id',
    ID: '',
  },
  remarks: {
    EN: 'Remarks',
    ID: '',
  },
  remaining: {
    EN: 'Remaining',
    ID: '',
  },
  amount: {
    EN: 'Amount',
    ID: '',
  },
  reason: {
    EN: 'Reason',
    ID: '',
  },
  supplier_penalty_recovery_file_upload: {
    EN: 'Supplier Penalty/Recovery File Upload',
    ID: '',
  },
  download_example_template: {
    EN: 'Download Example Template',
    ID: '',
  },
  penalty_reason_id: {
    EN: 'Penalty Reason Id',
    ID: '',
  },
  flag: {
    EN: 'Flag',
    ID: '',
  },
  supplier_penalty_type_file_upload: {
    EN: 'Supplier Penalty Type File Upload',
    ID: '',
  },
  supplier_penalty_waiver_compensation_file_upload: {
    EN: 'Supplier Penalty Waiver & Compensation File Upload',
    ID: '',
  },
  taxonomy_product_update_file_upload: {
    EN: 'Taxonomy Product Update File Upload',
    ID: '',
  },
  user_block_file_upload: {
    EN: 'User Block File Upload',
    ID: '',
  },
  blocked_user: {
    EN: 'Blocked User',
    ID: '',
  },
  user_block_file_uploads: {
    EN: 'User Block File Uploads',
    ID: '',
  },
  user_cod_file_upload: {
    EN: 'User COD File Upload',
    ID: '',
  },
  bulk_collection_file_upload: {
    EN: 'Bulk Collection File Upload',
    ID: '',
  },
  blocked_user_cod: {
    EN: 'Blocked User COD',
    ID: '',
  },
  bulk_collection: {
    EN: 'Bulk Collection File Upload',
    ID: '',
  },
  offer_create: {
    EN: 'Offer Create',
    ID: '',
  },
  create_user_offer: {
    EN: 'Create User Offer',
    ID: '',
  },
  show_banner: {
    EN: 'Show Banner',
    ID: '',
  },
  add_image: {
    EN: 'Add Image',
    ID: '',
  },
  notification_details: {
    EN: 'Notification Details',
    ID: '',
  },
  user_offers_map_file: {
    EN: 'User Offers Map File',
    ID: '',
  },
  user_offers_map_file_upload: {
    EN: 'User Offers Map File Upload',
    ID: '',
  },
  create_offer: {
    EN: 'Create Offer',
    ID: '',
  },
  offer_id: {
    EN: 'Offer Id',
    ID: '',
  },
  files_uploading_view_actions: {
    EN: 'Files Uploading View Actions',
    ID: '',
  },
  catalog_videos_file_upload: {
    EN: 'Catalog Videos File Upload',
    ID: '',
  },
  product_supplier_price_file: {
    EN: 'Product Supplier Price File',
    ID: '',
  },
  taxonomy_product_update_file: {
    EN: 'Taxonomy Product Update File',
    ID: '',
  },
  tinder_taxonomy_product_update_file: {
    EN: 'Tinder Taxonomy Product Update File',
    ID: '',
  },
  catalog_product_map: {
    EN: 'Catalog Product Map',
    ID: '',
  },
  user_offer_create: {
    EN: 'User Offer Create',
    ID: '',
  },
  user_blocking_file_upload: {
    EN: 'User Blocking File Upload',
    ID: '',
  },
  product_activate_deactivate: {
    EN: 'Product Activate Deactivate',
    ID: '',
  },
  supplier_penalty_type: {
    EN: 'Supplier Penalty Type',
    ID: '',
  },
  credits_winner_info_graph: {
    EN: 'Credits Winner Info Graph',
    ID: '',
  },
  file_upload_s3_link_generator: {
    EN: 'File Upload S3 Link Generator',
    ID: '',
  },
  notification_image_s3_link_generator: {
    EN: 'Notification Image S3 Link Generator',
    ID: '',
  },
  select_bucket: {
    EN: 'Select Bucket',
    ID: '',
  },
  select_folder: {
    EN: 'Select Folder',
    ID: '',
  },
  get_s3_link: {
    EN: 'Get S3 Link',
    ID: '',
  },
  generated_s3_url: {
    EN: 'Generated S3 url',
    ID: '',
  },
  copy: {
    EN: 'Copy',
    ID: '',
  },
  suppliers_successfully_added: {
    EN: 'Suppliers successfully added',
    ID: '',
  },
  account_manager_delete_successfully: {
    EN: 'Account Manager delete successfully',
    ID: '',
  },
  already_added_suppliers: {
    EN: 'Already Added Suppliers',
    ID: '',
  },
  no_supplier_added_yet: {
    EN: 'No supplier added yet',
    ID: '',
  },
  new_selected_suppliers: {
    EN: 'New Selected Suppliers',
    ID: '',
  },
  no_new_supplier_added_yet: {
    EN: 'No new supplier added yet',
    ID: '',
  },
  are_you_sure_want_to_delete_this_admin: {
    EN: 'Are you sure want to Delete this admin?',
    ID: '',
  },
  delete_admin: {
    EN: 'Delete Admin',
    ID: '',
  },
  admin_added_successfully: {
    EN: 'Admin Added Successfully',
    ID: '',
  },
  user_not_valid: {
    EN: 'User not valid',
    ID: '',
  },
  admin_deleted_successfully: {
    EN: 'Admin Deleted Successfully',
    ID: '',
  },
  admins: {
    EN: 'Admins',
    ID: '',
  },
  add_delete: {
    EN: 'Add/Delete',
    ID: '',
  },
  select_user_type: {
    EN: 'Select User Type',
    ID: '',
  },
  bd_am_user_add: {
    EN: 'BD AM User Add',
    ID: '',
  },
  user_type: {
    EN: 'User Type',
    ID: '',
  },
  email_not_valid: {
    EN: 'Email not valid',
    ID: '',
  },
  password_not_valid_password_length_at_least_one_number_and_one_special_character:
    {
      EN: 'Password not valid. Password length 8-16, at least one number and one special character',
      ID: '',
    },
  password_updated_successfully: {
    EN: 'Password updated successfully',
    ID: '',
  },
  length_and_at_least_one_number_and_one_special_character: {
    EN: 'length 8-16, at least one number and one special character',
    ID: '',
  },
  name_not_valid_min_length_3: {
    EN: 'Name not valid. Min Length 3',
    ID: '',
  },
  add_new_user: {
    EN: 'Add New User',
    ID: '',
  },
  account_admin: {
    EN: 'Account Admin',
    ID: '',
  },
  identifier: {
    EN: 'Identifier',
    ID: '',
  },
  supplier_users: {
    EN: 'Supplier Users',
    ID: '',
  },
  no_user_added: {
    EN: 'No User Added',
    ID: '',
  },
  are_you_sure_want_to_remove_this_user: {
    EN: 'Are you sure want to remove this user?',
    ID: '',
  },
  remove_user: {
    EN: 'Remove User',
    ID: '',
  },
  firebase_settings: {
    EN: 'Firebase Settings',
    ID: '',
  },
  unable_to_fetch_challenges_audience_map: {
    EN: 'Unable to fetch Challenges audience map',
    ID: '',
  },
  campaign_name_is_required: {
    EN: 'Campaign Name is Required',
    ID: '',
  },
  challenge_is_required: {
    EN: 'Challenge is Required',
    ID: '',
  },
  activation_time_is_required: {
    EN: 'Activation Time is Required',
    ID: '',
  },
  expiry_time_is_required: {
    EN: 'Expiry Time is Required',
    ID: '',
  },
  valid_is_required: {
    EN: 'Valid is Required',
    ID: '',
  },
  failed_to_create_audience_map: {
    EN: 'Failed to create Audience Map',
    ID: '',
  },
  failed_to_edit_audience_map: {
    EN: 'Failed to edit Audience Map',
    ID: '',
  },
  create_challenge_campaign: {
    EN: 'Create Challenge Campaign',
    ID: '',
  },
  audiences_id: {
    EN: 'Audiences Id',
    ID: '',
  },
  campaign: {
    EN: 'Campaign',
    ID: '',
  },
  campaign_name: {
    EN: 'Campaign Name',
    ID: '',
  },
  select_challenge_audience: {
    EN: 'Select Challenge Audience',
    ID: '',
  },
  challenge: {
    EN: 'Challenge',
    ID: '',
  },
  select_challenge: {
    EN: 'Select Challenge',
    ID: '',
  },
  activation_time: {
    EN: 'Activation Time',
    ID: '',
  },
  expiry_time: {
    EN: 'Expiry Time',
    ID: '',
  },
  required: {
    EN: 'Required',
    ID: '',
  },
  optional: {
    EN: 'Optional',
    ID: '',
  },
  challenge_id: {
    EN: 'Challenge Id',
    ID: '',
  },
  expiring_on: {
    EN: 'Expiring On',
    ID: '',
  },
  edit_campaign: {
    EN: 'Edit Campaign',
    ID: '',
  },
  failed_to_fetch_audience_mapping_data: {
    EN: 'Failed to fetch Audience Mapping data',
    ID: '',
  },
  challenge_campaigns: {
    EN: 'Challenge Campaigns',
    ID: '',
  },
  unable_to_fetch_options: {
    EN: 'Unable to fetch options',
    ID: '',
  },
  failed_to_add_challenge: {
    EN: 'Failed to Add Challenge',
    ID: '',
  },
  view_challenge: {
    EN: 'View Challenge',
    ID: '',
  },
  add_challenge: {
    EN: 'Add Challenge',
    ID: '',
  },
  completion_text: {
    EN: 'Completion text',
    ID: '',
  },
  terms_url: {
    EN: 'Terms url',
    ID: '',
  },
  challenge_type: {
    EN: 'Challenge Type',
    ID: '',
  },
  normal: {
    EN: 'Normal',
    ID: '',
  },
  streak: {
    EN: 'Streak',
    ID: '',
  },
  streak_count: {
    EN: 'Streak Count',
    ID: '',
  },
  streak_interval: {
    EN: 'Streak Interval',
    ID: '',
  },
  reward_option: {
    EN: 'Reward Option',
    ID: '',
  },
  challenge_options: {
    EN: 'Challenge options',
    ID: '',
  },
  deeplink: {
    EN: 'Deeplink',
    ID: '',
  },
  link_text: {
    EN: 'Link text',
    ID: '',
  },
  challenge_action: {
    EN: 'Challenge Action',
    ID: '',
  },
  challenge_action_value: {
    EN: 'Challenge Action Value',
    ID: '',
  },
  failed_to_fetch_challenges: {
    EN: 'Failed to fetch Challenges',
    ID: '',
  },
  unable_to_fetch_audience_map: {
    EN: 'Unable to fetch audience map',
    ID: '',
  },
  spin_is_required: {
    EN: 'Spin is Required',
    ID: '',
  },
  campaign_type_is_required: {
    EN: 'Campaign Type is Required',
    ID: '',
  },
  campaign_type: {
    EN: 'Campaign Type',
    ID: '',
  },
  unlock_timestamp_or_duration_after_activation_is_required: {
    EN: 'Unlock Timestamp or duration after activation is Required',
    ID: '',
  },
  create_campaign: {
    EN: 'Create Campaign',
    ID: '',
  },
  reward: {
    EN: 'REWARD',
    ID: '',
  },
  duration_after_activation: {
    EN: 'Duration After Activation',
    ID: '',
  },
  spin: {
    EN: 'Spin',
    ID: '',
  },
  unlock_timestamp: {
    EN: 'Unlock Timestamp',
    ID: '',
  },
  spin_campaigns: {
    EN: 'Spin Campaigns',
    ID: '',
  },
  create_spin_campaign: {
    EN: 'Create Spin Campaign',
    ID: '',
  },
  campaign_id: {
    EN: 'Campaign Id',
    ID: '',
  },
  spin_id: {
    EN: 'Spin Id',
    ID: '',
  },
  mapping_type: {
    EN: 'Mapping Type',
    ID: '',
  },
  unlock_strategy_time: {
    EN: 'Unlock Strategy Time',
    ID: '',
  },
  name_cannot_be_empty: {
    EN: 'Name cannot be empty',
    ID: '',
  },
  credit_amount_is_required: {
    EN: 'Credit amount is required',
    ID: '',
  },
  spin_campaign_id_is_required: {
    EN: 'Spin campaign id is required',
    ID: '',
  },
  pre_reward_description_is_required: {
    EN: 'Pre-reward description is required',
    ID: '',
  },
  post_reward_description_is_required: {
    EN: 'Post-reward description is required',
    ID: '',
  },
  add_rewards: {
    EN: 'Add Rewards',
    ID: '',
  },
  credit_amount: {
    EN: 'Credit Amount',
    ID: '',
  },
  spin_campaign_id: {
    EN: 'Spin Campaign Id',
    ID: '',
  },
  pre_reward_description: {
    EN: 'Pre Reward Description',
    ID: '',
  },
  post_reward_description: {
    EN: 'Post Reward Description',
    ID: '',
  },
  rewards: {
    EN: 'Rewards',
    ID: '',
  },
  option_id: {
    EN: 'Option Id',
    ID: '',
  },
  please_enter_all_the_fields_for_respective_options: {
    EN: 'Please enter all the fields for respective options',
    ID: '',
  },
  campaign_value_not_selected: {
    EN: 'Campaign Value not selected',
    ID: '',
  },
  please_select_a_value: {
    EN: 'Please select a value',
    ID: '',
  },
  percentage_value_is_compulsory: {
    EN: 'Percentage value is compulsory',
    ID: '',
  },
  absolute_type_should_have_value_field_present: {
    EN: 'Absolute type should have `value` field present',
    ID: '',
  },
  please_enter_at_least_one_option_with_distribution_type_all: {
    EN: 'Please enter at least one option with distribution type `ALL`',
    ID: '',
  },
  name_can_be_maximum_25_characters_long: {
    EN: 'Name can be maximum 25 characters long',
    ID: '',
  },
  description_can_be_maximum_25_characters_long: {
    EN: 'Description can be maximum 25 characters long',
    ID: '',
  },
  fetching_options: {
    EN: 'fetching options',
    ID: '',
  },
  distribution_type: {
    EN: 'Distribution Type',
    ID: '',
  },
  view_spin: {
    EN: 'View Spin',
    ID: '',
  },
  add_spin: {
    EN: 'Add Spin',
    ID: '',
  },
  created_on: {
    EN: 'Created On',
    ID: 'Dibuat pada',
  },
  view_add_rewards: {
    EN: 'View & Add Rewards',
    ID: '',
  },
  view_add_spins: {
    EN: 'View & Add Spins',
    ID: '',
  },
  view_add_spin_campaigns: {
    EN: 'View & Add Spin Campaigns',
    ID: '',
  },
  view_add_challenges: {
    EN: 'View & Add Challenges',
    ID: '',
  },
  view_add_challenge_campaigns: {
    EN: 'View & Add Challenge Campaigns',
    ID: '',
  },
  view_add_gamesui_config: {
    EN: 'GamesUI Config Update',
    ID: '',
  },
  url_can_not_be_empty: {
    EN: 'URL can not be empty',
    ID: '',
  },
  bitly_link_generator: {
    EN: 'Bitly Link Generator',
    ID: '',
  },
  url_to_shorten: {
    EN: 'Url to Shorten',
    ID: '',
  },
  generate_bitly_link: {
    EN: 'Generate Bitly Link',
    ID: '',
  },
  generated_bitly_url: {
    EN: 'Generated Bitly url',
    ID: '',
  },
  user_phone: {
    EN: 'User Phone',
    ID: '',
  },
  orders_placed: {
    EN: 'Orders Placed',
    ID: '',
  },
  shares_done: {
    EN: 'Shares Done',
    ID: '',
  },
  orders_delivered: {
    EN: 'Orders Delivered',
    ID: '',
  },
  orders_rto: {
    EN: 'Orders RTO',
    ID: '',
  },
  orders_returned: {
    EN: 'Orders Returned',
    ID: '',
  },
  group_id: {
    EN: 'Group ID',
    ID: '',
  },
  admin_comment: {
    EN: 'Admin Comment',
    ID: '',
  },
  product_info: {
    EN: 'Product Info',
    ID: '',
  },
  select_variation: {
    EN: 'Select Variation',
    ID: '',
  },
  this_pincode_is_not_serviceable_please_handle_it_outside_system: {
    EN: 'This pincode is not serviceable. Please handle it outside system',
    ID: '',
  },
  repl_details: {
    EN: 'REPL Details',
    ID: '',
  },
  repl_label: {
    EN: 'REPL Label',
    ID: '',
  },
  product_booking_error_try_again_with_valid_inputs: {
    EN: 'Product Booking Error. Try Again with valid inputs',
    ID: '',
  },
  comment_update_error: {
    EN: 'Comment update error',
    ID: '',
  },
  payment_date: {
    EN: 'Payment Date',
    ID: '',
  },
  select_reship_reason: {
    EN: 'Select Reship Reason',
    ID: '',
  },
  select_product_quantity: {
    EN: 'Select Product Quantity',
    ID: '',
  },
  initiate_reship: {
    EN: 'Initiate Reship',
    ID: '',
  },
  reship_product_details: {
    EN: 'Reship Product Details',
    ID: '',
  },
  reship_reason: {
    EN: 'Reship Reason',
    ID: '',
  },
  product_quantity: {
    EN: 'Product Quantity',
    ID: '',
  },
  update: {
    EN: 'Update',
    ID: '',
  },
  re_initiate_reship: {
    EN: 'Re initiate Reship',
    ID: '',
  },
  probable_rto: {
    EN: 'Probable RTO',
    ID: '',
  },
  exchange_expected_dispatch_date: {
    EN: 'Exchange Expected Dispatch Date',
    ID: '',
  },
  no_detail: {
    EN: 'No Detail',
    ID: '',
  },
  available_after_product_ship: {
    EN: 'Available after Product Ship',
    ID: '',
  },
  download_invoice: {
    EN: 'Download Invoice',
    ID: '',
  },
  no_date: {
    EN: 'No Date',
    ID: '',
  },
  name_not_found: {
    EN: 'Name not found',
    ID: '',
  },
  price_update_successfully: {
    EN: 'Price update successfully',
    ID: '',
  },
  updated_order_info: {
    EN: 'Updated order info',
    ID: '',
  },
  reseller_dispatch_date: {
    EN: 'Reseller Dispatch Date',
    ID: '',
  },
  invoice_supplier_to_reseller: {
    EN: 'Invoice Supplier TO Reseller',
    ID: '',
  },
  invoice_reseller_to_customer: {
    EN: 'Invoice Reseller TO Customer',
    ID: '',
  },
  variants_data: {
    EN: 'Variants Data',
    ID: '',
  },
  configuration: {
    EN: 'Configuration',
    ID: '',
  },
  variants: {
    EN: 'Variants',
    ID: '',
  },
  add_new: {
    EN: 'Add New',
    ID: '',
  },
  add_new_experiment: {
    EN: 'Add New Experiment',
    ID: '',
  },
  edit_experiment: {
    EN: 'Edit Experiment',
    ID: '',
  },
  experiment_info: {
    EN: 'Experiment Info',
    ID: '',
  },
  reference_file: {
    EN: 'Reference file',
    ID: '',
  },
  create_new_experiment: {
    EN: 'Create New Experiment',
    ID: '',
  },
  no_experiment_found: {
    EN: 'No Experiment found',
    ID: '',
  },
  edit_customer_bank_details: {
    EN: 'Edit Customer Bank Details',
    ID: '',
  },
  are_you_sure_want_to_verify_this_customer_bank_details: {
    EN: 'Are you sure want to verify this customer bank details?',
    ID: '',
  },
  account_name_is_required: {
    EN: 'Account Name is required',
    ID: '',
  },
  account_number_is_required: {
    EN: 'Account Number is required',
    ID: '',
  },
  ifsc_code_is_required: {
    EN: 'IFSC Code is required',
    ID: '',
  },
  bank_account_name: {
    EN: 'Bank Account Name',
    ID: '',
  },
  bank_account_number: {
    EN: 'Bank Account Number',
    ID: '',
  },
  bank_ifsc_code: {
    EN: 'Bank IFSC Code',
    ID: '',
  },
  account_name: {
    EN: 'Account Name',
    ID: '',
  },
  reseller_id: {
    EN: 'Reseller Id',
    ID: '',
  },
  email_updated: {
    EN: 'Email updated',
    ID: '',
  },
  reseller_edit: {
    EN: 'Reseller Edit',
    ID: '',
  },
  edit_email: {
    EN: 'Edit Email',
    ID: '',
  },
  bonus_details_week: {
    EN: 'Bonus Details Week',
    ID: '',
  },
  bonus_earned: {
    EN: 'Bonus Earned',
    ID: '',
  },
  bonus_paid: {
    EN: 'Bonus Paid',
    ID: '',
  },
  outstanding: {
    EN: 'Outstanding',
    ID: '',
  },
  order_link: {
    EN: 'Order Link',
    ID: '',
  },
  unconfirmed: {
    EN: 'Unconfirmed',
    ID: '',
  },
  reseller_bonus_details: {
    EN: 'Reseller Bonus Details',
    ID: '',
  },
  fetch_reseller_bonus_details: {
    EN: 'Fetch Reseller Bonus Details',
    ID: '',
  },
  total_bonus_earned: {
    EN: 'Total Bonus Earned',
    ID: '',
  },
  total_bonus_paid: {
    EN: 'Total Bonus Paid',
    ID: '',
  },
  total_bonus_outstanding: {
    EN: 'Total Bonus Outstanding',
    ID: '',
  },
  current_week: {
    EN: 'Current Week',
    ID: '',
  },
  total_orders: {
    EN: 'Total orders',
    ID: 'Total Pesanan',
  },
  verified_orders_value: {
    EN: 'Verified Orders Value',
    ID: '',
  },
  pending_orders_value: {
    EN: 'Pending Orders Value',
    ID: '',
  },
  bonus_name_type: {
    EN: 'Bonus Name ( Type )',
    ID: '',
  },
  past_weeks: {
    EN: 'Past Weeks',
    ID: '',
  },
  week_duration: {
    EN: 'Week Duration',
    ID: '',
  },
  no_past_bonus: {
    EN: 'No Past bonus',
    ID: '',
  },
  bonus: {
    EN: 'Bonus',
    ID: 'Bonus',
  },
  bonus_details_fetching_error: {
    EN: 'Bonus Details Fetching Error',
    ID: '',
  },
  outstanding_payments: {
    EN: 'Outstanding Payments',
    ID: '',
  },
  total_margin: {
    EN: 'Total Margin',
    ID: '',
  },
  total_refund: {
    EN: 'Total Refund',
    ID: '',
  },
  total_compensation: {
    EN: 'Total Compensation',
    ID: '',
  },
  margin: {
    EN: 'Margin',
    ID: 'Keuntungan',
  },
  compensation: {
    EN: 'Compensation',
    ID: '',
  },
  processed_payments: {
    EN: 'Processed Payments',
    ID: '',
  },
  no_payment_processed_yet: {
    EN: 'No Payment Processed Yet',
    ID: '',
  },
  payment_timeline: {
    EN: 'Payment Timeline',
    ID: '',
  },
  date_time: {
    EN: 'Date/Time',
    ID: '',
  },
  payments_timeline_not_found: {
    EN: 'Payments Timeline not found',
    ID: '',
  },
  payments_timeline: {
    EN: 'Payments Timeline',
    ID: '',
  },
  reseller_payment_details: {
    EN: 'Reseller Payment Details',
    ID: '',
  },
  fetch_reseller_payment_details: {
    EN: 'Fetch Reseller Payment Details',
    ID: '',
  },
  payment_files: {
    EN: 'Payment Files',
    ID: '',
  },
  response_files: {
    EN: 'Response Files',
    ID: '',
  },
  output_processed_date: {
    EN: 'Output Processed Date',
    ID: '',
  },
  response_upload_date: {
    EN: 'Response Upload Date',
    ID: '',
  },
  created_date: {
    EN: 'Created Date',
    ID: '',
  },
  transaction_time: {
    EN: 'Transaction Time',
    ID: '',
  },
  file_upload_success: {
    EN: 'File Upload Success',
    ID: '',
  },
  reseller_credits_file_upload: {
    EN: 'Reseller Credits File Upload',
    ID: '',
  },
  reseller_wallet_history: {
    EN: 'Reseller Wallet History',
    ID: '',
  },
  fetch_reseller_wallet_history: {
    EN: 'Fetch Reseller Wallet History',
    ID: '',
  },
  current_balance: {
    EN: 'Current Balance',
    ID: '',
  },
  both_reseller_cant_be_same: {
    EN: 'Both reseller can\'t be same',
    ID: '',
  },
  add_new_referral: {
    EN: 'Add New Referral',
    ID: '',
  },
  referral_plan_details: {
    EN: 'Referral Plan Details',
    ID: '',
  },
  no_details_found: {
    EN: 'No Details Found',
    ID: 'Tidak ada informasi ditemukan',
  },
  referred_id: {
    EN: 'Referred Id',
    ID: '',
  },
  when: {
    EN: 'When',
    ID: 'Kapan',
  },
  over_all_details: {
    EN: 'Over All Details',
    ID: '',
  },
  orders_details: {
    EN: 'Orders Details',
    ID: '',
  },
  payments_details: {
    EN: 'Payments Details',
    ID: '',
  },
  referral_earning_id: {
    EN: 'Referral Earning Id',
    ID: '',
  },
  plan_details: {
    EN: 'Plan Details',
    ID: '',
  },
  order_value: {
    EN: 'Order Value',
    ID: '',
  },
  earned: {
    EN: 'Earned',
    ID: '',
  },
  earned_amount: {
    EN: 'Earned Amount',
    ID: '',
  },
  no_orders_found: {
    EN: 'No Orders Found',
    ID: '',
  },
  paid_amount: {
    EN: 'Paid Amount',
    ID: '',
  },
  transaction_id: {
    EN: 'Transaction Id',
    ID: '',
  },
  transfer_date: {
    EN: 'Transfer Date',
    ID: '',
  },
  payment_code: {
    EN: 'Payment Code',
    ID: '',
  },
  payment_file_id: {
    EN: 'Payment File Id',
    ID: '',
  },
  schedule_date: {
    EN: 'Scheduled Date',
    ID: '',
  },
  schedule_time: {
    EN: 'Scheduled Time',
    ID: '',
  },
  no_payment_details_found: {
    EN: 'No Payment Details Found',
    ID: '',
  },
  referral_validation: {
    EN: 'Referral Validation',
    ID: '',
  },
  are_you_sure_this_referral_is_a_fraud: {
    EN: 'Are you sure this referral is a fraud ?',
    ID: '',
  },
  yes_its_fraud: {
    EN: 'Yes, Its\'s fraud',
    ID: '',
  },
  no_not_fraud: {
    EN: 'No, Not Fraud',
    ID: '',
  },
  select_fraud_reason: {
    EN: 'Select fraud reason',
    ID: '',
  },
  enter_fraud_detail: {
    EN: 'Enter fraud detail',
    ID: '',
  },
  referral_fraud: {
    EN: 'Referral Fraud',
    ID: '',
  },
  are_you_sure_this_reseller_referral_is_fraud: {
    EN: 'Are you sure this reseller referral is fraud?',
    ID: '',
  },
  fraud_reason: {
    EN: 'Fraud Reason',
    ID: '',
  },
  fraud_detail: {
    EN: 'Fraud Detail',
    ID: '',
  },
  validate: {
    EN: 'Validate',
    ID: '',
  },
  invalidate: {
    EN: 'Invalidate',
    ID: '',
  },
  not_found: {
    EN: 'Not Found',
    ID: '',
  },
  reseller_info_updated: {
    EN: 'Reseller Info Updated',
    ID: '',
  },
  block: {
    EN: 'Block',
    ID: '',
  },
  unflag: {
    EN: 'Unflag',
    ID: '',
  },
  verify_reseller: {
    EN: 'Verify Reseller',
    ID: '',
  },
  are_you_sure_want_to_block_user_forever: {
    EN: 'Are you sure want to block user forever?',
    ID: '',
  },
  are_you_sure_the_user_is_a_reseller: {
    EN: 'Are you sure the user is a Reseller?',
    ID: '',
  },
  verification_confirmation: {
    EN: 'Verification Confirmation',
    ID: '',
  },
  should_be_in_lower_case_without_any_spaces: {
    EN: 'Should be in lower case without any spaces.',
    ID: '',
  },
  can_be_maximum_30_character_long: {
    EN: 'Can be maximum 30 character long.',
    ID: '',
  },
  cannot_be_empty_or_contain_special_character: {
    EN: 'Cannot be empty or contain special character.',
    ID: '',
  },
  notification_body_is_required: {
    EN: 'Notification Body is required',
    ID: '',
  },
  notification_title_is_required: {
    EN: 'Notification Title is required',
    ID: '',
  },
  transaction_type_is_required: {
    EN: 'Transaction Type is required',
    ID: '',
  },
  max_amount_can_not_be_greater_than_10000: {
    EN: 'Max amount can not be greater than 10000',
    ID: '',
  },
  wallet_type_is_required: {
    EN: 'Wallet Type is required',
    ID: '',
  },
  wallet_template: {
    EN: 'Wallet Template',
    ID: '',
  },
  template_identifier: {
    EN: 'Template Identifier',
    ID: '',
  },
  template_message: {
    EN: 'Template Message',
    ID: '',
  },
  notification_title: {
    EN: 'Notification Title',
    ID: '',
  },
  notification_body: {
    EN: 'Notification Body',
    ID: '',
  },
  transaction_type: {
    EN: 'Transaction Type',
    ID: '',
  },
  wallet_type: {
    EN: 'Wallet Type',
    ID: '',
  },
  wallet_type_credits: {
    EN: 'Credits',
    ID: '',
  },
  wallet_type_meesho_coin: {
    EN: 'Meesho Coin',
    ID: '',
  },
  wallet_type_meesho_balance: {
    EN: 'Meesho Balance',
    ID: '',
  },
  on_hold: {
    EN: 'On Hold',
    ID: '',
  },
  resolved: {
    EN: 'Resolved',
    ID: '',
  },
  closed: {
    EN: 'Closed',
    ID: '',
  },
  select_transaction_type: {
    EN: 'Select Transaction Type',
    ID: '',
  },
  max_amount: {
    EN: 'Max Amount',
    ID: '',
  },
  successfully_added_template: {
    EN: 'Successfully Added template',
    ID: '',
  },
  reseller_actions: {
    EN: 'Reseller Actions',
    ID: '',
  },
  payment_details: {
    EN: 'Payment Details',
    ID: '',
  },
  bank_details: {
    EN: 'Bank Details',
    ID: '',
  },
  bonus_details: {
    EN: 'Bonus Details',
    ID: '',
  },
  verification: {
    EN: 'Verification',
    ID: '',
  },
  referral: {
    EN: 'Referral',
    ID: '',
  },
  all_referrals: {
    EN: 'All Referrals',
    ID: '',
  },
  edit_details: {
    EN: 'Edit Details',
    ID: '',
  },
  wallet_history: {
    EN: 'Wallet History',
    ID: '',
  },
  add_wallet_template: {
    EN: 'Add Wallet Template',
    ID: '',
  },
  referrals: {
    EN: 'Referrals',
    ID: 'Referal',
  },
  reseller_referrals: {
    EN: 'Reseller Referrals',
    ID: '',
  },
  filter_referrals: {
    EN: 'Filter Referrals',
    ID: '',
  },
  fraud: {
    EN: 'Fraud',
    ID: '',
  },
  not_fraud: {
    EN: 'Not Fraud',
    ID: '',
  },
  invalid: {
    EN: 'Invalid',
    ID: '',
  },
  no_reseller_referrals_found: {
    EN: 'No Reseller Referrals Found',
    ID: '',
  },
  referring_id: {
    EN: 'Referring Id',
    ID: '',
  },
  referred_by: {
    EN: 'Referred By',
    ID: '',
  },
  referral_plan_name: {
    EN: 'Referral Plan Name',
    ID: '',
  },
  is_it_fraud: {
    EN: 'Is it Fraud?',
    ID: '',
  },
  invalidated_at: {
    EN: 'Invalidated At',
    ID: '',
  },
  reseller_referral_details: {
    EN: 'Reseller Referral Details',
    ID: '',
  },
  fetch_reseller_referral_details: {
    EN: 'Fetch Reseller Referral Details',
    ID: '',
  },
  no_of_referrals: {
    EN: '# of Referrals',
    ID: '',
  },
  total_gmv: {
    EN: 'Total GMV',
    ID: '',
  },
  total_commission: {
    EN: 'Total Commission',
    ID: 'Total Komisi',
  },
  referred_resellers_details: {
    EN: 'Referred Reseller\'s Details',
    ID: '',
  },
  no_referred_user_found: {
    EN: 'No Referred User Found',
    ID: '',
  },
  reseller_verification: {
    EN: 'Reseller Verification',
    ID: '',
  },
  no_users_found: {
    EN: 'No Users Found',
    ID: '',
  },
  end_date: {
    EN: 'End Date',
    ID: '',
  },
  tier: {
    EN: 'Tier',
    ID: '',
  },
  experiment: {
    EN: 'Experiment',
    ID: '',
  },
  gmv: {
    EN: 'GMV',
    ID: '',
  },
  pending_cash_back: {
    EN: 'Pending Cash Back',
    ID: '',
  },
  confirmed_cash_back: {
    EN: 'Confirmed Cash Back',
    ID: '',
  },
  paid_cash_back: {
    EN: 'Paid Cash Back',
    ID: '',
  },
  uploaded_on: {
    EN: 'Uploaded on',
    ID: '',
  },
  previous_history: {
    EN: 'Previous history',
    ID: '',
  },
  review_and_ratings: {
    EN: 'Review and Ratings',
    ID: '',
  },
  review_videos: {
    EN: 'Review Videos',
    ID: '',
  },
  review_status_text: {
    EN: 'Review Status - Text',
    ID: '',
  },
  review_status_image: {
    EN: 'Review Status - Image',
    ID: '',
  },
  date_of_review: {
    EN: 'Date of Review',
    ID: '',
  },
  language: {
    EN: 'Language',
    ID: 'Language',
  },
  language_required: {
    EN: 'Language Required',
    ID: '',
  },
  create_template: {
    EN: 'Create Template',
    ID: '',
  },
  create_whatsapp_template: {
    EN: 'Create Whatsapp Template',
    ID: '',
  },
  enter_template_name: {
    EN: 'Enter Template name',
    ID: '',
  },
  enter_template_title: {
    EN: 'Enter Template title (with variable)',
    ID: 'Masukkan judul design (dengan konsep)',
  },
  english: {
    EN: 'English',
    ID: '',
  },
  regional: {
    EN: 'Regional',
    ID: '',
  },
  enter_custom_message_body_with_variables: {
    EN: 'Enter custom message body (with variables)',
    ID: '',
  },
  save_template: {
    EN: 'Save Template',
    ID: '',
  },
  view_sample: {
    EN: 'View Sample',
    ID: '',
  },
  actual_character_count_is_variable_dependant: {
    EN: 'Actual Character count is variable dependant',
    ID: '',
  },
  call_to_required: {
    EN: 'Call to Required',
    ID: '',
  },
  id_required: {
    EN: 'Id Required',
    ID: '',
  },
  cant_be_more_than_15_characters: {
    EN: 'Can\'t be more than 15 characters',
    ID: '',
  },
  call_purpose_required: {
    EN: 'Call Purpose Required',
    ID: '',
  },
  reseller_supplier: {
    EN: 'Reseller/Supplier',
    ID: '',
  },
  your_phone_mobile_number: {
    EN: 'Your Phone/Mobile Number',
    ID: '',
  },
  call_purpose: {
    EN: 'Call Purpose',
    ID: '',
  },
  select_purpose: {
    EN: 'Select Purpose',
    ID: '',
  },
  connect_call: {
    EN: 'Connect call',
    ID: '',
  },
  success_please_wait_while_we_are_making_call: {
    EN: 'Success: Please wait while we are making call !',
    ID: '',
  },
  how_to_enter_your_number: {
    EN: 'How to enter your Number',
    ID: '',
  },
  if_using_mobile_enter_10_digit_number_only_9xxxx9xxxx: {
    EN: 'If using mobile - Enter : 10 digit number only 9XXXX9XXXX',
    ID: '',
  },
  if_using_landline_enter_number_with_std_code_0802xxxxxx: {
    EN: 'If using landline - Enter : Number with STD Code 0802XXXXXX',
    ID: '',
  },
  you_can_only_make_calls_to_and_from_indian_numbers_only: {
    EN: 'You can only make calls to and from Indian Numbers only',
    ID: '',
  },
  error_fetching_templates_list: {
    EN: 'Error fetching templates list.',
    ID: '',
  },
  error: {
    EN: 'Error',
    ID: '',
  },
  errors: {
    EN: 'Errors',
    ID: '',
  },
  send_sms: {
    EN: 'Send SMS',
    ID: '',
  },
  select_template: {
    EN: 'Select Template',
    ID: '',
  },
  select_type: {
    EN: 'Select Type',
    ID: '',
  },
  upload_ids_variables_csv: {
    EN: 'Upload IDs, Variables CSV',
    ID: '',
  },
  sample_download: {
    EN: 'Sample Download',
    ID: '',
  },
  download: {
    EN: 'Download',
    ID: '',
  },
  sms_content: {
    EN: 'SMS Content',
    ID: '',
  },
  sms_senderid: {
    EN: 'SMS SenderId',
    ID: '',
  },
  schedule_sms: {
    EN: 'Schedule SMS',
    ID: '',
  },
  send_now: {
    EN: 'Send Now',
    ID: '',
  },
  send_later: {
    EN: 'Send Later',
    ID: '',
  },
  select_date_and_time: {
    EN: 'Select date and time',
    ID: '',
  },
  name_of_campaign: {
    EN: 'Name of Campaign',
    ID: '',
  },
  international_supplier_payment_details: {
    EN: 'International Supplier Payment Details',
    ID: '',
  },
  supplier_payment_details: {
    EN: 'Supplier Payment Details',
    ID: '',
  },
  international_supplier_payment: {
    EN: 'International Supplier Payment',
    ID: '',
  },
  international_supplier_details: {
    EN: 'International Supplier Details',
    ID: '',
  },
  ior: {
    EN: 'IOR',
    ID: '',
  },
  eor: {
    EN: 'EOR',
    ID: '',
  },
  manifestation_stage: {
    EN: 'Manifestation Stage',
    ID: '',
  },
  select_manifestation_stage: {
    EN: 'Select Manifestation Stage',
    ID: '',
  },
  ordered: {
    EN: 'Ordered',
    ID: '',
  },
  assigned: {
    EN: 'Assigned',
    ID: '',
  },
  return_phone: {
    EN: 'Return Phone',
    ID: '',
  },
  auto_inventory_update_on_cancellation_return: {
    EN: 'Auto Inventory Update on Cancellation/Return?',
    ID: '',
  },
  lianlianpay_account_exists: {
    EN: 'LianLianPay Account Exists?',
    ID: '',
  },
  return_address: {
    EN: 'Return Address',
    ID: '',
  },
  pin_code: {
    EN: 'Pin Code',
    ID: '',
  },
  international_banner: {
    EN: 'International Banner',
    ID: '',
  },
  gold_enable: {
    EN: 'Gold Enable',
    ID: '',
  },
  logistic_fee_enabled: {
    EN: 'Logistic Fee Enable',
    ID: '',
  },
  gold_dispatch_delta: {
    EN: 'Gold Dispatch Delta',
    ID: '',
  },
  TRUE: {
    EN: 'TRUE',
    ID: '',
  },
  FALSE: {
    EN: 'FALSE',
    ID: '',
  },
  select_from_below: {
    EN: 'Select from below',
    ID: '',
  },
  no_attributes_present_right_now: {
    EN: 'No attributes present right now',
    ID: '',
  },
  is_this_an_internationally_fulfilled_account: {
    EN: 'Is this an Internationally Fulfilled Account?',
    ID: '',
  },
  gstin: {
    EN: 'GSTIN',
    ID: 'NPWP',
  },
  enrolment_number: {
    EN: 'Enrolment Number',
    ID: '',
  },
  supplier_gst_no: {
    EN: 'Supplier GST No.',
    ID: 'Supplier NPWP No',
  },
  supplier_enrolment_no: {
    EN: 'Supplier Enrolment No.',
    ID: '',
  },
  gst_certificate: {
    EN: 'Gst Certificate',
    ID: 'NPWP Certificate',
  },
  supplier_pan: {
    EN: 'Supplier PAN',
    ID: 'Supplier KTP',
  },
  pan_card: {
    EN: 'Pan Card',
    ID: 'KTP Card',
  },
  only_activate: {
    EN: 'Only Activate',
    ID: '',
  },
  inputs_missing_please_check: {
    EN: 'Inputs Missing. Please check',
    ID: '',
  },
  please_check_the_value_type: {
    EN: 'Please check the value type',
    ID: '',
  },
  default_value_should_be_one_among_the_custom_values: {
    EN: 'Default Value should be one among the custom values',
    ID: '',
  },
  custom_value_field_cannot_be_empty: {
    EN: 'Custom value field cannot be empty',
    ID: '',
  },
  attribute_added_successfully: {
    EN: 'Attribute added successfully',
    ID: '',
  },
  add_attributes: {
    EN: 'Add Attributes',
    ID: '',
  },
  add_supplier_attributes: {
    EN: 'Add Supplier Attributes',
    ID: '',
  },
  attribute_name: {
    EN: 'Attribute Name',
    ID: '',
  },
  attribute_key: {
    EN: 'Attribute Key',
    ID: '',
  },
  attribute_value_type: {
    EN: 'Attribute Value Type',
    ID: '',
  },
  string: {
    EN: 'String',
    ID: '',
  },
  integer: {
    EN: 'Integer',
    ID: '',
  },
  boolean: {
    EN: 'Boolean',
    ID: '',
  },
  float: {
    EN: 'Float',
    ID: '',
  },
  double: {
    EN: 'Double',
    ID: '',
  },
  custom: {
    EN: 'Custom',
    ID: '',
  },
  attribute_default_value: {
    EN: 'Attribute Default Value',
    ID: '',
  },
  add_more_custom_values: {
    EN: 'Add More Custom Values',
    ID: '',
  },
  categories_fetch_error_refresh_page: {
    EN: 'Categories fetch error. Refresh page',
    ID: '',
  },
  attributes_fetch_error_refresh_page: {
    EN: 'Attributes fetch error. Refresh page',
    ID: '',
  },
  executives_fetch_error_refresh_page: {
    EN: 'Executives fetch error. Refresh page',
    ID: '',
  },
  supplier_fetch_error_refresh_page: {
    EN: 'Supplier fetch error. Refresh page',
    ID: '',
  },
  supplier_update_error_refresh_page: {
    EN: 'Supplier update error. Refresh page',
    ID: '',
  },
  supplier_details_updated_successfully: {
    EN: 'Supplier details updated successfully',
    ID: 'Supplier details updated successfully',
  },
  input_missing_check_inputs: {
    EN: 'Input Missing. Check Inputs',
    ID: '',
  },
  supplier_inventory_update_dashboard: {
    EN: 'Supplier Inventory Update Dashboard',
    ID: '',
  },
  supplier_inventory_update_file: {
    EN: 'Supplier Inventory Update File',
    ID: '',
  },
  supplier_identifier: {
    EN: 'Supplier Identifier',
    ID: '',
  },
  variation_id: {
    EN: 'Variation Id',
    ID: '',
  },
  inventory_count: {
    EN: 'Inventory Count',
    ID: '',
  },
  penalty_date: {
    EN: 'Penalty Date',
    ID: '',
  },
  delay_fine: {
    EN: 'Delay Fine',
    ID: '',
  },
  cancellation_fine: {
    EN: 'Cancellation Fine',
    ID: '',
  },
  total_fine: {
    EN: 'Total Fine',
    ID: '',
  },
  fine_deducted: {
    EN: 'Fine Deducted',
    ID: '',
  },
  fine_deduction_date: {
    EN: 'Fine Deduction Date',
    ID: '',
  },
  penalty_dashboard: {
    EN: 'Penalty Dashboard',
    ID: '',
  },
  download_demo_excel: {
    EN: 'Download Demo Excel',
    ID: '',
  },
  penalties: {
    EN: 'Penalties',
    ID: '',
  },
  catalogs_added_to_collections_successfully: {
    EN: 'Catalogs added to collections successfully',
    ID: '',
  },
  input_not_valid: {
    EN: 'Input not valid',
    ID: '',
  },
  add_catalogs_to_collections: {
    EN: 'Add Catalogs To Collections',
    ID: '',
  },
  paste_catalog_id_and_collection_id_here: {
    EN: 'Paste Catalog Id and Collection Id Here',
    ID: '',
  },
  catalog_updated_successfully: {
    EN: 'Catalog updated successfully',
    ID: '',
  },
  paste_catalogs_ids_here: {
    EN: 'Paste Catalogs Id\'s Here',
    ID: '',
  },
  move_catalogs_to_hidden_collection: {
    EN: 'Move catalogs to hidden collection',
    ID: '',
  },
  move_catalogs_to_quality_hidden_collection: {
    EN: 'Move catalogs to quality hidden collection',
    ID: '',
  },
  remove_catalogs_to_collections: {
    EN: 'Remove Catalogs To Collections',
    ID: '',
  },
  cancel_date_updated_successfully: {
    EN: 'Cancel Date Updated successfully',
    ID: '',
  },
  length_5000_not_allowed_at_a_time: {
    EN: 'Length > 5000 not allowed at a time',
    ID: '',
  },
  invalid_data_at_index: {
    EN: 'Invalid data at index',
    ID: '',
  },
  sla_date_not_valid_at_index: {
    EN: 'SLA Date not valid at index',
    ID: '',
  },
  sub_order_cancel_date_update: {
    EN: 'Sub Order Cancel Date Update',
    ID: '',
  },
  paste_suborder_nos_and_date_here: {
    EN: 'Paste Suborder No\'s and Date Here',
    ID: '',
  },
  sla_update: {
    EN: 'SLA Update',
    ID: '',
  },
  sla_updated_successfully: {
    EN: 'SLA Updated successfully',
    ID: '',
  },
  sub_order_sla_update: {
    EN: 'Sub Order SLA Update',
    ID: '',
  },
  price_deactivation: {
    EN: 'Price Deactivation',
    ID: '',
  },
  net_marketing_fees: {
    EN: 'Net Marketing Fees',
    ID: '',
  },
  cancellation_fees: {
    EN: 'Cancellation Fees',
    ID: '',
  },
  sum_of_igst: {
    EN: 'Sum of IGST',
    ID: '',
  },
  sum_of_cgst: {
    EN: 'Sum of CGST',
    ID: '',
  },
  sum_of_sgst: {
    EN: 'Sum of SGST',
    ID: '',
  },
  supplier_registered_name: {
    EN: 'Supplier Registered Name',
    ID: '',
  },
  invoice_no: {
    EN: 'Invoice No',
    ID: '',
  },
  invoice_date: {
    EN: 'Invoice Date',
    ID: '',
  },
  edit_trend: {
    EN: 'Edit Trend',
    ID: '',
  },
  add_trend: {
    EN: 'Add Trend',
    ID: '',
  },
  enter_name: {
    EN: 'Enter Name',
    ID: '',
  },
  add_new_trend: {
    EN: 'Add New Trend',
    ID: '',
  },
  error_updating_video_status: {
    EN: 'Error updating video status',
    ID: '',
  },
  video_influencer_review: {
    EN: 'Video Influencer Review',
    ID: '',
  },
  review_status_videos: {
    EN: 'Review Status - Videos:',
    ID: '',
  },
  date_of_upload: {
    EN: 'Date of Upload',
    ID: '',
  },
  review_video_influencer_videos: {
    EN: 'Review Video Influencer Videos',
    ID: '',
  },
  product_details: {
    EN: 'Product details',
    ID: '',
  },
  submitted_video: {
    EN: 'Submitted Video',
    ID: '',
  },
  moderation_details: {
    EN: 'Moderation Details',
    ID: '',
  },
  change_status: {
    EN: 'Change Status',
    ID: '',
  },
  enter_image_url: {
    EN: 'Enter Image URL',
    ID: '',
  },
  search_by_username_or_number: {
    EN: 'Search by User  Name or Number',
    ID: 'Cari dengan Nama Pengguna atau Nomor',
  },
  search_by_url: {
    EN: 'Search by URL',
    ID: '',
  },
  search_by_ticket_id: {
    EN: 'Search by Ticket Id',
    ID: 'Cari dengan ID Tiket',
  },
  ticket_id: {
    EN: 'Ticket Id',
    ID: '',
  },
  upload_search: {
    EN: 'Upload & Search',
    ID: '',
  },
  exact_matches: {
    EN: 'Exact Matches',
    ID: '',
  },
  similar_catalogs: {
    EN: 'Similar Catalogs',
    ID: '',
  },
  widget_group_id_search: {
    EN: 'Widget Group ID Search',
    ID: '',
  },
  search: {
    EN: 'Search',
    ID: '',
  },
  widget_group_id: {
    EN: 'Widget Group ID',
    ID: '',
  },
  widget_group_title: {
    EN: 'Widget Group Title',
    ID: '',
  },
  position: {
    EN: 'Position',
    ID: '',
  },
  select_audiences: {
    EN: 'Select Audiences',
    ID: '',
  },
  add_new_landing_page: {
    EN: 'Add New Landing Page',
    ID: '',
  },
  edit_landing_page: {
    EN: 'Edit Landing Page',
    ID: '',
  },
  sub_title: {
    EN: 'Sub Title',
    ID: '',
  },
  landing_page_widget_groups: {
    EN: 'Landing Page Widget Groups',
    ID: '',
  },
  add_landing_page: {
    EN: 'Add Landing Page',
    ID: '',
  },
  no_landing_page_found: {
    EN: 'No Landing page found',
    ID: '',
  },
  invalid_image_aspect_ratio: {
    EN: 'Invalid Image Aspect Ratio',
    ID: '',
  },
  single_screen_data: {
    EN: 'Single Screen Data',
    ID: '',
  },
  widget_screen: {
    EN: 'Widget Screen',
    ID: '',
  },
  image_width: {
    EN: 'Image Width',
    ID: '',
  },
  are_you_sure_want_to_delete_this_widget: {
    EN: 'Are you sure want to delete this widget?',
    ID: '',
  },
  yes_delete: {
    EN: 'Yes, Delete!',
    ID: '',
  },
  landing_page_id: {
    EN: 'Landing Page ID',
    ID: '',
  },
  landing_page_title: {
    EN: 'Landing Page Title',
    ID: '',
  },
  image_aspect_ratio: {
    EN: 'Image Aspect Ratio',
    ID: '',
  },
  destination: {
    EN: 'Destination',
    ID: '',
  },
  location_screen: {
    EN: 'Location Screen',
    ID: '',
  },
  select_location_screen: {
    EN: 'Select location Screen',
    ID: '',
  },
  are_you_sure_want_to_delete_this_widget_group_location: {
    EN: 'Are you sure want to delete this widget group location?',
    ID: '',
  },
  no_location_added_for_this_group: {
    EN: 'No location Added for this group',
    ID: '',
  },
  locations: {
    EN: 'Locations',
    ID: '',
  },
  add_new_widget_group: {
    EN: 'Add New Widget Group',
    ID: '',
  },
  edit_widget: {
    EN: 'Edit Widget Group',
    ID: '',
  },
  widget_group_template: {
    EN: 'Widget Group Template',
    ID: '',
  },
  select_widget_group_template: {
    EN: 'Select Widget Group Template',
    ID: '',
  },
  select_background_color: {
    EN: 'Select Background Color',
    ID: '',
  },
  add_widget: {
    EN: 'Add Widget',
    ID: '',
  },
  dynamic_webview: {
    EN: 'Dynamic Webview',
    ID: '',
  },
  category_navigation_trees: {
    EN: 'Category Navigation',
    ID: '',
  },
  files_uploading: {
    EN: 'Files Uploading',
    ID: '',
  },
  access_denied_contact_your_team_lead_to_get_access: {
    EN: 'You don\'t have access to this feature. Please send email to itsupport@meesho.com with your manager approval to get access.',
    ID: '',
  },
  edit_file: {
    EN: 'Edit File',
    ID: '',
  },
  this_user_already_added_to_this_supplier: {
    EN: 'This user already added to this supplier',
    ID: '',
  },
  campaign_name_can_be_maximum_50_characters_long: {
    EN: 'Campaign Name can be maximum 50 characters long.',
    ID: '',
  },
  select_interval: {
    EN: 'Select interval',
    ID: '',
  },
  select_deep_link: {
    EN: 'Select Deep link',
    ID: '',
  },
  no_outstanding_payment: {
    EN: 'No Outstanding Payment',
    ID: '',
  },
  no_outstanding_payments: {
    EN: 'No Outstanding Payments',
    ID: '',
  },
  show_notification: {
    EN: 'Show Notification',
    ID: '',
  },
  valid_status: {
    EN: 'Valid Status',
    ID: '',
  },
  confirm_fraud: {
    EN: 'Confirm Fraud',
    ID: '',
  },
  reseller_search: {
    EN: 'Reseller Search',
    ID: '',
  },
  name_of_the_campaign: {
    EN: 'Name of the Campaign',
    ID: '',
  },
  select_ior: {
    EN: 'Select IOR',
    ID: '',
  },
  select_eor: {
    EN: 'Select EOR',
    ID: '',
  },
  use_submitted_images: {
    EN: 'User Submitted images',
    ID: '',
  },
  num: {
    EN: 'No.',
    ID: '',
  },
  moderated: {
    EN: 'Moderated',
    ID: '',
  },
  details: {
    EN: 'Details',
    ID: '',
  },

  review_user_submitted_images: {
    EN: 'Review User submitted Images',
    ID: '',
  },
  confirm_changes: {
    EN: 'Confirm changes',
    ID: '',
  },
  bulk_cod_verification: {
    EN: 'Bulk COD Verification',
    ID: '',
  },
  failed_to_fetch_deals: {
    EN: 'Failed to fetch Deals',
    ID: '',
  },
  dynamic_section: {
    EN: 'Dynamic Section',
    ID: '',
  },
  will_appear_once_page_is_successfully_created: {
    EN: 'will appear once page is successfully created',
    ID: '',
  },
  section_id: {
    EN: 'Section Id',
    ID: '',
  },
  color: {
    EN: 'Color',
    ID: '',
  },
  remove_section: {
    EN: 'Remove Section',
    ID: '',
  },
  leaderboard_section: {
    EN: 'Leaderboard Section',
    ID: '',
  },
  leaderboard_title: {
    EN: 'Leaderboard title',
    ID: '',
  },
  leaderboard_visibility_type: {
    EN: 'LeaderBoard visibility type',
    ID: '',
  },
  leaderboard_heading: {
    EN: 'Leader Board Heading',
    ID: '',
  },
  static_section_editor: {
    EN: 'Static Section Editor',
    ID: '',
  },
  page_id: {
    EN: 'Page Id',
    ID: '',
  },
  new_webview_page: {
    EN: 'New Webview Page',
    ID: '',
  },
  page_slug: {
    EN: 'Page Slug',
    ID: '',
  },
  page_title: {
    EN: 'Page Title',
    ID: '',
  },
  select_new_section: {
    EN: 'Select New Section',
    ID: '',
  },
  add_section: {
    EN: 'Add Section',
    ID: '',
  },
  update_page: {
    EN: 'Update Page',
    ID: '',
  },
  save_page: {
    EN: 'Save Page',
    ID: '',
  },
  new_page: {
    EN: 'New Page',
    ID: '',
  },
  live_pages: {
    EN: 'Live Pages',
    ID: '',
  },
  view_edit: {
    EN: 'View / Edit',
    ID: '',
  },
  file_uploaded_successfully: {
    EN: 'File Uploaded Successfully',
    ID: '',
  },
  discount_type: {
    EN: 'Discount Type',
    ID: '',
  },
  discount_value: {
    EN: 'Discount Value',
    ID: '',
  },
  max_discount: {
    EN: 'Max Discount',
    ID: '',
  },
  max_apply_count: {
    EN: 'Max Apply Count',
    ID: '',
  },
  expiry_days: {
    EN: 'Expiry Days',
    ID: '',
  },
  extended_expiry_days: {
    EN: 'Extended Expiry Days',
    ID: '',
  },
  scope: {
    EN: 'Scope',
    ID: '',
  },
  add_notification_detail: {
    EN: 'Add Notification Detail',
    ID: '',
  },
  account_managers: {
    EN: 'Account Managers',
    ID: '',
  },
  user_admin_password_reset: {
    EN: 'User / Admin Password Reset',
    ID: '',
  },
  catalog_name_not_found: {
    EN: 'Catalog Name not found',
    ID: '',
  },
  no_image: {
    EN: 'No Image',
    ID: '',
  },
  text_review_details: {
    EN: 'Text Review Details',
    ID: '',
  },
  image_review_details: {
    EN: 'Image Review Details',
    ID: '',
  },
  video_review_details: {
    EN: 'Video Review Details',
    ID: '',
  },
  review_user_submitted_videos: {
    EN: 'Review User submitted Videos',
    ID: '',
  },
  user_submitted_videos: {
    EN: 'User Submitted Videos',
    ID: '',
  },
  download_video: {
    EN: 'Download Video',
    ID: '',
  },
  upload_moderated_video: {
    EN: 'Upload Moderated Video',
    ID: '',
  },
  enter_custom_value: {
    EN: 'Enter Custom Value',
    ID: '',
  },
  supplier_attribute_info: {
    EN: 'Supplier Attribute Info',
    ID: '',
  },
  file_status: {
    EN: 'File Status',
    ID: '',
  },
  allotted: {
    EN: 'Allotted',
    ID: '',
  },
  not_allotted: {
    EN: 'Not Allotted',
    ID: '',
  },
  excel_upload: {
    EN: 'Excel Upload',
    ID: '',
  },
  please_select_sub_sub_category_id_first: {
    EN: 'Please select sub sub category id first',
    ID: '',
  },
  select_scale_id: {
    EN: 'Select scale Id',
    ID: '',
  },
  select_source: {
    EN: 'Select Source',
    ID: '',
  },
  internal: {
    EN: 'Internal',
    ID: '',
  },
  no_category_data_found: {
    EN: 'No category data found',
    ID: '',
  },
  please_select_mandatory_fields: {
    EN: 'Please select mandatory fields',
    ID: '',
  },
  download_template: {
    EN: 'Download Template',
    ID: '',
  },
  error_in_the_downloading_the_template: {
    EN: 'There was an error in downloading template. Please try again later.',
    ID: '',
  },
  the_excel_file_is_uploaded_successfully: {
    EN: 'The excel file is uploaded successfully',
    ID: '',
  },
  there_was_an_error_in_uploading_the_excel: {
    EN: 'There was an error in uploading the excel. Please try again later.',
    ID: '',
  },
  please_select_supplier: {
    EN: 'Please select supplier',
    ID: '',
  },
  offer_audience_map: {
    EN: 'Offer Audience Map',
    ID: '',
  },
  add_audiences: {
    EN: 'Add Audiences',
    ID: '',
  },
  active: {
    EN: 'Active',
    ID: '',
  },
  no_audience_tagged_to_this_offer_id: {
    EN: 'No Audience Tagged to this Offer ID',
    ID: '',
  },
  activated_successfully: {
    EN: 'Activated successfully',
    ID: '',
  },
  deactivated_successfully: {
    EN: 'Deactivated successfully',
    ID: '',
  },
  csv_upload: {
    EN: 'CSV UPLOAD',
    ID: '',
  },
  kyc_status_warning: {
    EN: 'Supplier’s payment might get blocked if the updated KYC documents are unapproved. Make sure the details entered are correct',
    ID: '',
  },
  comment_is_required: {
    EN: 'Comment is required',
    ID: '',
  },
  bulk_ordering: {
    EN: 'Bulk Ordering',
    ID: '',
  },
  image_ab: {
    EN: 'Image A|B',
    ID: '',
  },
  tabular_view: {
    EN: 'Tabular View',
    ID: '',
  },
  group_view: {
    EN: 'Group View',
    ID: '',
  },
  file_id: {
    EN: 'File Id',
    ID: '',
  },
  reference_id: {
    EN: 'Reference Id',
    ID: '',
  },
  image_ab_file_upload: {
    EN: 'Image A|B File Upload',
    ID: '',
  },
  uploaded_image_ab_data: {
    EN: 'Uploaded Image A|B Data',
    ID: '',
  },
  activate_warning_message: {
    EN: 'Please note existing temporary deactivated products for this supplier will be permanently deactivated',
    ID: '',
  },
  yes_only_activate: {
    EN: 'Yes, Only Activate',
    ID: '',
  },
  rule_engine: {
    EN: 'Rule Engine',
    ID: '',
  },
  create_rule: {
    EN: 'Create Rule',
    ID: '',
  },
  failed_to_fetch_rule: {
    EN: 'Failed to fetch rule',
    ID: '',
  },
  no_rules_present: {
    EN: 'No rules present.',
    ID: '',
  },
  logic_is_required: {
    EN: 'Rule logic is required',
    ID: '',
  },
  validation_input_is_required: {
    EN: 'Rule validation input is required',
    ID: '',
  },
  validation_output_is_required: {
    EN: 'Rule validation output is required',
    ID: '',
  },
  rule_status_is_required: {
    EN: 'Rule status is required',
    ID: '',
  },
  input_value_is_not_a_valid_json: {
    EN: 'Input value is not a valid JSON',
    ID: '',
  },
  value_changed_please_test_the_rule_again: {
    EN: 'Value changed. Please test the rule again',
    ID: '',
  },
  label: {
    EN: 'Label',
    ID: '',
  },
  value: {
    EN: 'Value',
    ID: '',
  },
  inactive: {
    EN: 'Inactive',
    ID: '',
  },
  logic: {
    EN: 'Logic',
    ID: '',
  },
  validation_input: {
    EN: 'Validation Input',
    ID: '',
  },
  validation_output: {
    EN: 'Validation Output',
    ID: '',
  },
  test_rule: {
    EN: 'Test Rule',
    ID: '',
  },
  update_rule: {
    EN: 'Update Rule',
    ID: '',
  },
  test_validation_input: {
    EN: 'Test Validation Input',
    ID: '',
  },
  test_validation_output: {
    EN: 'Test Validation Output',
    ID: '',
  },
  cover_img_url: {
    EN: 'Cover Image Url',
    ID: '',
  },
  show_leaderboard_points: {
    EN: 'Show Leaderboard Points',
    ID: 'Tampilkan Poin Papan Peringkat',
  },
  clp_admin: {
    EN: 'CLP Admin',
    ID: '',
  },
  enter_clp_id: {
    EN: 'Enter CLP ID',
    ID: 'Enter CLP ID',
  },
  spins: {
    EN: 'Spins',
    ID: '',
  },
  referral_plan: {
    EN: 'Referral Plan',
    ID: '',
  },
  badge_group: {
    EN: 'Badge Group',
    ID: '',
  },
  all_referral_plans: {
    EN: 'All Referral Plans',
    ID: '',
  },
  all_badge_groups: {
    EN: 'All Badge Groups',
    ID: '',
  },
  approve_referrals: {
    EN: 'Approve Referrals',
    ID: '',
  },
  referral_home: {
    EN: 'Referral Home',
    ID: '',
  },
  create_badge_group: {
    EN: 'Create Badge Group',
    ID: '',
  },
  default_group_category: {
    EN: 'Default Group Category',
    ID: '',
  },
  default_badge_group: {
    EN: 'Default Badge Group',
    ID: '',
  },
  badges: {
    EN: 'Badges',
    ID: '',
  },
  default_referral_plan_id: {
    EN: 'Default referral plan id',
    ID: '',
  },
  create_plan: {
    EN: 'Create Plan',
    ID: '',
  },
  referrals_order_min: {
    EN: 'Referrals Order Min',
    ID: '',
  },
  referrals_order_max: {
    EN: 'Referrals Order Max',
    ID: '',
  },
  plan_tiers: {
    EN: 'Plan Tiers',
    ID: '',
  },
  badge_upgrade_criteria: {
    EN: 'Badge Upgrade Criteria',
    ID: '',
  },
  referrals_min: {
    EN: 'Referrals Min',
    ID: '',
  },
  referrals_max: {
    EN: 'Referrals Max',
    ID: '',
  },
  min_order: {
    EN: 'Min Order',
    ID: '',
  },
  criteria: {
    EN: 'Criteria',
    ID: '',
  },
  metadata_order_count: {
    EN: 'Metadata Order Count',
    ID: '',
  },
  months_period_start: {
    EN: 'Months Period Start',
    ID: '',
  },
  incentive: {
    EN: 'Incentive',
    ID: '',
  },
  incentive_metadata_type: {
    EN: 'Incentive Metadata Type',
    ID: '',
  },
  incentive_metadata_value: {
    EN: 'Incentive Metadata Value',
    ID: '',
  },
  max_incentive: {
    EN: 'Max Incentive',
    ID: '',
  },
  edit_badge_group: {
    EN: 'Edit Badge Group',
    ID: '',
  },
  referral_badge: {
    EN: 'Referral Badge',
    ID: '',
  },
  delete_badge: {
    EN: 'Delete Badge',
    ID: '',
  },
  add_new_badge: {
    EN: 'Add New Badge',
    ID: '',
  },
  edit_referral_plan: {
    EN: 'Edit Referral Plan',
    ID: '',
  },
  create_referral_plan: {
    EN: 'Create Referral Plan',
    ID: '',
  },
  show_plan_upgrade_criteria: {
    EN: 'Show Plan Upgrade Criteria',
    ID: '',
  },
  plan_upgrade_criteria: {
    EN: 'Plan Upgrade Criteria',
    ID: '',
  },
  referral_plan_tier: {
    EN: 'Referral Plan Tier',
    ID: '',
  },
  delete_tier: {
    EN: 'Delete Tier',
    ID: '',
  },
  add_new_referral_plan_tier: {
    EN: 'Add New Referral Plan Tier',
    ID: '',
  },
  referral_audience: {
    EN: 'Referral Audience',
    ID: '',
  },
  create_audience: {
    EN: 'Create Audience',
    ID: '',
  },
  audience_list: {
    EN: 'Audience List',
    ID: '',
  },
  audience_badge_map: {
    EN: 'Audience Badge Map',
    ID: '',
  },
  audience_badge_map_list: {
    EN: 'Audience Badge Map List',
    ID: '',
  },
  bulk_badge_group_update: {
    EN: 'Bulk Badge Group Update',
    ID: '',
  },
  audience_created_successfully: {
    EN: 'Audience created Successfully!',
    ID: '',
  },
  referrer: {
    EN: 'REFERRER',
    ID: '',
  },
  influencer: {
    EN: 'INFLUENCER',
    ID: '',
  },
  badge_group_id: {
    EN: 'Badge Group Id',
    ID: '',
  },
  badge_id: {
    EN: 'Badge Id',
    ID: '',
  },
  referral_plan_id: {
    EN: 'Referral Plan Id',
    ID: '',
  },
  validated_successfully: {
    EN: 'Validated successfully!',
    ID: '',
  },
  invalidated_successfully: {
    EN: 'Invalidated successfully!',
    ID: '',
  },
  ab_type: {
    EN: 'AB Type!',
    ID: '',
  },
  audience_badge_map_created_successfully: {
    EN: 'Audience badge map created successfully!',
    ID: '',
  },
  audience_validation: {
    EN: 'Audience Validation',
    ID: '',
  },
  are_you_sure: {
    EN: 'Are you sure?',
    ID: '',
  },
  referral_approval: {
    EN: 'Referral Approval',
    ID: '',
  },
  referral_badge_group_created_with_id: {
    EN: 'Referral Badge Group created with ID',
    ID: '',
  },
  referral_badge_group_updated_successfully: {
    EN: 'Referral Badge Group Updated Successfully!',
    ID: '',
  },
  referral_plan_is_created_with_id: {
    EN: 'Referral Plan is created with ID',
    ID: '',
  },
  referral_plan_updated_successfully: {
    EN: 'Referral Plan Updated Successfully!',
    ID: '',
  },
  name_can_be_maximum_100_characters_long: {
    EN: 'Name can be maximum 100 characters long!',
    ID: '',
  },
  min_order_is_not_valid: {
    EN: 'Min order is not valid!',
    ID: '',
  },
  referrals_min_is_required: {
    EN: 'Referrals min is required!',
    ID: '',
  },
  referrals_min_should_be_smaller_then_max: {
    EN: 'Referrals min should be smaller then max!',
    ID: '',
  },
  referrals_max_is_required: {
    EN: 'Referrals max is required!',
    ID: '',
  },
  referrals_max_should_be_greater_then_min: {
    EN: 'Referrals max should be greater then min!',
    ID: '',
  },
  max_100_chars_long: {
    EN: 'Max 100 chars. long!',
    ID: '',
  },
  not_valid: {
    EN: 'Not valid',
    ID: '',
  },
  default_group_category_is_required: {
    EN: 'Default Group category is required!',
    ID: '',
  },
  user_type_is_required: {
    EN: 'User type is required!',
    ID: '',
  },
  invalid_file_type: {
    EN: 'Invalid file type!',
    ID: '',
  },
  referral_audience_list: {
    EN: 'Referral Audience List',
    ID: '',
  },
  approved_successfully: {
    EN: 'Approved Successfully!',
    ID: '',
  },
  rejected_successfully: {
    EN: 'Rejected Successfully!',
    ID: '',
  },
  first_n_order: {
    EN: 'FIRST N ORDER',
    ID: '',
  },
  bulk_order: {
    EN: 'BULK ORDER',
    ID: '',
  },
  referral_badge_level_plan: {
    EN: 'REFERRAL BADGE LEVEL PLAN',
    ID: '',
  },
  flat: {
    EN: 'FLAT',
    ID: '',
  },
  percentage: {
    EN: 'PERCENTAGE',
    ID: '',
  },
  add_filter_group: {
    EN: 'Add Filter Group',
    ID: '',
  },
  create_clp: {
    EN: 'Create Clp',
    ID: '',
  },
  filter_group: {
    EN: 'Filter Group: ',
    ID: '',
  },
  add_filter: {
    EN: 'Add Filter',
    ID: '',
  },
  remove_fg: {
    EN: 'Remove FilterGroup',
    ID: '',
  },
  clp_vals: {
    EN: 'Values',
    ID: '',
  },
  clp_add_val: {
    EN: 'Add Value',
    ID: '',
  },
  remove_filt: {
    EN: 'Remove Filter',
    ID: '',
  },
  destination_data: {
    EN: 'destination data',
    ID: '',
  },
  clp_create: {
    EN: 'Create',
    ID: '',
  },
  create_new_l1: {
    EN: 'Create New L1',
    ID: '',
  },
  refresh_tree: {
    EN: 'Refresh Tree',
    ID: '',
  },
  category_navigation: {
    EN: 'Category Navigation',
    ID: '',
  },
  create_new_tree: {
    EN: 'Create New Tree',
    ID: '',
  },
  all_trees: {
    EN: 'All Trees :',
    ID: '',
  },
  select_a_node: {
    EN: 'Select a node',
    ID: '',
  },
  select_destination: {
    EN: 'select a destination',
    ID: '',
  },
  categories: {
    EN: 'categories',
    ID: '',
  },
  create_nav_tab: {
    EN: 'Create Nav tab',
    ID: '',
  },
  create_tile_group: {
    EN: 'Create Tile Group',
    ID: '',
  },
  create_tile_icon: {
    EN: 'Create Tile Icon',
    ID: '',
  },
  top_nav_bar: {
    EN: 'Top Nav Bar',
    ID: '',
  },
  create_nav_bar: {
    EN: 'Create Top Navigation Bar',
    ID: '',
  },
  reseller_credit_view_error: {
    EN: 'View details',
    ID: 'Melihat rincian',
  },
  message_type_not_selected: {
    EN: 'Message Type not selected',
    ID: '',
  },
  clear_sample: {
    EN: 'Clear Samples',
    ID: '',
  },
  transactional: {
    EN: 'Transactional',
    ID: '',
  },
  promotional: {
    EN: 'Promotional',
    ID: '',
  },
  content_type: {
    EN: 'Content Type',
    ID: '',
  },
  reseller_code: {
    EN: 'Reseller Code',
    ID: '',
  },
  customer_codes: {
    EN: 'Customer Code',
    ID: '',
  },
  customer_number: {
    EN: 'Customer Number',
    ID: '',
  },
  customer_no: {
    EN: 'Customer No.',
    ID: '',
  },
  code: {
    EN: 'Code',
    ID: '',
  },
  get_reseller_code: {
    EN: 'Get Reseller Code',
    ID: '',
  },
  country_of_origin: {
    EN: 'Country of origin',
    ID: '',
  },
  country_of_origin_is_required: {
    EN: 'Country of origin is required in all products',
    ID: '',
  },
  agent_dashboard: {
    EN: 'Agent Dashboard',
    ID: 'Dasbor Agen',
  },
  phone_number: {
    EN: 'Phone Number',
    ID: 'Nomor HP',
  },
  add_dispostions: {
    EN: 'Add Dispostions',
    ID: 'Tambah Disposisi',
  },
  order_id_selected: {
    EN: 'Order selected',
    ID: '',
  },
  invoice_user_to_customer: {
    EN: 'Invoice User to Customer',
    ID: 'Tagihan Pengguna ke Pelanggan',
  },
  invoice_supplier_to_user: {
    EN: 'Invoice Supplier to User',
    ID: 'Tagihan Supplier ke Pengguna',
  },
  customer_name: {
    EN: 'Customer Name',
    ID: 'Nama Pelanggan',
  },
  other_details: {
    EN: 'Other Details',
    ID: 'Detail Lainnya',
  },
  final_customer_price: {
    EN: 'Final Customer Price',
    ID: '',
  },
  product_charges: {
    EN: 'Product Charges',
    ID: '',
  },
  payments: {
    EN: 'Payments',
    ID: 'Pembayaran',
  },
  see_all_updates: {
    EN: 'See all updates',
    ID: 'Lihat Semua Update',
  },
  size_ordered: {
    EN: 'Size Ordered',
    ID: 'Ukuran Yang Dipesan',
  },
  availability: {
    EN: 'Availability',
    ID: '',
  },
  initial_return_exchange: {
    EN: 'Initiate Return/Exchange',
    ID: '',
  },
  see_all_orders: {
    EN: 'See all orders',
    ID: 'Lihat Semua Pesanan',
  },
  see_less_upades: {
    EN: 'See less updates',
    ID: '',
  },
  forward: {
    EN: 'Forward',
    ID: '',
  },
  forward_tracking: {
    EN: 'Forward Tracking',
    ID: '',
  },
  reverse_tracking: {
    EN: 'Reverse Tracking',
    ID: '',
  },
  cancellation_date: {
    EN: 'Cancellation Date',
    ID: '',
  },
  cancel_by: {
    EN: 'Cancelled by',
    ID: '',
  },
  order_cancelled: {
    EN: 'Order Cancelled',
    ID: '',
  },
  customer_details: {
    EN: 'Customer Details',
    ID: '',
  },
  copy_details: {
    EN: 'Copy Details',
    ID: '',
  },
  email_sent_successfully: {
    EN: 'Email sent successfully',
    ID: '',
  },
  choose_process_to_initiate: {
    EN: 'Choose process to initiate',
    ID: '',
  },
  add_return_details: {
    EN: 'Add Return Details',
    ID: '',
  },
  initiate_return: {
    EN: 'Initiate Return',
    ID: '',
  },
  add_exchange_details: {
    EN: 'Add Exchange Details',
    ID: '',
  },
  initiate_exchange: {
    EN: 'Initiate Exchange',
    ID: '',
  },
  delivery_partner: {
    EN: 'Delivery Partner',
    ID: '',
  },
  disposition: {
    EN: 'Disposition',
    ID: '',
  },
  sub_disposition: {
    EN: 'Sub disposition',
    ID: '',
  },
  type_of_courier_escalation: {
    EN: 'Type of courier escalation',
    ID: '',
  },
  reverse: {
    EN: 'Reverse',
    ID: '',
  },
  save_ticket: {
    EN: 'Save Ticket',
    ID: '',
  },
  select_disposition: {
    EN: 'Select Disposition',
    ID: '',
  },
  slect_sub_disposition: {
    EN: 'Select Sub disposition',
    ID: '',
  },
  create_ticket: {
    EN: 'Create Ticket',
    ID: '',
  },
  save_and_close: {
    EN: 'Save and Close',
    ID: '',
  },
  search_by: {
    EN: 'Search by',
    ID: 'Cari Dengan',
  },
  meesho_credits: {
    EN: 'Meesho Credits',
    ID: '',
  },
  or: {
    EN: 'or',
    ID: 'atau',
  },
  send_message: {
    EN: 'Send Message',
    ID: 'Kirim Pesan',
  },
  back_to_homepage: {
    EN: 'Back To Homepage',
    ID: 'Kembali ke Halaman Utama',
  },
  no_user_found: {
    EN: 'No User Found',
    ID: '',
  },
  send_custom_message: {
    EN: 'Send Custom Message',
    ID: '',
  },
  email_address: {
    EN: 'Email Address',
    ID: '',
  },
  email_invoice: {
    EN: 'Email Invoice',
    ID: '',
  },
  search_by_order_id: {
    EN: 'Search by Order ID',
    ID: '',
  },
  tracking: {
    EN: 'Tracking',
    ID: '',
  },
  show_result_for_order_id: {
    EN: 'Showing results for Order ID',
    ID: '',
  },
  show_by_order_number: {
    EN: 'Search by Order Number',
    ID: 'Cari dengan Nomor Pesanan',
  },
  user_code: {
    EN: 'User Code',
    ID: 'Kode Pengguna',
  },
  show_result_for_order_number: {
    EN: 'Show result for order number',
    ID: '',
  },
  edit_return_exchange: {
    EN: 'Edit Return / Exchange',
    ID: 'Ubah Pengembalian / Penukaran',
  },
  valid_date_range: {
    EN: 'Please enter a date range within 30 days.',
    ID: '',
  },
  address_line: {
    EN: 'Address Line',
    ID: '',
  },
  order_list: {
    EN: 'Order List',
    ID: '',
  },
  review_ticket: {
    EN: 'Review Ticket',
    ID: '',
  },
  tickets: {
    EN: 'Tickets',
    ID: 'Tiket',
  },
  customer: {
    EN: 'Customer',
    ID: 'Pelanggan',
  },

  ticketing: {
    EN: 'Ticketing...',
    ID: '',
  },
  your_ticket_has_been_created: {
    EN: 'Your ticket has been created.',
    ID: '',
  },
  the_ticker_id_is: {
    EN: 'The ticket id is',
    ID: '',
  },
  faild_to_save_ticket_please_try_againg: {
    EN: 'Failed to save Ticket. Please try again.',
    ID: '',
  },
  pow_products_file_upload: {
    EN: 'POW Products File Upload',
    ID: '',
  },
  url_shortener: {
    EN: 'URL Shortener',
    ID: '',
  },
  custom_url_shortener: {
    EN: 'Custom URL Shortener',
    ID: '',
  },
  generate_short_url: {
    EN: 'Generate Short URL',
    ID: '',
  },
  generated_short_url: {
    EN: 'Generated Short URL',
    ID: '',
  },
  short_key: {
    EN: 'Short Key',
    ID: '',
  },
  short_key_can_not_be_empty: {
    EN: 'Short Key can not be empty',
    ID: '',
  },
  screen_type: {
    EN: 'Screen Type',
    ID: '',
  },
  old_referral_flow: {
    EN: 'Old referral flow',
    ID: '',
  },
  new_referral_flow: {
    EN: 'New referral flow',
    ID: '',
  },
  expected_dispatch_date: {
    EN: 'Expected dispatch date',
    ID: '',
  },
  uat_catalogs: {
    EN: 'UAT Catalogs',
    ID: '',
  },
  audience_entity: {
    EN: 'Audience entity type',
    ID: 'Entitas audiens',
  },
  select_audience_entity: {
    EN: 'Select audience entity type',
    ID: 'Audiens pilih entitas audiens',
  },
  anonymous_audience_id: {
    EN: 'Anonymus audience id',
    ID: 'Id audiens anonymus',
  },
  select_experiment_entity: {
    EN: 'Select audience entity type',
    ID: 'Pilih jenis entitas audiens',
  },
  experiment_entity: {
    EN: 'Experiment entity',
    ID: 'Entitas percobaan',
  },
  select_experiment_entity_error: {
    EN: 'Experiment entity is required!',
    ID: 'Entitas eksperimen wajib diisi!',
  },
  anonymous_audience_id_error: {
    EN: 'Anonymus audience is required!',
    ID: 'Audiens anonymus wajib diisi!',
  },
  is_empty_audience: {
    EN: 'Audience',
    ID: 'Audiens',
  },
  entity_type: {
    EN: 'Entity type',
    ID: 'jenis entitas',
  },
  empty_audience_text: {
    EN: 'If you want to create an audience with no user (Empty audience), then select no.',
    ID: 'Jika Anda ingin membuat audiens tanpa pengguna, pilih no.',
  },
  empty_audience_query_text: {
    EN: 'If you want to create an audience with users (via file upload/SQL query), then select Yes.',
    ID: 'Jika Anda ingin membuat audiens dengan pengguna (melalui upload file / kueri SQL), pilih ya.',
  },
  hours: {
    EN: 'Hours',
    ID: 'Jam',
  },
  minutes: {
    EN: 'Minutes',
    ID: 'Menit',
  },
  user_level: {
    EN: 'User Level',
    ID: 'User Level',
  },
  green_user: {
    EN: 'Green User',
    ID: 'Green User',
  },
  non_green_user: {
    EN: 'Non Green User',
    ID: 'Non Green User',
  },
  new_user: {
    EN: 'New User',
    ID: 'New User',
  },
  type_of_users: {
    EN: 'Type of Users',
    ID: 'Type of Users',
  },
  any: {
    EN: 'Any',
    ID: 'Any',
  },
  image_type: {
    EN: 'Image Type',
    ID: '',
  },
  user_uploaded_image: {
    EN: 'User Uploaded Image',
    ID: '',
  },
  image_comparison: {
    EN: 'Image Comparison',
    ID: '',
  },
  earnings_main_page: {
    EN: 'Earning Main Page',
    ID: '',
  },
  margin_earnings: {
    EN: 'Margin earnings',
    ID: '',
  },
  cart: {
    EN: 'Cart',
  },
  review: {
    EN: 'Review',
  },
  referral_earnings: {
    EN: 'Referral Earnings',
    ID: '',
  },
  journey: {
    EN: 'Journey',
    ID: '',
  },
  social_profile: {
    EN: 'Social profile',
    ID: '',
  },
  attributable_to: {
    EN: 'Attributable to',
    ID: '',
  },
  price_type_tag_name: {
    EN: 'Price type tag name',
    ID: '',
  },
  attributable_to_is_required: {
    EN: 'Attributable to is required',
    ID: '',
  },
  return_reasons: {
    EN: 'Return Reasons',
    ID: '',
  },
  l1: {
    EN: 'L1',
    ID: '',
  },
  l2: {
    EN: 'L2',
    ID: '',
  },
  sscat: {
    EN: 'SSCAT',
    ID: '',
  },
  sscat_name: {
    EN: 'SSCAT Name',
    ID: '',
  },
  select_sscat_err: {
    EN: 'Please select any SSCAT',
    ID: '',
  },
  sscat_new: {
    EN: 'Add New SSCAT',
    ID: '',
  },
  attributable: {
    EN: 'Attributable',
    ID: '',
  },
  reason_type: {
    EN: 'Reason Type',
    ID: '',
  },
  comment_required: {
    EN: 'Comment Required',
    ID: '',
  },
  external: {
    EN: 'External',
    ID: '',
  },
  consolidation_enabled: {
    EN: 'Consolidation',
    ID: '',
  },
  rule_identifier: {
    EN: 'Identifier',
    ID: '',
  },
  image_qc_types: {
    EN: 'Image QC Type',
    ID: '',
  },
  images_bulk_upload: {
    EN: 'Images Bulk Upload',
    ID: '',
  },
  drag_and_drop_files_here: {
    EN: 'Drag and Drop Files Here',
    ID: '',
  },
  drop_images_here_or_click_to_select_images: {
    EN: 'Drop images here, or click to select images',
    ID: '',
  },
  select_images: {
    EN: 'Select Images',
    ID: '',
  },
  upload_images: {
    EN: 'Upload Images',
    ID: 'Unggah Gambar',
  },
  path: {
    EN: 'Path',
    ID: 'Link Gambar',
  },
  file_name: {
    EN: 'File Name',
    ID: 'Nama Gambar',
  },
  new_query_expansion: {
    EN: 'Add New Query',
    ID: 'Add New Query',
  },
  quality_check: {
    EN: 'QC',
    ID: '',
  },
  serviceability_file_upload: {
    EN: 'Serviceability File Upload',
    ID: '',
  },
  file_type: {
    EN: 'Select a file type',
    ID: '',
  },
  progress: {
    EN: 'Progress',
    ID: '',
  },
  refresh: {
    EN: 'Refresh',
    ID: '',
  },
  initial: {
    EN: 'Initial',
    ID: '',
  },
  no_new_sscat_associated_with_old_sscat_found: {
    EN: 'No new sscat id associated with the old sscat id found',
    ID: '',
  },
  you_selected_the_incorrect_return_reason_kindly_reinitiate_the_return_with_the_correct_reason_and_corresponding_evidence:
    {
      EN: 'You\'ve selected the incorrect return reason. Kindly reinitiate the return with the correct reason and corresponding evidence.',
      ID: '',
    },
  image_uploaded_by_you_is_not_that_of_an_actual_product_kindly_re_initiate_the_return_by_uploading_the_picture_of_the_delivered_product:
    {
      EN: 'Image uploaded by you is not that of an actual product. Kindly re-initiate the return by uploading the picture of the delivered product.',
      ID: '',
    },
  please_fill_taxonomy_attributes: {
    EN: 'Please fill taxonomy products',
    ID: '',
  },
  processing: {
    EN: 'Processing',
    ID: '',
  },
  completed: {
    EN: 'Completed',
    ID: '',
  },
  uploaded: {
    EN: 'Uploaded',
    ID: '',
  },
  select_message_type: {
    EN: 'Select Message Type',
    ID: '',
  },
  child_campaign_view: {
    EN: 'Child Campaign View',
    ID: '',
  },
  parent_campaign_view: {
    EN: 'Parent Campaign View',
    ID: '',
  },
  select_campaign_type: {
    EN: 'Select Campaign Type',
    ID: '',
  },
  user: {
    EN: 'User',
    ID: '',
  },
  select_campaign_type_required: {
    EN: 'Select campaign type required',
    ID: '',
  },
  name_of_template: {
    EN: 'Name of Template',
    ID: '',
  },
  media_file: {
    EN: 'Media File',
    ID: '',
  },
  enter_media_file: {
    EN: 'Enter Media File',
    ID: '',
  },
  all_notification_campaigns: {
    EN: 'All Notification Campaigns',
    ID: '',
  },
  reel_moderation_dashboard:{
    EN: 'Reel Moderation Dashboard',
    ID: ''
  },
  key_value_pairs: {
    EN: 'Key Value Pairs',
    ID: '',
  },
  enter_key: {
    EN: 'Enter Key',
    ID: '',
  },
  enter_value: {
    EN: 'Enter Value',
    ID: '',
  },
  preview: {
    EN: 'Preview',
    ID: '',
  },
  invalid_key_value_pairs: {
    EN: 'Invalid key value pairs',
    ID: '',
  },
  test_template: {
    EN: 'Test Template',
    ID: '',
  },
  enter_user_Ids: {
    EN: 'Enter User Ids',
    ID: '',
  },
  are_you_sure_want_to_delete_this_user: {
    EN: 'Are you sure want to Delete this user?',
    ID: '',
  },
  soft_delete: {
    EN: 'Soft Delete',
    ID: '',
  },
  hard_delete: {
    EN: 'Hard Delete',
    ID: '',
  },
  delete_user: {
    EN: 'Delete User',
    ID: '',
  },
  user_delete: {
    EN: 'User Delete',
    ID: '',
  },
  user_delete_successfully: {
    EN: 'User delete successfully',
    ID: '',
  },
  gst: {
    EN: 'GST',
    ID: '',
  },
  invalidate_gst: {
    EN: 'Invalidate GST',
    ID: '',
  },
  invalidate_already: {
    EN: 'Invalidate Already',
    ID: '',
  },
  supplier_gst_invalidation: {
    EN: 'Suppliers GST Invalidation',
    ID: '',
  },
  cash_on_delivery_fee: {
    EN: 'Cash on Delivery Fee',
    ID: '',
  },
  cash_on_delivery_fee_is_not_valid: {
    EN: 'Cash on Delivery Fee is not valid',
    ID: '',
  },
  required_media_file: {
    EN: 'Required Media File',
    ID: '',
  },
  filter_by_created_on: {
    EN: 'Created on',
    ID: '',
  },
  filter_by_schedule_date: {
    EN: 'Schedule Date',
    ID: '',
  },
  filter_by_status: {
    EN: 'Status',
    ID: '',
  },
  filter_by_column_names: {
    EN: '+ Column',
    ID: '',
  },
  filter_by_channel: {
    EN: 'Channel',
    ID: '',
  },
  filter_by_created_by: {
    EN: 'Created by',
    ID: '',
  },
  sort: {
    EN: 'Sort by',
    ID: '',
  },
  supplier_ads_task: {
    EN: 'Ads Tasks',
    ID: '',
  },
  button_template: {
    EN: 'Button Template',
    ID: '',
  },
  add_stop_word: {
    EN: 'Add/Edit Stop Word',
    ID: '',
  },
  template_saved_successfully: {
    EN: 'Template saved successfully',
    ID: '',
  },
  test_template_sent_successfully: {
    EN: 'Test Template sent successfully',
    ID: '',
  },
  unable_to_send_notification_error: {
    EN: 'Unable to send notifications, Please enter a valid user Id’s',
    ID: '',
  },
  created_by: {
    EN: 'Created By',
    ID: '',
  },
  sender_name: {
    EN: 'Sender Name',
    ID: '',
  },
  channel: {
    EN: 'Channel',
    ID: '',
  },
  country_code: {
    EN: 'Country code',
    ID: '',
  },
  mrp_can_not_be_empty: {
    EN: 'MRP can not be empty.',
    ID: '',
  },
  admin_notices: {
    EN: 'Admin Notices',
    ID: '',
  },
  add_new_notices: {
    EN: 'Add New Notice',
    ID: '',
  },
  update_notice: {
    EN: 'Update Notice',
    ID: '',
  },
  unable_to_fetch_notices: {
    EN: 'unable to fetch notices',
    ID: '',
  },
  posted: {
    EN: 'Posted',
    ID: '',
  },
  pinned: {
    EN: 'Pinned',
    ID: '',
  },
  tags: {
    EN: 'Tags',
    ID: '',
  },
  select_language: {
    EN: ' Select Language',
    ID: '',
  },
  widget_source: {
    EN: 'Source is required',
  },
  create: {
    EN: 'Create',
    ID: '',
  },
  verify_count: {
    EN: 'Verify Count',
    ID: '',
  },
  enter_valid_schedule_date_time: {
    EN: 'Please Select a valid Date and Time',
    ID: '',
  },
  campaign_schedule_type: {
    EN: 'Campaign Schedule Type',
    ID: '',
  },
  one_time: {
    EN: 'One Time',
    ID: '',
  },
  multiple_dates: {
    EN: 'Multiple Dates',
    ID: '',
  },
  recurring: {
    EN: 'Recurring',
    ID: '',
  },
  pick_dates_and_delivery_time: {
    EN: 'Pick dates and delivery time',
    ID: '',
  },
  enable_different_delivery_time_for_every_date: {
    EN: 'Enable different delivery time for every date',
    ID: '',
  },
  add_another_date: {
    EN: 'Add another date',
    ID: '',
  },
  send_the_campaign_at: {
    EN: 'Send the campaign at',
  },
  at: {
    EN: 'at',
    ID: '',
  },
  create_recurring_campaign: {
    EN: 'Create Recurring Campaign',
    ID: '',
  },
  campaign_start: {
    EN: 'Campaign start',
    ID: '',
  },
  campaign_end: {
    EN: 'Campaign end',
    ID: '',
  },
  never_end: {
    EN: 'Never end',
    ID: '',
  },
  select_date: {
    EN: 'Select date',
    ID: '',
  },
  after: {
    EN: 'After',
    ID: '',
  },
  occurrences: {
    EN: 'Occurrences',
    ID: '',
  },
  repeat: {
    EN: 'Repeat',
    ID: '',
  },
  once_every: {
    EN: 'once every',
    ID: '',
  },
  on: {
    EN: 'on',
    ID: '',
  },
  by: {
    EN: 'by',
    ID: '',
  },
  day_of_week: {
    EN: 'Day of the week',
    ID: '',
  },
  day_of_month: {
    EN: 'Day of the month',
    ID: '',
  },
  parent_name: {
    EN: 'Parent Name',
    ID: '',
  },
  gstin_otp_verification_status: {
    EN: 'GSTIN otp verification status',
    ID: '',
  },
  enrolment_number_otp_verification_status: {
    EN: 'Enrolment Number otp verification status',
    ID: '',
  },
  please_select_week_days: {
    EN: 'Please select week days',
    ID: '',
  },
  please_select_month_days: {
    EN: 'Please select month days',
    ID: '',
  },
  end_date_error: {
    EN: 'You cannot end a campaign before it begins',
  },
  frequency: {
    EN: 'Frequency',
    ID: '',
  },
  interval: {
    EN: 'Interval',
    ID: '',
  },
  repeat_occurrence: {
    EN: 'Remaining Occurrence',
    ID: '',
  },
  select_receiver: {
    EN: 'Select Receiver type',
    ID: '',
  },
  edit_template: {
    EN: 'Do you want to edit template',
    ID: '',
  },
  anonymous: {
    EN: 'Anonymous',
    ID: '',
  },
  both: {
    EN: 'Both',
    ID: '',
  },
  show_on_notification_store: {
    EN: 'Show on Notification Store',
    ID: '',
  },
  promotions: {
    EN: 'Promotions',
    ID: '',
  },
  activity: {
    EN: 'Activity',
    ID: '',
  },
  section: {
    EN: 'Section',
    ID: '',
  },
  sent: {
    EN: 'Sent',
    ID: '',
  },
  clicked: {
    EN: 'Click',
    ID: '',
  },
  read: {
    EN: 'Read',
    ID: '',
  },
  select_query_type: {
    EN: 'Select query type',
    ID: '',
  },
  redshift: {
    EN: 'Redshift',
    ID: '',
  },
  presto: {
    EN: 'Presto',
  },
  reviews_count: {
    EN: 'Reviews Count',
  },
  rejection_reason: {
    EN: 'Rejection Reason',
  },
  select_sender_number: {
    EN: 'Select sender number',
  },
  provide_comma_separated_supplier_ids: {
    EN: 'Provide comma separated Supplier IDs here',
    ID: '',
  },
  textarea_placeholder_comma_separated_supplier_ids: {
    EN: 'Enter comma separated Supplier IDs (only numeric digits are allowed)',
  },
  invalid_selected_suppliers: {
    EN: 'Invalid Selected Supplier IDs',
  },
  ambiguous_suppliers: {
    EN: 'Ambiguous Supplier IDs (Requested to remove as well as add)',
  },
  upload_image: {
    EN: 'Upload Image',
  },
  same_image: {
    EN: 'Use same image for all languages',
  },
  edit_and_approve: {
    EN: 'Edit & Approve',
  },
  review_images: {
    EN: 'Review Images',
  },
  live_commerce: {
    EN: 'Live Commerce',
  },
  applicability_type: {
    EN: 'Applicability Type',
  },
  rule_type: {
    EN: 'Rule Type',
  },
  agency_panel_account_management: {
    EN: 'Agency Panel Account Management',
  },
  widget_icon_required: {
    EN: 'Please Upload Widget Icon'
  },
  widget_offer_data_id_required: {
    EN: 'Widget offer data id is required'
  },
  widget_offer_data_icon_url_required: {
    EN: 'Please Upload Widget Offer Data Icon'
  },
  catalog_listing_page: {
    EN: 'clp',
  },
  coin_text: {
    EN: 'enter coin text',
  },
  coin_count: {
    EN: 'enter coin count',
  },
  coins_landing_page: {
    EN: 'coins landing page',
  },
  coins_text_required: {
    EN: 'coin text is required',
  },
  coins_count_required: {
    EN: 'coin count is required',
  },
  visual_search_page: {
    EN: 'Visual Search page',
  },
  visual_search_intro_page: {
    EN: 'Visual Search Intro page',
  },
  ds_enabled: {
    EN: 'DS Enabled',
  },
  widget_tile_ds_enabled: {
    EN: 'Widget Tile DS Enabled',
  },
  widget_group_ds_enabled: {
    EN: 'Widget Group DS Enabled',
  },
  ds_enabled_slots_error: {
    EN: 'please add valid number of slots',
  },
  ds_enabled_theme_error: {
    EN: 'please select a valid theme',
  },
  number_of_slots: {
    EN: 'Number of Slots',
  },
  intent: {
    EN: 'Intent',
  },
  category_awareness: {
    EN: 'Category Awareness',
  },
  feature: {
    EN: 'Feature',
  },
  monetisation: {
    EN: 'Monetisation',
  },
  select_theme: {
    EN: 'Select Theme',
  },
  fixed: {
    EN: 'Fixed',
  },
  agent_queries: {
    EN: 'Agent - Queries'
  },
  admin_queries: {
    EN: 'Admin - Queries'
  },
  ds_tagging: {
    EN: 'DS Tagging'
  },
  bulk_widgets_upload: {
    EN: 'Bulk Widgets upload'
  },
  bulk_widgets_append: {
    EN: 'Append'
  },
  bulk_widgets_replace: {
    EN: 'Replace'
  },
  search_clp: {
    EN: 'Search Clp'
  },
  enter_clp_id_or_name: {
    EN: 'search clp by id or name'
  },
  earn_coins_message_placeholder: {
    EN: 'Please enter earn_coin label here'
  },
  earn_coins_message: {
    EN: 'Earn Coin Message'
  },
  earn_coins_message_error: {
    EN: 'Please enter earn coin message'
  },
  assistive_text_placeholder: {
    EN: 'Enter assistive text here'
  },
  assistive_text: {
    EN: 'Assistive Text'
  },
  assistive_text_error: {
    EN: 'Please enter assistive text message'
  },
  product_deactivate_message: {
    EN: 'Are you sure you want to deactivate the following product(s)?',
    ID: 'Apakah anda yakin ingin menonaktifkan produk berikut?',
  },
  upload_hero_image_label: {
    EN: 'Please upload hero image below'
  },
  upload_hero_image: {
    EN: 'Upload Hero Image'
  },
  min_scrolling_carousel_limit: {
    EN: 'Minimum 3 images are required for scrolling carousel'
  },
  min_text_content_limit: {
    EN: 'Please upload image for text content'
  },
  bulk_password_reset: {
    EN: 'Bulk Password Reset'
  },
  select_clients: {
    EN: 'Select Clients'
  },
  select_client_error: {
    EN: 'Please select a valid client'
  },
  duplicate_nav_bar: {
    EN: 'Duplicate this nav bar'
  },
  duplicate_navBar_btn: {
    EN: 'Duplicate It'
  },
  email_notifications: {
    EN: 'Email Notifications',
  },
  is_animated_enabled: {
    EN: 'Animated images required'
  },
  min_animated_content_limit: {
    EN: 'Please upload atleast 2 images for animated carousel'
  },
  animated_content_time_interval: {
    EN: 'Switch Interval'
  },
  ads_enabled: {
    EN: 'Ads DS Enabled'
  },
  clp_data: {
    EN: 'CLP Data'
  },
  dynamic: {
    EN: 'Dynamic',
    ID: '',
  },
  automatic: {
    EN: 'Automatic',
    ID: '',
  },
  type_of_audience: {
    EN: 'Type of Audience',
    ID: ''
  },
  mod_ids: {
    EN: 'Mod Ids',
    ID: ''
  },
  mod_id_is_required: {
    EN: 'Mod Ids are required',
    ID: '',
  },
  mod_id_validation_error: {
    EN: 'Mod Ids should be between 0 to 99',
    ID: '',
  },
  override_mod_id_title: {
    EN: 'Continue to override Mod IDs',
    ID: '',
  },
  override_mod_id_description: {
    EN: 'Below Mod Ids are being currently used in another experiment.',
    ID: '',
  },
  stop_refresh_title: {
    EN: 'Are you sure you want to Stop Refresh?',
    ID: '',
  },
  start_refresh_title: {
    EN: 'Are you sure you want to Start Audience Refresh?',
    ID: '',
  },
  stop_refresh_description: {
    EN: 'The refresh will stop once the “In Queue” refresh cycle completes.',
    ID: '',
  },
  stop_refresh: {
    EN: 'Stop Refresh',
    ID: ''
  },
  start_refresh: {
    EN: 'Start Refresh',
    ID: ''
  },
  audience_status: {
    EN: 'Audience Status',
    ID: ''
  },
  stopped_audience_msg: {
    EN: 'Audience refresh has stopped',
    ID: ''
  },
  inqueue_audience_msg: {
    EN: 'Audience refresh will stop after current refresh cycle',
    ID: ''
  },
  table_bucket_id: {
    EN: 'Bucket Id',
    ID: ''
  },
  table_audience_id: {
    EN: 'Audience Id',
    ID: ''
  },
  special_push_type: {
    EN: 'Special Push Type',
    ID: ''
  },
  push_type: {
    EN: 'Push Type',
    ID: ''
  },

  search_cat: {
    EN: 'search category id'
  },
  sub_category_id: {
    EN: 'sub category id'
  },
  sub_sub_category_id: {
    EN: 'sub sub category id'
  },
  search_query: {
    EN: 'Search Query'
  },
  search_nps_page: {
    EN: 'search nps page'
  },
  cat_id: {
    EN: 'enter category id'
  },
  bulk_gstin_invalidate: {
    EN: 'Bulk GSTIN Invalidate'
  },
  min_animated_content_time_interval: {
    EN: 'Time interval cannot be less than 400'
  },
  nps_rating: {
    EN: 'nps rating'
  },
  search_catalogs: {
    EN: 'search catalogs'
  },
  i_have_obtained_the_necessary_approval_for_changing_bank_account:
    {
      EN: 'I have obtained the necesasry approval for changing bank account. In case of escrow account or secondary account, I have informed the respective lender and receive their confirmation for account change',
    },
  note_this_seller_has_a_secondary_escrow_account_active: {
    EN: 'Note: This seller has a secondary escrow account active',
  },
  same_as_devlivery_address: {
    EN: 'Same as Delivery Address',
  },
  add_new_address: {
    EN: 'Add a New Address',
  },
  widget_group_types: {
    EN: 'Widget Group Types',
  },
  Select_widget_group_types: {
    EN: 'Select Widget-Group Type',
  },
  wg_fixed: {
    EN: 'FIXED',
  },
  wg_ds: {
    EN: 'DS',
  },
  widget_group_type_required: {
    EN: 'Please select a valid widget-group type',
  },
  slot_number: {
    EN: 'Slot Number',
  },
  slot_number_is_required: {
    EN: 'Slot Number is required',
  },
  comments: {
    EN: 'Send approval request',
  },
  supplier_role_based_access: {
    EN: 'Supplier Role Based Access Control',
  },
  send_supplier_email_: {
    EN: 'Send Supplier Email'
  },
  select_entity_type: {
    EN: 'Select Entity Type',
  },
  select_approve_reason: {
    EN: 'Select Approve Reason'
  },
  approve_reason_is_required: {
    EN: 'Approve reason in required'
  },
  zero_order_audience: {
    EN: 'Zero Order Audience',
  },
  single_product_details: {
    EN: 'Single Product Details',
  },
  north_star: {
    EN: 'North Star Metric',
    ID: '',
  },
  product_leader_email: {
    EN: 'Product Leader Email',
    ID: '',
  },
  engineering_leader_email: {
    EN: 'Engineering Leader Email',
    ID: '',
  },
  real_estate_impacting: {
    EN: 'Real Estate Impacting',
    ID: '',
  },
  select_real_estate_impacting: {
    EN: 'Select the Real Estate',
    ID: '',
  },
  stage: {
    EN: 'Experiment Stage',
    ID: '',
  },
  select_stage: {
    EN: 'Select Experiment Stage',
    ID: '',
  },
  north_star_is_required: {
    EN: 'North Star is required',
    ID: '',
  },
  real_estate_impact_is_required: {
    EN: 'Real Estate Impact is required',
    ID: '',
  },
  stage_is_required: {
    EN: 'Experiment Stage is required',
    ID: '',
  },
  widget_group_owner: {
    EN: 'Widget Group Owner',
    ID: '',
  },
  invalid_email: {
    EN: 'Invalid Email. Please enter a valid email. example : test@meesho.com',
    ID: '',
  },

  search_widget_group: {
    EN: 'Search Widget Group',
    ID: '',
  },
  search_banner: {
    EN: 'Search Banner',
    ID: '',
  },
  audit_history: {
    EN: 'Audit History',
    ID: '',
  },
  widget_group_theme: {
    EN: 'Widget Group Theme',
    ID: '',
  },
  enter_widget_group_id: {
    EN: 'Enter Widget Group Id',
    ID: '',
  },
  enter_banner_id: {
    EN: 'Enter Banner Id',
    ID: '',
  },
  mb_landing_page: {
    EN: 'Meesho Balance landing page',
    ID: '',
  },
  view_template_id: {
    EN: 'View Template Id',
    ID: '',
  },
  clp_gst_type: {
    EN: 'CLP GST Type',
    ID: '',
  },
  widget_owner_email: {
    EN: 'Widget Group Email Is Required',
    ID: '',
  },
  debug_your_widget_group: {
    EN: 'Debug your Widget Group',
    ID: '',
  },
  single_product: {
    EN: 'PDP Single Product',
    ID: '',
  },
  owner_approved: {
    EN: 'Is Owner Approved',
    ID: ''
  },
  select_l1_reason: {
    EN: 'Select L1 Reason',
    ID: ''
  },
  select_l2_reason: {
    EN: 'Select L2 Reason',
    ID: ''
  },
  wg_owner_required: {
    EN: 'Is WG Owner approval required',
    ID: ''
  },
  tag:{
    EN:'Tag',
    ID:''
  },
  is_bottomsheet_wg: {
    EN: 'Visible on BottomSheet',
    ID: ''
  },
  poc_approved: {
    EN: 'Is Centrally Approved',
    ID: ''
  },
  pickup_images: {
    EN: 'Pickup Images',
    ID: ''
  },
  child_awbs: {
    EN: 'Child AWBs',
    ID: ''
  },
  create_new_label: {
    EN: 'Create new Label',
  },
  show_interstitial_filters: {
    EN: 'Show Interstitital Filters',
  },
  template_type: {
    EN: 'Template Type',
  },
  filter_title: {
    EN: 'Filter Label Title',
  },
  show_filter_title: {
    EN: 'Show Filter Label Title',
  },
  show_interstitial_subtitle: {
    EN: 'Show Subtitle',
  },
  show_interstitial_filter_value: {
    EN: 'Show Mid Feed Filter Values',
  },
  show_interstitial_filter_subtitle: {
    EN: 'Show Subtitle',
  },
  gst_tax_invoice: {
    EN: 'Supplier GST Tax Invoice',
    ID: ''
  },
  non_gst_tax_invoice: {
    EN: 'Supplier Non GST Tax Invoice',
    ID: ''
  },
  supplier_tax_invoices: {
    EN: 'Supplier Tax Invoices',
    ID: ''
  },
  source_clp_id: {
    EN: 'Enter Clp Id for Source CLP',
  },
  enrolment_no: {
    EN: 'Enrolment No',
  },
  source_collection_id: {
    EN: 'Enter Collection ID for Source Collection',
  },
  experiment_type:{
    EN: 'Experiment Type',
  },
  experiment_type_is_required:{
    EN: 'Experiment Type is required'
  },
  seller_bank_verification: {
    EN: 'Seller Bank Verification'
  },
  sdui_widget_forge: {
    EN: 'SDUI Widget Forge',
  },
  sdui_widget_template: {
    EN: 'SDUI Widget Template',
  },
  client_page:{
    EN: 'Client Page'
  },
  client_page_is_required:{
    EN: 'Client Page is required'
  },
  search_by_catalog_id: {
    EN: 'Search by Catalog Id',
  },
  search_landing_page: {
    EN: 'Search Landing Page'
  },
  all_search_query: {
    EN: 'All Search Query'
  },
  all_clp: {
    EN: 'All CLP'
  },
  cancellation_initiated: {
    EN: 'Cancellation Initiated',
    ID: 'Pembatalan Dimulai'
  },
  sdui_widget_preview: {
    EN: 'SDUI Widget Preview',
  },
};

export function getDateAndTime(dateTime) {
  let country_code = Globalize.getCountry();
  let date_string = '';
  if (country_code === 'IN') {
    date_string = moment(dateTime)
      .tz('Asia/Kolkata')
      .format('YYYY-MM-DD HH:mm:ss');
  } else if (country_code === 'ID') {
    date_string = moment(dateTime)
      .tz('Asia/Jakarta')
      .format('YYYY-MM-DD HH:mm:ss');
  } else {
    date_string = moment(dateTime)
      .tz('Asia/Kolkata')
      .format('YYYY-MM-DD HH:mm:ss');
  }
  return date_string;
}

export function convertDateAndTime(dateTime) {
  let country_code = Globalize.getCountry();
  let date_string = '';
  if (country_code === 'IN') {
    date_string = moment
      .tz(dateTime, 'Asia/Kolkata')
      .tz('Asia/Kolkata')
      .format();
  } else if (country_code === 'ID') {
    date_string = moment
      .tz(dateTime, 'Asia/Jakarta')
      .clone()
      .tz('Asia/Kolkata')
      .format();
  } else {
    date_string = moment
      .tz(dateTime, 'Asia/Kolkata')
      .tz('Asia/Kolkata')
      .format();
  }
  return date_string;
}


export function convertDateAndTimePinPosts(date, time) {
  let country_code = Globalize.getCountry();
  let dateTime = null;
  if(date !== null && time !== null ){
    dateTime = date+'T'+time+':00';
  }
  let date_string = '';
  if (country_code === 'IN') {
    date_string = moment
      .tz(dateTime, 'Asia/Kolkata')
      .tz('Asia/Kolkata')
      .format();
  } else if (country_code === 'ID') {
    date_string = moment
      .tz(dateTime, 'Asia/Jakarta')
      .clone()
      .tz('Asia/Kolkata')
      .format();
  } else {
    date_string = moment
      .tz(dateTime, 'Asia/Kolkata')
      .tz('Asia/Kolkata')
      .format();
  }
  return date_string;
}
