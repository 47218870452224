import { combineReducers } from 'redux';
import catalog from './reducers/catalog';
import collections from './reducers/collections';
import dailyTrends from './reducers/dailyTrends';
import dashboard from './reducers/dashboard';
import order from './reducers/order';
import shared from './reducers/shared';
import supplier from './reducers/supplier';
import payments from './reducers/payments';
import { fetchUserRoles } from './reducers/user';
import gamification from './reducers/gamification';
import excelUpload from './reducers/excelUpload';
import excelUploadedFiles from './reducers/excelUploadedFiles';
import agentDashboard from './reducers/agentDashboard';
import catalogIntentFilter from './reducers/catalogIntentFilter';
import queryNormalization from './reducers/queryNormalization';
import queryExpansion from './reducers/queryExpansion';
import queryTagging from './reducers/queryTagging';
import qclCorrection from './reducers/qclCorrection';
import stopWords from './reducers/stopWords';
import searchRuleEngine from './reducers/searchRuleEngine';
import catalogIntentFilterV2 from './reducers/catalogIntentFilterV2';
import abgMappings from './reducers/abgMapping';

export default combineReducers({
  catalog,
  collections,
  dailyTrends,
  dashboard,
  order,
  payments,
  shared,
  supplier,
  fetchUserRoles,
  gamification,
  excelUpload,
  excelUploadedFiles,
  agentDashboard,
  catalogIntentFilter,
  queryNormalization,
  queryExpansion,
  queryTagging,
  stopWords,
  qclCorrection,
  searchRuleEngine,
  catalogIntentFilterV2,
  abgMappings
});
