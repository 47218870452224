export function callOnLoadFunction() {
  if(document.getElementById('filterBlock') != null) {
    var offsetHeight = document.getElementById('filterBlock').offsetHeight;
    var windowHeight = window.innerHeight;
    var tableHeight = windowHeight - offsetHeight - 235;
    document.getElementById('scroll-table-body-content').style.height = tableHeight + 'px';
  }
  /*if(document.getElementById('filterBlock') != null) {
   document.getElementById('right-body-table').addEventListener('scroll', scrollStoperBody);
   document.getElementById('right-header-table').addEventListener('scroll', scrollStoperHeader);
   }*/
}

window.onload = callOnLoadFunction();


var delayedExec = function(after, fn) {
  var timer;
  return function() {
    timer && clearTimeout(timer);
    timer = setTimeout(fn, after);
  };
};

var scrollStoperBody = delayedExec(100, function() {
  document.getElementById('right-header-table').scrollLeft = document.getElementById('right-body-table').scrollLeft;
});

var scrollStoperHeader = delayedExec(100, function() {
  document.getElementById('right-body-table').scrollLeft = document.getElementById('right-header-table').scrollLeft;
});

$(document).on('click', '.image-zoom', function(e) {
  let src = $(this).attr('data-src') ? $(this).attr('data-src') : $(this).attr('src');
  let appendHtml = '';
  appendHtml += '<div id="myModal" class="modal2">';
  appendHtml += '<span class="closeimg">&times;</span>';
  appendHtml += '<img src=' + src + ' class="modal-content2" id="img01">';
  appendHtml += '</div>';
  $('body').append(appendHtml);
});

$(document).on('click', '.closeimg, #myModal', function() {
  $('#myModal').remove();
});

$(document).on('keyup', function(e) {
  if(e.keyCode === 27 && $('#myModal').length > 0) {
    $('#myModal').remove();
  }
});
