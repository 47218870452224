export const SEARCH_RULE_ENGINE_BASE_URL = '/api/search/search-indexing-rule/';
export const FETCH_ALL_CATEGORIES_URL = `${SEARCH_RULE_ENGINE_BASE_URL}get-categories-data`;
export const FETCH_CATEGORY_WISE_AVAILABLE_FIELDS_URL = `${SEARCH_RULE_ENGINE_BASE_URL}rule-fields`;
export const FETCH_SPECIFIC_RULE_URL = `${SEARCH_RULE_ENGINE_BASE_URL}get-rule`;
export const CREATE_RULE_URL = `${SEARCH_RULE_ENGINE_BASE_URL}create-rule`;
export const UPDATE_RULE_URL = `${SEARCH_RULE_ENGINE_BASE_URL}edit-rule`;
export const DELETE_RULE_BY_ID_URL = `${SEARCH_RULE_ENGINE_BASE_URL}delete-rule`;
export const IS_RULE_EXIST_URL = `${SEARCH_RULE_ENGINE_BASE_URL}is-rule-exist`;
export const FETCH_ALL_RULES_URL = `${SEARCH_RULE_ENGINE_BASE_URL}get-rules/all`;
export const FETCH_MTRC_URL = `${SEARCH_RULE_ENGINE_BASE_URL}get-mtrc`;
export const UPDATE_MTRC_URL = `${SEARCH_RULE_ENGINE_BASE_URL}update-mtrc`;
export const UPDATE_RULE_STATUS_URL = `${SEARCH_RULE_ENGINE_BASE_URL}update-rule-status`;
export const UPDATE_APPLICABILITY_URL = `${SEARCH_RULE_ENGINE_BASE_URL}update-rule-applicability`;

export const CAT = { name: 'cat', id: 1, type: 'category' };
export const SCAT = { name: 'scat', id: 2, type: 'sub_category' };
export const SSCAT = { name: 'sscat', id: 3, type: 'sub_sub_category' };
export const GLOBAL_CAT = { name: 'global', id: 4, type: 'global' };
export const CATEGORY_TYPES = [SSCAT, GLOBAL_CAT]; //[CAT, SCAT, SSCAT, GLOBAL_CAT]
export const CATEGORY_TYPE_MAPPING = {
  1: 'cat',
  2: 'scat',
  3: 'sscat',
  4: 'global',
};

export const TIER_ONE = 'tier_one';
export const TIER_TWO = 'tier_two';
export const TIER_THREE = 'tier_three';
export const TIERS = [TIER_ONE, TIER_TWO, TIER_THREE];
export const STATUS_OPTIONS = {1: 'stopped', 2: 'started'};
export const APPLICABILITY_OPTIONS = {0: 'Limited Users', 1: 'Limited Catalogs', 2: 'default'};
export const APPLICABILITY_TYPES = ['user', 'catalog', 'global'];