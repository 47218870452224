import {
  SET_LOADER,
  SET_CATEGORY_TYPE,
  SET_ALL_CATEGORIES,
  SET_SELECTED_CATEGORY_VALUE,
  SET_TIER,
  SET_AVAILABLE_FIELDS,
  ADD_SELECTED_FILEDS,
  DELETE_SELECTED_FILEDS,
  SET_IS_RULE_EXIST,
  SET_FIELDS,
  SET_ALL_RULES,
  REQUEST_FAILED,
  RULE_DELETED,
  RULE_CREATED,
  RULE_UPDATED,
  CLEAR_FIELDS_ON_ADD_RULE_PAGE_UNMOUNT,
  RESET_REDUCER,
  RESET_SNACKBAR_MESSAGE,
  CHANGE_ACTIVE_TAB,
  SET_MTRC_DATA,
  MTRC_UPDATED,
  UPDATE_RULE,
  RULE_STATUS_UPDATED,
  SET_CATALOG_CREATION_DATE,
  SET_CATALOG_BUCKET,
  RULE_APPLICATBILITY_UPDATED,
  SET_APPLICABILITY_TYPE,
  SET_IS_RULE_FETCHING
} from '../components/dashboard/SearchOps/searchRuleEngine/types';
import moment  from 'moment';

import {
  CAT,
  SCAT,
  SSCAT,
  TIER_ONE,
  TIER_TWO,
  TIER_THREE,
  CATEGORY_TYPE_MAPPING,
  APPLICABILITY_TYPES
} from '../components/dashboard/SearchOps/searchRuleEngine/constants';

const INITIAL_STATE = {
  isLoading: false,
  selectedCategoryTypeId: null,
  selectedCategoryTypeName: null,
  catValues: [],
  scatValues: [],
  sscatValues: [],
  selectedCategoryValue: null,
  allAvailableFields: [],
  selectedTierOneValues: [],
  selectedTierTwoValues: [],
  selectedTierThreeValues: [],
  isRuleExist: false,
  allRulesList: [],
  limit: 1000,
  offset: 0,
  message: '',
  activeTab: SSCAT.id,
  mtrcValue: null,
  catalogBucket: null,
  catalogCreationDate: null,
  applicabilityType: APPLICABILITY_TYPES[1],
  isRuleFetching: false
};

export default function searchRuleEngine(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_CATEGORY_TYPE:
      return {
        ...state,
        selectedCategoryTypeId: action.payload.id,
        selectedCategoryTypeName: action.payload.name,
        isRuleExist: false,
      };
    case SET_ALL_CATEGORIES:
      const catValues = action.payload.filter((item) => item.type === CAT.type);
      const scatValues = action.payload.filter(
        (item) => item.type === SCAT.type
      );
      const sscatValues = action.payload.filter(
        (item) => item.type === SSCAT.type
      );

      return {
        ...state,
        catValues,
        scatValues,
        sscatValues,
      };
    case SET_SELECTED_CATEGORY_VALUE:
      return {
        ...state,
        isRuleExist: false,
        selectedCategoryValue: action.payload,
        allAvailableFields: [],
        selectedTierOneValues: [],
        selectedTierTwoValues: [],
        selectedTierThreeValues: [],
      };
    case SET_TIER:
      return {
        ...state,
        selectedTier: action.payload,
      };
    case SET_AVAILABLE_FIELDS:
      let allSelectedFields = [
        ...state.selectedTierOneValues,
        ...state.selectedTierTwoValues,
        ...state.selectedTierThreeValues,
      ];

      let availableFields = action.payload;
      if (allSelectedFields.length) {
        availableFields = action.payload.filter((item) => {
          return !allSelectedFields.includes(item.name);
        });
      }
      return {
        ...state,
        allAvailableFields: availableFields,
      };
    case ADD_SELECTED_FILEDS:
      let allAvailableFields = state.allAvailableFields.filter(
        (field) => field.name !== action.payload.value
      );
      switch (action.payload.tierType) {
        case TIER_ONE:
          return {
            ...state,
            allAvailableFields,
            selectedTierOneValues: [
              ...state.selectedTierOneValues,
              action.payload.value,
            ],
          };
        case TIER_TWO:
          return {
            ...state,
            allAvailableFields,
            selectedTierTwoValues: [
              ...state.selectedTierTwoValues,
              action.payload.value,
            ],
          };
        case TIER_THREE:
          return {
            ...state,
            allAvailableFields,
            selectedTierThreeValues: [
              ...state.selectedTierThreeValues,
              action.payload.value,
            ],
          };
        default:
          return state;
      }
    case DELETE_SELECTED_FILEDS:
      switch (action.payload.tierType) {
        case TIER_ONE:
          return {
            ...state,
            allAvailableFields: [
              ...state.allAvailableFields,
              { name: action.payload.value },
            ],
            selectedTierOneValues: state.selectedTierOneValues.filter(
              (item) => item !== action.payload.value
            ),
          };
        case TIER_TWO:
          return {
            ...state,
            allAvailableFields: [
              ...state.allAvailableFields,
              { name: action.payload.value },
            ],
            selectedTierTwoValues: state.selectedTierTwoValues.filter(
              (item) => item !== action.payload.value
            ),
          };
        case TIER_THREE:
          return {
            ...state,
            allAvailableFields: [
              ...state.allAvailableFields,
              { name: action.payload.value },
            ],
            selectedTierThreeValues: state.selectedTierThreeValues.filter(
              (item) => item !== action.payload.value
            ),
          };
        default:
          return state;
      }
    case SET_IS_RULE_EXIST:
      return {
        ...state,
        isRuleExist: action.payload,
      };

    case SET_FIELDS:
      let selectedTierOneValues = [];
      let selectedTierTwoValues = [];
      let selectedTierThreeValues = [];
      action.payload.rules.forEach((item) => {
        switch (item.tierName) {
          case TIER_ONE:
            selectedTierOneValues = item.tierValue || [];
            break;
          case TIER_TWO:
            selectedTierTwoValues = item.tierValue || [];
            break;
          case TIER_THREE:
            selectedTierThreeValues = item.tierValue || [];
            break;
          default:
            break;
        }
      });

      let selectedFields = [
        ...selectedTierOneValues,
        ...selectedTierTwoValues,
        ...selectedTierThreeValues,
      ];
      let allFields = [];
      if (state.allAvailableFields.length) {
        allFields = state.allAvailableFields.filter((item) => {
          return !selectedFields.includes(item.name);
        });
      }
      return {
        ...state,
        isRuleExist: false,
        selectedTierOneValues,
        selectedTierTwoValues,
        selectedTierThreeValues,
        selectedCategoryValue: action.payload.refId,
        selectedCategoryTypeId: action.payload.refType,
        selectedCategoryTypeName:
          CATEGORY_TYPE_MAPPING[action.payload.refType],
        allAvailableFields: allFields,
        catalogBucket: action.payload.catalogBucket || null,
        catalogCreationDate: action.payload.catalogCreationDateFilter ? moment(new Date(action.payload.catalogCreationDateFilter)): null,
        applicabilityType: action.payload.applicabilityType
      };
    case SET_ALL_RULES:
      return {
        ...state,
        allRulesList: action.payload.data,
        offset: action.payload.offset,
      };
    case RULE_DELETED:
      const updatedList = state.allRulesList.filter(
        (item) => item.id !== action.payload.ruleId
      );
      return {
        ...state,
        allRulesList: updatedList,
        message: action.payload.message,
      };

    case RULE_CREATED:
      return {
        ...state,
        message: action.payload.message,
      };

    case RULE_UPDATED:
      const updatedRuleList = state.allRulesList.map((item) => {
        if (item.id === Number(action.payload.data.id)) {
          item.rules = action.payload.data.rules;
          item.catalogBucket = action.payload.data.catalog_bucket || null;
          item.catalogCreationDateFilter = action.payload.data.catalog_creation_date_filter;
        }
        return item;
      });
      return {
        ...state,
        allRulesList: updatedRuleList,
        message: action.payload.message,
      };
    case CLEAR_FIELDS_ON_ADD_RULE_PAGE_UNMOUNT:
      return {
        ...state,
        isLoading: false,
        selectedCategoryTypeId: null,
        selectedCategoryTypeName: null,
        catValues: [],
        scatValues: [],
        sscatValues: [],
        selectedCategoryValue: null,
        allAvailableFields: [],
        selectedTierOneValues: [],
        selectedTierTwoValues: [],
        selectedTierThreeValues: [],
        isRuleExist: false,
        allRulesList: [],
        message: '',
        activeTab: SSCAT.id,
        mtrcValue: null,
        catalogBucket: null,
        catalogCreationDate: null
      };
    case RESET_REDUCER:
      return {
        ...state,
        isLoading: false,
        selectedCategoryTypeId: null,
        selectedCategoryTypeName: null,
        catValues: [],
        scatValues: [],
        sscatValues: [],
        selectedCategoryValue: null,
        allAvailableFields: [],
        selectedTierOneValues: [],
        selectedTierTwoValues: [],
        selectedTierThreeValues: [],
        isRuleExist: false,
        allRulesList: [],
        limit: 1000,
        offset: 0,
        message: '',
        applicabilityType: APPLICABILITY_TYPES[1]
      };
    case RESET_SNACKBAR_MESSAGE:
      return {
        ...state,
        message: '',
      };
    case REQUEST_FAILED:
      return {
        ...state,
        message: action.payload.message,
      };
    
    case CHANGE_ACTIVE_TAB: 
      return {
        ...state,
        activeTab: action.payload
      };

    case SET_MTRC_DATA:
      return {
        ...state,
        mtrcValue: action.payload
      };

    case MTRC_UPDATED:
      return {
        ...state,
        message: action.payload.message,
      };

    case UPDATE_RULE:
      const updatedAllRuleList = state.allRulesList.map((item) => {
        if (item.id === Number(action.payload.ruleId)) {
          item[action.payload.keyToBeUpdated] = action.payload.value;
        }
        return item;
      });
      return {
        ...state,
        allRulesList: updatedAllRuleList
      };

    case RULE_STATUS_UPDATED:
      return {
        ...state,
        message: action.payload.message,
      };
    case RULE_APPLICATBILITY_UPDATED:
      return {
        ...state,
        message: action.payload.message,
      };

    case SET_CATALOG_BUCKET:
      return {
        ...state,
        catalogBucket: action.payload,
      };

    case SET_CATALOG_CREATION_DATE:
      return {
        ...state,
        catalogCreationDate: action.payload,
      };

    case SET_APPLICABILITY_TYPE:
      return {
        ...state,
        applicabilityType: action.payload,
      };
    case SET_IS_RULE_FETCHING:
      return {
        ...state,
        isRuleFetching: action.payload,
      };
    default:
      return state;
  }
}
