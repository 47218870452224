import React from 'react';
import { Link } from 'react-router-dom';
import {logout} from '../actions/authActions';
import { browserHistory } from '@browserHistory';

class NavigationBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  logout() {
    logout()().then(
      (res) => {
        browserHistory.push('/login');
      }
    );
  }

  render() {
    let isAuthenticated = this.props.loggedStatus;
    const userLinks = (
      <div className="nav navbar-right flex-center">
        {this.props.user !== undefined && this.props.user.url !== undefined &&
          <div className="user-name">{this.props.user.url}</div>
        }
        {this.props.user !== undefined &&
          <div className="user-name">
            <span>
              {this.props.user.email !== undefined ? this.props.user.email : ''}
            </span>
            {this.props.user.country !== undefined ? <span> ({this.props.user.country.name})</span> : ''}
          </div>
        }
        <div><Link to="#" onClick={this.logout.bind(this)}>Logout</Link></div>
      </div>
    );

    return (
      <div className="home-page-nav">
        <nav className="navbar navbar-default border-radius">
          <div className="container-fluid">
            <div className="brand-name">
              <Link to="/" className="navbar-brand">
                <span>Meesho Supply</span>
              </Link>
            </div>
            <div>
              {isAuthenticated ? userLinks : null}
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

export default NavigationBar;
