import {
  REQUEST_FETCH_FILE_TYPES,
  FETCH_FILE_TYPES_SUCCESS,
  FETCH_FILE_TYPES_FAILURE,
  CHANGE_SELECTED_UPLOAD_INDEX,
  REQUEST_FETCH_CATEGORY_TREE,
  FETCH_CATEGORY_TREE_SUCCESS,
  FETCH_CATEGORY_TREE_FAILURE,
  MODIFY_SELECTED_CATEGORY_OBJ,
  REQUEST_FETCH_SCALEIDS,
  FETCH_SCALEIDS_SUCCESS,
  FETCH_SCALEIDS_FAILURE,
  CHANGE_SELECTED_SCALEID,
  INCREMENT_FILE_UPLOAD_STEP,
  SET_FILE_UPLOAD_STEP,
  RESET_EXCEL_UPLOAD,
  REQUEST_FETCH_ALL_SUPPLIERS,
  FETCH_ALL_SUPPLIERS_SUCCESS,
  FETCH_ALL_SUPPLIERS_FAILURE,
  SUPPLIER_SELECT,
  REQUEST_UPLOAD_GENERIC_EXCEL,
  UPLOAD_GENERIC_EXCEL_SUCCESS,
  UPLOAD_GENERIC_EXCEL_FAILURE,
  SET_EXCEL_FILE,
  DOWNLOAD_TEMPLATE_SUCCESS,
  DOWNLOAD_TEMPLATE_FAILURE,
  REQUEST_DOWNLOAD_TEMPLATE,
  SET_SELECTED_SOURCE,
  EXCEL_ROUTE_CHANGE
} from '../actions/excelUpload';
import Globalize from '../actions/languages/Globalize';

const initialState = {
  fetchFileTypeLoading: false,
  fileTypeData: null,
  fetchFileTypeError: '',
  selectedUploadIndex: null,
  categoryTree: [],
  isLoading: false,
  isDownloadTemplateError: false,
  isError: false,
  errorMessage: '',
  selectedCategoryTree: {
    superCategory: {},
    category: {},
    subCategory: {},
    subSubCategory: {}
  },
  scaleIds: [],
  selectedScaleId: null,
  totalSteps: 1,
  currentStep: 1,
  isBulkUpload: false,
  suppliers: [],
  selectedSupplier: null,
  fileUploadSuccess: false,
  fileUploadError: false,
  fileUploadErrorMessage: '',
  file: null,
  fileUrl: '',
  selectedSource: '',
  viewFiles: false
};

export default (state = initialState, action = {}) => {
  switch(action.type) {
    case REQUEST_FETCH_FILE_TYPES: {
      return Object.assign({}, state, {
        fetchFileTypeLoading: true,
      });
    }

    case FETCH_FILE_TYPES_SUCCESS: {
      return Object.assign({}, state, {
        fetchFileTypeLoading: false,
        fileTypeData: action.data
      });
    }

    case FETCH_FILE_TYPES_FAILURE: {
      return Object.assign({}, state, {
        fetchFileTypeLoading: false,
        fetchFileTypeError: Globalize.getString('unexpected_error_retry')
      });
    }

    case CHANGE_SELECTED_UPLOAD_INDEX: {
      return Object.assign({}, state, {
        selectedUploadIndex: action.data,
        totalSteps: action.totalSteps,
        isBulkUpload: action.isBulkUpload
      });
    }

    case REQUEST_FETCH_CATEGORY_TREE: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }

    case FETCH_CATEGORY_TREE_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        categoryTree: action.data,
        isError: false
      });
    }

    case FETCH_CATEGORY_TREE_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: Globalize.getString('unexpected_error_retry'),
        isError: true
      });
    }

    case MODIFY_SELECTED_CATEGORY_OBJ: {
      return Object.assign({}, state, {
        selectedCategoryTree: action.data
      });
    }

    case REQUEST_FETCH_SCALEIDS: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }

    case FETCH_SCALEIDS_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        scaleIds: action.data
      });
    }

    case FETCH_SCALEIDS_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: Globalize.getString('unexpected_error_retry')
      });
    }

    case CHANGE_SELECTED_SCALEID: {
      return Object.assign({}, state, {
        selectedScaleId: action.data
      });
    }

    case INCREMENT_FILE_UPLOAD_STEP: {
      let nextStep = state.currentStep + 1;
      return Object.assign({}, state, {
        currentStep: nextStep
      });
    }

    case SET_FILE_UPLOAD_STEP: {
      if(action.data === 1) {
        let scaleIds = [];
        let selectedScaleId = null;
        return Object.assign({}, state, {
          currentStep: action.data,
          scaleIds: scaleIds,
          selectedScaleId: selectedScaleId,
          selectedSource: '',
          selectedSupplier: null,
          file: null,
          isDownloadTemplateError: false,
          fileUploadSuccess: false,
          fileUploadError: false,
          fileUploadErrorMessage: '',
        });
      } else if(action.data === 2) {
        return Object.assign({}, state, {
          selectedSource: '',
          currentStep: action.data,
          selectedSupplier: null,
          file: null,
          isDownloadTemplateError: false,
          fileUploadSuccess: false,
          fileUploadError: false,
          fileUploadErrorMessage: '',
          scaleIds: [],
          selectedScaleId: null,
        });
      } else if(action.data !== state.totalSteps) {
        return Object.assign({}, state, {
          selectedSource: '',
          currentStep: action.data,
          isDownloadTemplateError: false,
          fileUploadSuccess: false,
          fileUploadError: false,
          fileUploadErrorMessage: '',
        });
      } else {
        return Object.assign({}, state, {
          currentStep: action.data,
          isDownloadTemplateError: false,
          fileUploadSuccess: false,
          fileUploadError: false,
          fileUploadErrorMessage: '',
        });
      }
    }

    case RESET_EXCEL_UPLOAD: {
      let resetState = {
        fetchFileTypeLoading: false,
        fileTypeData: null,
        fetchFileTypeError: '',
        selectedUploadIndex: null,
        categoryTree: [],
        isLoading: false,
        isDownloadTemplateError: false,
        isError: false,
        errorMessage: '',
        selectedCategoryTree: {
          superCategory: {},
          category: {},
          subCategory: {},
          subSubCategory: {}
        },
        scaleIds: [],
        selectedScaleId: null,
        totalSteps: 1,
        currentStep: 1,
        isBulkUpload: false,
        suppliers: [],
        selectedSupplier: null,
        fileUploadSuccess: false,
        fileUploadError: false,
        fileUploadErrorMessage: '',
        file: null,
        fileUrl: '',
        selectedSource: '',
        viewFiles: false
      };
      return Object.assign({}, state, resetState);
    }

    case REQUEST_FETCH_ALL_SUPPLIERS: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }

    case FETCH_ALL_SUPPLIERS_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        suppliers: action.data
      });
    }

    case FETCH_ALL_SUPPLIERS_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
        errorMessage: Globalize.getString('unexpected_error_retry')
      });
    }

    case SUPPLIER_SELECT: {
      return Object.assign({}, state, {
        selectedSupplier: action.data
      });
    }

    case REQUEST_UPLOAD_GENERIC_EXCEL: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }

    case UPLOAD_GENERIC_EXCEL_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        fileUploadSuccess: true,
        fileUploadError: false,
        fileUploadErrorMessage: ''
      });
    }

    case UPLOAD_GENERIC_EXCEL_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
        fileUploadSuccess: false,
        fileUploadError: true,
        fileUploadErrorMessage: action.data
      });
    }

    case SET_EXCEL_FILE: {
      return Object.assign({}, state, {
        file: action.data
      });
    }
    case REQUEST_DOWNLOAD_TEMPLATE: {
      return Object.assign({}, state, {
        isLoading: true,
      });
    }

    case DOWNLOAD_TEMPLATE_SUCCESS: {
      return Object.assign({}, state, {
        isLoading: false,
        fileUrl: action.data,
        isDownloadTemplateError: false
      });
    }

    case DOWNLOAD_TEMPLATE_FAILURE: {
      return Object.assign({}, state, {
        isLoading: false,
        isDownloadTemplateError: true,
        errorMessage: Globalize.getString('unexpected_error_retry')
      });
    }

    case SET_SELECTED_SOURCE: {
      return Object.assign({}, state, {
        selectedSource: action.data
      });
    }

    case EXCEL_ROUTE_CHANGE: {
      let resetSt = {
        fetchFileTypeLoading: false,
        fileTypeData: null,
        fetchFileTypeError: '',
      };
      return Object.assign({}, state, resetSt);
    }

    default:
      return state;
  }
};
