export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
    return v.toString(16);
  });
}

export function isPositiveInteger(str) {
  if (typeof str !== 'string') {
    return false;
  }
  if(str.includes('.')) {
    return false;
  }
  const num = Number(str);

  if (Number.isInteger(num) && num > 0) {
    return true;
  }

  return false;
}

export function isInteger(str) {
  if (typeof str !== 'string') {
    return false;
  }
  if(str.includes('.')) {
    return false;
  }

  const num = Number(str);

  if (Number.isInteger(num)) {
    return true;
  }

  return false;
}

export function isNumeric(num){
  return !isNaN(num);
}

export function validateFilterValue(value, fieldName, operator){
  const isEmptyValue = value === '';
  let isError = false;
  let errorValue = '';
  // const charactersRegex = /^[a-zA-Z]+$/;
  // const alphaNumnericRegex = /^[a-z0-9]+$/i;
  // const isDotNotInValue = !value.includes('.');

  if(fieldName === 'discount_amount' || fieldName === 'price' 
  || fieldName === 'discount' || fieldName === 'supplier_location' || fieldName === 'deal_discount'
  || fieldName === 'deal_price' || fieldName === 'deal_discount_amount') {
    if((isInteger(value) || isEmptyValue) === false) {
      errorValue = 'Enter Integer';
      isError = true;
    } else if(fieldName === 'supplier_location' && operator === 'wthn' && ((isPositiveInteger(value) || isEmptyValue) === false)) {
      errorValue = 'Enter Integer greater than 0';
      isError = true;
    }
  } else if(fieldName === 'ss_id') {
    if((isCommaSeparatedIntegers(value) || isEmptyValue) === false) {
      errorValue = 'Enter Comma Separated Integers';
      isError = true;
    }
  } else if(fieldName === 'rating') {
    if((isNumeric(value) || isEmptyValue) === false) {
      errorValue = 'Enter Numeric Value';
      isError = true;
    }
  } 
  /* Commented code might we used in later release
  else if(fieldName.includes('taxonomy') || fieldName.includes('label')) {
    if(alphaNumnericRegex.test(value) === false) {
      errorValue = 'Enter Alphnumberic Value';
      isError = true;
    }
  } else if(fieldName === 'category') {
    if(charactersRegex.test(value) === false) {
      errorValue = 'Enter Alphabets';
      isError = true;
    }
  }
  */

  return {
    isError, 
    errorValue,
  };
}

export function extractValues(clp){
  const valuesExtractReducer = (accum, currentVal) => {
    if(currentVal.trim() === '')
      return accum;
    if(currentVal.split(',').length > 1){
      let currentValArr = currentVal.split(',').filter(val => val.trim() !== '' ).map((item) => item.trim());
      return [].concat(accum, currentValArr);
    }
    return [].concat(accum, [currentVal]);
  };
  return clp.filters.map(
    (filterGroup)=> filterGroup.map(
      (filter)=>(
        {...filter, values:filter.values.map(({value})=>value).reduce(valuesExtractReducer, [])}
      )
    ));
}

export function isCommaSeparatedIntegers(str) {
  let regex = /^ *\d+ *(?:, *\d+ *)*$/;
  return regex.test(str);
}

export function getClpAdminSummary(jsonData) {
  let query = 'Select catalogs from DB where ';
  jsonData.filters.forEach((data, index) => {
    index !== 0 ? query += '"AND" ' : '';
    query += '(';
    data.map((currentData, idx) => {
      query += currentData.field;
      query += ' ';
      query += currentData.op;
      query += ' ';
      currentData.values.map((value, index) => {
        index !== 0 ? query += ', ' : '';
        index === 0 ? query += '[' : '';
        query += value;
      });
      query += ']';
      (idx !== data.length - 1 && data.length > 1) ? query += ' "OR" ' : '';
    });
    query += ') ';
  });
  return query;
}
