import {
  FETCH_USER_FROM_SEARCH,
  SET_SELECTED_USER_CALL,
  SET_USER,
  FETCH_USER,
  SET_SELECTED_ORDER_ROW_DATA,
  IS_SEARCH_BY_ORDER_NUM, CLEAR_ORDER_NUM,
  CLEAR_SELECTED_ORDER_IDS
} from "../actions/agentDashboard";

const initialState = {
  userFromSearch: null,
  isFetching: true,
  // user data
  user: null,
  isUserFetching: true,
  orderDetails: [],
  isSearchByOrderNum: ''
};

export default (state = initialState, action = {}) => {
  switch (action.type) {

    //TODO use of this FETCH_USER and where are we setting isUserFetching false again
    case FETCH_USER:
      return Object.assign({}, state, {
        isUserFetching: action.payload,
      });
    case CLEAR_ORDER_NUM: 
      return Object.assign({}, state, {
        isSearchByOrderNum: ''
      });
    case CLEAR_SELECTED_ORDER_IDS:
        return Object.assign({}, state, {
          orderDetails: []
        });
    case IS_SEARCH_BY_ORDER_NUM:
      return Object.assign({}, state, {
        isSearchByOrderNum: action.payload,
      });

    case SET_USER:
      let obj = {}
      if(action.payload && action.payload.data && action.payload.data.length > 0 && action.payload.data[0]) {      
          obj = {
          code: action.payload.data[0].user_code,
          email: action.payload.data[0].email,
          id: action.payload.data[0].user_id,
          name: action.payload.data[0].name,
          phone: action.payload.data[0].phone
        }
      }

      return Object.assign({}, state, {
        user: !!action.payload && action.payload.data[0],
        userFromSearch: obj,
        isUserFetching: false,
      });

    case FETCH_USER_FROM_SEARCH:
      let userFromSearch = {};
      if (action.payload !== undefined) {
        userFromSearch = action.payload;
      }
      return Object.assign({}, state, {
        userFromSearch: userFromSearch,
        isFetching: false,
      });

    case SET_SELECTED_USER_CALL:
      return Object.assign({}, state, {
        userFromSearch: action.data,
        isFetching: false,
      });

    case SET_SELECTED_ORDER_ROW_DATA:
      return Object.assign({}, state, {
        orderDetails: action.data,
      })
    default:
      return state;
  }
};
