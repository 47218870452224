import {FETCH_USER_ROLES} from '../types';
import axios from 'axios';
import { browserHistory } from '@browserHistory';
import get from 'lodash/get';

import Globalize from './../../actions/languages/Globalize';

export function fetchUserRoles() {
  return dispatch => {
    axios.post('/api/admin/users/actions').then(function(response) {
      dispatch(dispatchUserRoles(response.data));
    }).catch(function(error) {
      if(get(error, 'response.status', '') === 401) {
        browserHistory.push('/login');
      } else {
        let data = {user_roles: [], user: {}};
        dispatch(dispatchUserRoles(data));
      }
    });
  };
}

export function dispatchUserRoles(data) {
  new Globalize(data.user);
  return {
    type: FETCH_USER_ROLES,
    data
  };
}

export function fetchUsers() {
  return dispatch => {
    let response = axios.get('/api/admin/users');
    response.then(function() {
    }, function(error) {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        browserHistory.push('/login');
      }
    });
    return response;
  };
}

export function fetchActions() {
  return dispatch => {
    let response = axios.get('/api/admin/actions');
    response.then(function() {
    }, function(error) {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        browserHistory.push('/login');
      }
    });
    return response;
  };
}

export function fetchUserActions(data) {
  return dispatch => {
    let response = axios.get('/api/admin/user/actions', {params: data});
    response.then(function() {
    }, function(error) {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        browserHistory.push('/login');
      }
    });
    return response;
  };
}

export function fetchUserTeams(email) {
  return dispatch => {
    return axios.get('/api/admin/user/teams', {params: {email}}).catch(error => {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        browserHistory.push('/login');
      }
      throw error;
    });
  };
}

export function fetchUserTeamRoleMapping(email) {
  return dispatch => {
    return axios.get('/api/admin/user/roles', {params: {email}}).catch(error => {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        browserHistory.push('/login');
      }
      throw error;
    });
  };
}

export function onValidEmail(data) {
  return dispatch => {
    return axios.post('/api/admin/user/validate', data).catch(error => {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        browserHistory.push('/login');
      }
      throw error;
    });
  };
}

export function addUserActions(data) {
  return dispatch => {
    let response = axios.post('/api/admin/user/actions', data);
    response.then(function() {
    }, function(error) {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        browserHistory.push('/login');
      }
    });
    return response;
  };
}

export function syncUsers() {
  return dispatch => {
    let response = axios.get('/api/google/users');
    response.then(function() {
    }, function(error) {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        browserHistory.push('/login');
      }
    });
    return response;
  };
}

export function addAdminExecs(data) {
  return dispatch => {
    let response = axios.post('/api/admin/execs', data);
    response.then(function() {
    }, function(error) {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        browserHistory.push('/login');
      }
    });
    return response;
  };
}

export function fetchTeams() {
  return dispatch => {
    return axios.get('/api/admin/teams').catch(error => {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        return browserHistory.push('/login');
      }
      throw error;
    });
  };
}

export function fetchAllTeams() {
  return dispatch => {
    return axios.get('/api/admin/all-teams').catch(error => {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        return browserHistory.push('/login');
      }
      throw error;
    });
  };
}

export function fetchTeamActions(team_id) {
  return dispatch => {
    return axios.get('/api/admin/team/action', {params: {team_id}}).catch(error => {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        browserHistory.push('/login');
      }
      throw error;
    });
  };
}

export function submitTeamActions(data) {
  return dispatch => {
    return axios.post('/api/admin/team/action/add', data).catch(error => {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        return browserHistory.push('/login');
      }
      throw error;
    });
  };
}

export function submitUserTeam(data) {
  return dispatch => {
    return axios.post('/api/admin/user/teams', data).catch(error => {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        browserHistory.push('/login');
      }
      throw error;
    });
  };
}

export function submitUserRole(data) {
  return dispatch => {
    return axios.post('/api/admin/user/roles', data).catch(error => {
      if(error.response !== undefined && error.response.status !== undefined && error.response.status === 401) {
        browserHistory.push('/login');
      }
      throw error;
    });
  };
}
