import {
  IS_LOADING,
  GET_ABG_MAPPINGS_LIST,
  ON_FAILURE,
  CLEAR_SNACKBAR_MESSAGE,
  ADD_ABG_MAPPING,
  UPDATE_ABG_MAPPING,
  DELETE_ABG_MAPPING,
  GET_ABG_LIST,
} from '../components/dashboard/SearchOps/abgMapping/types';
  
const INITIAL_STATE = {
  isLoading: false,
  abgMappingsList: [],
  message: null,
  errorMessage: null,
  abgList: []
};
  
export default function abgMappings(state = INITIAL_STATE, action) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case ON_FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload
      };
    case GET_ABG_MAPPINGS_LIST:
      return {
        ...state,
        isLoading: false,
        abgMappingsList: action.payload,
      };
    case ADD_ABG_MAPPING:
      return {
        ...state,
        message: action.payload
      };
    case UPDATE_ABG_MAPPING:
      return {
        ...state,
        message: action.payload.message
      };
    case DELETE_ABG_MAPPING:
      return {
        ...state,
        message: action.payload.message
      };
    case CLEAR_SNACKBAR_MESSAGE:
      return {
        ...state,
        message: null,
        errorMessage: null
      };
    case GET_ABG_LIST:
      return {
        ...state,
        abgList: action.payload,
      };
    default:
      return state;
  }
}
  