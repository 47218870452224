import {
  IS_LOADING,
  ON_SUCCESS_GET_NORMALIZATION_RULE,
  ON_FAILURE_GET_NORMALIZATION_RULE,
  ON_SUCCESS_ADD_NORMALIZATION_RULE,
  ON_FAILURE_ADD_NORMALIZATION_RULE,
  ON_SUCCESS_EDIT_NORMALIZATION_RULE,
  ON_FAILURE_EDIT_NORMALIZATION_RULE,
  ON_SUCCESS_DELETE_NORMALIZATION_RULE,
  ON_FAILURE_DELETE_NORMALIZATION_RULE,
  ON_INPUT_NORMALIZED_QUERY,
  ON_INPUT_QUERY,
  IS_ERROR_FOUND,
  SUCCESS_RULE_BY_ID,
  CLEAR_NORMALIZATION_DATA
} from '../actions/types';

const INITIAL_STATE = {
  isLoading: false,
  initialRuleList: [],
  offset: 0,
  limit: 20,
  count: 0,
  sidebarOpen: true,
  query: '',
  normalized_query: '',
  error: {
    query: '',
    normalized_query: '',
    add_edit: '',
  },
  editData: {},
};

export default function queryNormalization(state = INITIAL_STATE, action) {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ON_SUCCESS_GET_NORMALIZATION_RULE:
      return {
        ...state,
        isLoading: false,
        initialRuleList: [...action.payload.data],
        count: action.payload.count,
        query: '',
        normalized_query: '',
        error: {},
      };
    case ON_FAILURE_GET_NORMALIZATION_RULE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case ON_SUCCESS_ADD_NORMALIZATION_RULE:
      return {
        ...state,
        isLoading: false,
        query: '',
        normalized_query: '',
      };
    case ON_FAILURE_ADD_NORMALIZATION_RULE:
      return {
        ...state,
        isLoading: false,
        error: {
          query: '',
          normalized_query: '',
          add_edit: action.payload.add_edit,
        },
      };
    case ON_SUCCESS_EDIT_NORMALIZATION_RULE:
      return {
        ...state,
        isLoading: false,
        query: '',
        normalized_query: '',
      };
    case ON_FAILURE_EDIT_NORMALIZATION_RULE:
      return {
        ...state,
        isLoading: false,
      };
    case ON_SUCCESS_DELETE_NORMALIZATION_RULE:
      return {
        ...state,
        isLoading: false,
        initialRuleList: [...action.payload],
      };
    case ON_FAILURE_DELETE_NORMALIZATION_RULE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case ON_INPUT_QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case ON_INPUT_NORMALIZED_QUERY:
      return {
        ...state,
        normalized_query: action.payload,
      };
    case IS_ERROR_FOUND:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SUCCESS_RULE_BY_ID:
      return {
        ...state,
        editData: action.payload,
        isLoading: false,
        query: action.payload.query,
        normalized_query: action.payload.normalized_query,
      };
    case CLEAR_NORMALIZATION_DATA:
      return {
        ...state,
        isLoading: false,
        initialRuleList: [],
        count: 0,
        query: '',
        normalized_query: '',
        error: {},
      };
    default:
      return state;
  }
}
